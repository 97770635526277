import { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import Img from 'react-cool-img'
// import axios from 'axios';

// import CreateFolder from '../ObjectControl/CreateFolder';
import FileActions from '../FileAction/FileActions';
import FileNameChange from '../FileAction/FileNameChange';
import FullSizeImage from './FullSizeImage';
// import FolderActions from '../FolderAction/FolderActions';
// import FolderNameChange from '../FolderAction/FolderNameChange';
// import PrefixLine from '../PrefixLine';
// import SelectedObjectsControll from '../../SelectedObjects/SelectedObjectsControl';

import { useStore } from '../../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../../utils/bucket/file-move-util';
import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';
import { marks } from '../../../../utils/marks';

// import BucketObjectMenu from '../BucketObjectMenu';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalPosition from '../../../Modal/ModalPosition';
import ModalConfirm from '../../../Modal/ModalConfirm';
// import { BACKEND_URL } from '../../../App';

import classes from './BucketObjectImageItem.module.css';

// import ObjectControl from '../ObjectControl/ObjectControl';

function BucketObjectImageItem(props) {

  const {
    object,
    fileClickSelectHandler,
    setClickedObjectKeyHandler, 
    showActionModalHandler, 
    setClickedActionInfoHandler,
    clickedObjectKey,
    setClickedActionInfo,
    modalPositions,
    getObjectSignedUrlHandler,
    fileDownloadHandler,
    deleteBucketObjectsHandler,
    selectedFileKeysHandler,
    clickedActionInfo,
    objectSignedUrl,
    isInSelectedFileKeys,
    imageStyle,
    actionModalStyle,
    isImage,
    storeObjectAccessHandler,
    isLoading,
  } = props;

  const checkMark = marks.checkMark;
  const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  // const topLevels = store.bucketObjectStore.topLevels;

  const { 
    imageUrlList,
   } = store.bucketObjectStore;

  // const gLoading = store.uiStore.gLoading;
  // const windowValues = store.uiStore.windowValues;

  // const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showFullSize, setShowFullSize] = useState();


  // const handleImageClick = (e) => {
  //   switch (e.detail) {
  //     case 1:
  //       console.log("click");
  //       break;
  //     case 2:
  //       console.log("double click");
  //       setShowFullSize(true);
  //       break;
  //     case 3:
  //       console.log("triple click");
  //       break;
  //   }
  // };

  const fileActionElementId = `file-action-${object.Key}`;
  const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);


  let imageBody;

  if (isImage) {

    let imageUrl;

    const imageUrlObj = imageUrlList.find(element => {
      return element.key === object.Key;
    });

    if (imageUrlObj?.imageUrl) {
      imageUrl = imageUrlObj.imageUrl;
    }

    if (imageUrlObj?.smallImageUrl) {
      imageUrl = imageUrlObj.smallImageUrl;
    }
    
    imageBody = (
      <Img 
        title={originalName}
        // title={''}
        // className={classes.objectImageItemImage}
        style={imageStyle}
        // src={object.imageUrl}
        src={imageUrl}
        onClick={(e) =>{ 
          if (!isLoading) {
            setClickedObjectKeyHandler(object.Key); 
            showActionModalHandler(object.Key, 'file'); 
            setClickedActionInfoHandler(null);
            // handleImageClick(e)
            storeObjectAccessHandler(object.Key);
          }
        }}
        alt="file"
      />
    )
  } 
  else {

    const fileMark = createFileMark(object);

    imageBody = (
      <div 
      title={originalName}
      // title={''}
      className={classes.objectImageItemOther}
      // src={object.imageUrl}
      onClick={() =>{ 
        if (!isLoading) {
          setClickedObjectKeyHandler(object.Key); 
          showActionModalHandler(object.Key, 'file'); 
          setClickedActionInfoHandler(null);
          storeObjectAccessHandler(object.Key);
        }
      }}
      alt="file"
    >
      <span className={classes.objectImageItemFileMark}>
        {fileMark}
      </span>
      
      <span className={classes.objectImageItemOtherName}>
        {originalName.length > 20 && (
          <span>{originalName.slice(0, 20)}...</span>
        )}
        {originalName.length <= 20 && (
          <span>{originalName}</span>
        )}
      </span>
    </div>
    )
  }


  let bucketObjectImageItemBody;

  bucketObjectImageItemBody = (
    <div className={classes.objectImageItem}>
      {imageBody}

      {/* <div>original-name: {originalName}</div> */}

      <span
        onClick={(event) => {
          if (!isLoading) {
            fileClickSelectHandler(event, isInSelectedFileKeys, object.Key)
          }
        }}
      >
        {/* {isInSelectedFileKeys ? 'sele' : 'dese'} */}
        <span id={fileActionElementId}
          className={isImage 
            ? classes.objectImageItemCheck 
            : classes.objectImageItemOtherCheck
          }
        >
          {isInSelectedFileKeys &&
            <span
            // className={classes.objectImageItemCheck}
            >
              {checkMark}
            </span> 
          }
          {!isInSelectedFileKeys && (
            <span 
            // className={classes.objectImageItemCheck}
            >
              {noCheckMark}
            </span>
          )}
        </span>
      </span>

      <span
        // title={'select action for file'}
        // onClick={() =>{ 
        //   if (!isLoading) {
        //     setClickedObjectKeyHandler(object.Key); 
        //     showActionModalHandler(object.Key, 'file'); 
        //     setClickedActionInfoHandler(null);
        //   }
        // }}
      >
        {/* action-for-image {marks.triangleDown} */}
      </span>

      {clickedObjectKey === object.Key && (
        <div>
          <Backdrop 
            zIndex={'90'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              if (!isLoading) {
                setClickedObjectKeyHandler(''); 
                setClickedActionInfo(null);
              }
            }}
          />
          <ModalPosition 
            // top={modalPositions.top}
            // left={modalPositions.left}
            // right={modalPositions.right}
            // bottom={modalPositions.bottom}
            // heightLimit={modalPositions.heightLimit}
            // zIndex='100'
            modalStyle={actionModalStyle}
            // onClose={() => {
            //   setClickedObjectKeyHandler(''); 
            //   setClickedActionInfo(null);
            // }}
          >
            {/* <div style={{textAlign:'right'}}>
              <span style={{cursor:'pointer'}} 
                onClick={() => {
                  if (!isLoading) {
                    setClickedObjectKeyHandler(''); 
                    setClickedActionInfo(null);
                  } 
                }}
              >
                X
              </span>
            </div> */}
            <FileActions 
              setClickedActionInfoHandler={setClickedActionInfoHandler}
              getObjectSignedUrlHandler={getObjectSignedUrlHandler}
              fileDownloadHandler={fileDownloadHandler}
              deleteBucketObjectsHandler={deleteBucketObjectsHandler}
              selectedFileKeysHandler={selectedFileKeysHandler}
              setClickedObjectKeyHandler={setClickedObjectKeyHandler}
              clickedActionInfo={clickedActionInfo}
              objectSignedUrl={objectSignedUrl}
              isInSelectedFileKeys={isInSelectedFileKeys}
              object={object}
              // showFullSize={showFullSize}
              setShowFullSize={setShowFullSize}
              isImage={isImage}
              isLoading={isLoading}
            />
          </ModalPosition>
        </div>
      )}
      
    </div>
  );


  let fileNameChangeBody;

  if (clickedActionInfo && 
    clickedActionInfo.key === object.Key && 
    clickedActionInfo.name === 'change-file-name'
  ) {
    fileNameChangeBody = (
      <ModalConfirm 
        hideButtons={true}
        onClose={() => {
          if (!isLoading) {
            setClickedActionInfoHandler(null);
          }
        }}
      >
        <FileNameChange 
          // setClickedObjectKeyHandler={setClickedActionInfo}
          setClickedActionInfoHandler={setClickedActionInfoHandler}
          object={object}
        />
      </ModalConfirm>
    );
  }
    
    




  return (
  <Fragment>
    <div>{bucketObjectImageItemBody}</div>
    <div>{fileNameChangeBody}</div>

    {showFullSize && (
      <div>
      {/* <Backdrop /> */}
        <FullSizeImage 
          object={object}
          setShowFullSize={setShowFullSize}
          showFullSize={showFullSize}
        />
      </div>
    )}
  </Fragment>
  );
}

export default BucketObjectImageItem;
