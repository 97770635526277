import { Fragment, useState, useEffect, useRef } from "react";

import CombinerInputs from "./CombinerInputs";
import CombinerResult from "./CombinerResult";

import ImageConvert from "../ImageConvert/ImageConvert";
import Backdrop from "../Backdrop/Backdrop";
import Modal from "../Modal/Modal";


import { useStore } from "../../hook-store/store";
import './ImageCombiner.css';



export default function ImageCombiner(props) {

  const [store, dispatch] = useStore();
  // const { gLoading } = store.uiStore;

  const [combinedB64String, setCombinedB64String] = useState("");


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437603549"
        style={{width:"310px", height:"70px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437603549"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437618599"
        style={{width:"460px", height:"95px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437618599"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437639506"
        style={{width:"610px", height:"135px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437639506"
      />
    );
  }

  return (
    <Fragment>
      <div className="imageCombiner">

        <div 
          style={{
            display: 'flex',
            justifyContent: "center",
            paddingBottom: "2rem"
          }}
        >
          {bannerElement}
        </div>

        <CombinerInputs setCombinedB64String={setCombinedB64String} />
        <CombinerResult combinedB64String={combinedB64String} />
      </div>
    </Fragment>
  );
}
