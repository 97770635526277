import React, { Fragment } from 'react'
import { atom, useRecoilState } from 'recoil'
import { SunIcon, MoonIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useStore } from '../../../../hook-store/store';

// export const themeState = atom({
//   key: 'themeState',
//   default: 'dark',
// })

export default function InfoButton() {
  
  const [store, dispatch] = useStore();

  return (
    <Fragment>
      <div className="theme-toggle-ui">
        <div
          className="theme-btn"
          onClick={() => { dispatch('SET_SHOWLAMAINFOMODAL', true) }}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <QuestionMarkCircleIcon />
        </div>
      </div>
    </Fragment>
  );
}
