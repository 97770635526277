import Compressor from 'compressorjs';
import Resizer from "react-image-file-resizer";

export const createCompressedImage = (file, tQuality, tMaxHeight, tMaxWidth) => {
  return new Promise((resolve, reject) => {
    const image = file;

    let quality = 0.8;
    
    if (file.size > 3 * 10**6) {
      quality = 0.6;
    }

    if (tQuality) {
      quality = tQuality;
    }
    // console.log('quality', quality);

    new Compressor(image, {
      quality: quality, // 0.6 can also be used, but its not recommended to go below.
      maxHeight: tMaxHeight ? tMaxHeight : 1400,
      maxWidth: tMaxWidth ? tMaxWidth : 1400,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.        
        // setCompressedFile(res)
        console.log('compressedResult', compressedResult);
        resolve(compressedResult);
      },
    });
  });
}

export const changeImageDimensionSize = (
  file, 
  outPut, 
  ext, 
  maxWidth, 
  maxHeight,
) => {
  const outputType = outPut ? outPut : 'base64';
  const extName = ext ? ext : "JPEG";
  // console.log(extName)

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      extName,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType,
      0,
      0,
    );
  });
};