import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_ISUSERFUSIONRUNNING: (state, payload) => {
      return { 
        imageFusionStore : {
          ...state.imageFusionStore,
          isUserFusionRunning: payload, 
        }
      };
    },
    SET_RUNCYCLE: (state, payload) => {
      return { 
        imageFusionStore : {
          ...state.imageFusionStore,
          runCycle: payload, 
        }
      };
    },
    SET_ISFUSIONSERVER: (state, payload) => {
      return { 
        imageFusionStore : {
          ...state.imageFusionStore,
          isFusionServer: payload, 
        }
      };
    },
    SET_ISFUSIONSERVERPROCESSING: (state, payload) => {
      return { 
        imageFusionStore : {
          ...state.imageFusionStore,
          isFusionServerProcessing: payload, 
        }
      };
    },
  };

  initStore(actions, {
    imageFusionStore: {
      isUserFusionRunning: false,
      runCycle: 0,
      isFusionServer: false,
      isFusionServerProcessing: false, 
    }
  });
};

export default configureStore;
