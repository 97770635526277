import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import ShareAbout from './ShareAbout';

import { useStore } from '../../hook-store/store';
import { createCompressedImage } from '../../utils/image-util';

import { SHAREURLS, WATAKURAURL } from '../../App';

import { marks } from '../../utils/marks';

import './ShareEditImage.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

const fileSizeLimit = 5 * 10 **6;

function ShareEditImage(props) {
  const { 
    // editorRef,
    createShareFile,
    fileForShare,
    // loadedImageName,
    // undoStackLength,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    selectedPrefix,
  } = store.bucketObjectStore;
  const { isAuth, gLoading } = store.uiStore;
  const { loadedImageName, undoStackLength } = store.imageEditorStore;


  // const [imageData, setImageData] = useState();
  // const [selectedFile, setSelectedFile] = useState();
  
  // const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [outputFormat, setOutputFormat] = useState('png');
  const [shareFile, setShareFile] = useState();
  const [shareFileSize, setShareFileSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showShareAbout, setShowShareAbout] = useState(false);

  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

  },[gLoading]);


  useEffect(() => {
    if (shareFile) {
      window.addEventListener("message", messageProcessHandler);
    }

    return () => {
      window.removeEventListener('message', messageProcessHandler);
    }
  }, [shareFile]);


  useEffect(() => {
    if (loadedImageName) {
      setShareFileSize(0);

      const nList = loadedImageName.split('.');
      nList.pop();
      const noExtName = nList.join('.');
      setFileName(noExtName);


      const ext = loadedImageName.split('.').pop().toLowerCase();
      // console.log(ext);
      if (ext === 'jpg' || ext === 'jpeg') {
        setOutputFormat(ext);
      } else {
        setOutputFormat('png');
      }
    }
  },[loadedImageName]);


  function messageProcessHandler(event) {
        
    const token = '12345';
    const isOriginExist = SHAREURLS.find(
      (url) => url === event.origin
    );

    if (!isOriginExist) {
      return;
    }
    // if (event.origin !== messageSourceUrl) {
    //   // The message is not from a trusted origin. Do not process it.
    //   return;
    // }

    // if (event.source !== window.parent) {
    //   // The message is not from a trusted window object. Do not process it.
    //   return;
    // }

    if (event.data.token !== token) {
      // The token is not valid. Do not process the message.
      return;
    }

    console.log("message-event", event);

    if (event.data.shareWindowOpen && event.data.isAuth) {
      shareFileSendHandler(event);
      window.removeEventListener('message', messageProcessHandler);
    }
  }



  const shareStartHandler = async () => {
    try {
      dispatch('SET_GLOADING', true);

      setErrorMessage('');
      // const editedFile = createFileHandler(fileName, outputFormat);
      const editedFile = createShareFile(fileName, outputFormat);

      if (editedFile) {
        setShareFileSize(editedFile.size);

        const createdFile = await compressImageHandler(editedFile);
        
        if (!createdFile) {
          setErrorMessage('error occured');
          throw new Error('error occured');
          return;
        }
  
        if (editedFile.size > fileSizeLimit) {
          setErrorMessage('File size should be less than 5MB. Load image and edit image increase file size. Please consider to resize image in Image Resizer page.');
          throw new Error('error occured');
          return;
        }
  
        else {
            setShareFile(createdFile);
      
            const openUrl = WATAKURAURL + "/share";
            window.open(openUrl);
        }
      }

      dispatch('SET_GLOADING', false);
      
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }

  };


  const shareFileSendHandler = async (event) => {
    try { 
      dispatch('SET_GLOADING', true);

      setErrorMessage('');
      // const createdFile = createFileHandler(fileName, outputFormat);
      const editedFile = createShareFile(fileName, outputFormat);

      if (editedFile) {
        setShareFileSize(editedFile.size);

        const createdFile = await compressImageHandler(editedFile);
        
        if (!createdFile) {
          setErrorMessage('error occured');
          throw new Error('error occured');
          return;
        }
  
        if (editedFile.size > fileSizeLimit) {
          setErrorMessage('File size should be less than 5MB. Load image and edit image increase file size. Please consider to resize image in Image Resizer page.');
          throw new Error('error occured');
          return;
        }

        const openUrl = event.origin + "/share";
        const sendData = {
          token: '12345',
          shareFile: createdFile,
          content: "content",
        };
  
        event.source.postMessage(sendData, openUrl);

      }

      dispatch('SET_GLOADING', false);
      
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
    }
};
  



  const compressImageHandler = async (file) => {
    try {
      const compressed = await createCompressedImage(
        file,
        null,
        800,
        800,
      );
  
      if (compressed && compressed.size < file.size) {
        const cFile = new File(
          [compressed], 
          fileName + '.' + outputFormat, 
          { type: file.type }
        );
        return cFile;
      } else {
        return file;
      }

    } catch (err) {
      console.log(err);
      throw(err);
    }
  };


  let shareButtonDisabled = true;

  if (window.location.pathname === '/image-editor' && loadedImageName) {
    shareButtonDisabled = false;
  }

  if (window.location.pathname !== '/image-editor' && 
      fileForShare && fileForShare.size <= fileSizeLimit
  ) {
    shareButtonDisabled = false;
  }


  return (
    <Fragment>
      <div>
        <div className='shareEditImageButtons'>
          <button className="btnBase"
            title="share at watakura"
            // disabled={!loadedImageName}
            disabled={shareButtonDisabled}
            onClick={() => { 
              if (!isLoading) {
                // shareStartHandler()
                setShowShareAbout(true);
              }
            }}
          >
            {t('shareImage01', 'Share image')} 
            {' '}
            at watakura {marks.shareSquareOMark}
          </button>

          <span className='shareEditImageInfoMark'
            title="about file share at watakura"
            onClick={() => { 
              if (!isLoading) {
                setShowShareAbout(true);
              }
            }}
          >
            {marks.questionCircleOMark}
          </span>
        </div>

        {showShareAbout && (
          <div>
            <Backdrop 
              zIndex={'95'}
              onCancel={() => { 
                if (!isLoading) {
                  setShowShareAbout(false);
                }
              }}
            />
            <Modal
              hideButtons={true}
              onClose={() => { 
                if (!isLoading) {
                  setShowShareAbout(false);
                } 
                    }}
            >
              <ShareAbout 
                // editorRef={editorRef}
                fileName={fileName}
                outputFormat={outputFormat}
                createShareFile={createShareFile}
                fileForShare={fileForShare}
                shareStartHandler={shareStartHandler}
                shareButtonDisabled={shareButtonDisabled}
                errorMessage={errorMessage}
                isLoading={isLoading}
              />
            </Modal>
          </div>
        )}
        {errorMessage && (
          <div>
            <strong>{errorMessage}</strong>
          </div>
        )}

      </div>
    </Fragment>
  );
}

export default ShareEditImage;
