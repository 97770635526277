import { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import Resizer from "react-image-file-resizer";

// import numeral from 'numeral';

// import CombinerInfo from "./CombinerInfo";
import Backdrop from "../Backdrop/Backdrop";
import Modal from '../Modal/Modal';
import ImageFusionInfo from "./ImageFusionInfo";
import ImageFusionProgress from "./ImageFusionProgress";
import ImageFusionResult from "./ImageFusionResult";
import ImageFusionFileSelectContents from "./ImageFusionFileSelectContents";

import { useStore } from '../../hook-store/store';

import { BACKEND_URL, FUSIONAPI_URL, loginPageLink, signupPageLink } from "../../App";

import { marks } from '../../utils/marks';
import "./ImageFusion.css";

export default function ImageFusionInputs(props) {
  const { } = props;

  const [t] = useTranslation('translation');

  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  const { 
    runCycle, 
    isUserFusionRunning,
    isFusionServer,
    isFusionServerProcessing,
   } = store.imageFusionStore;

  // const [imageFiles, setImageFiles] = useState([]);
  const [inputFile, setInputFile] = useState();
  const [inputFile2, setInputFile2] = useState();
  const [selectedFileKind, setSelectedFileKind] = useState('base');

  const [fileBlob, setFileBlob] = useState();
  const [fileBlob2, setFileBlob2] = useState();

  const [targetHeight, setTargetHeight] = useState(100);
  const [cycle, setCycle] = useState(5);
  const [fusionInputs, setFusionInputs] = useState({
    targetHeight: { value: 100, isValid: true },
    cycle: { value: 25, isValid: true },
    strength: { value: 5, isValid: true },
  });
  const [fusionFile, setFusionFile] = useState();

  const [fileDimension, setFileDimension] = useState({
    height: null,
    width: null,
  });
  const [fileDimension2, setFileDimension2] = useState({
    height: null,
    width: null,
  });
  const [showFusionInfo, setShowFusionInfo] = useState(false);
  const [showFileSelectModal, setShowFileSelectModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const setFusionInputsHandler = (inputIdentifier, enteredValue) => {
    if (!(parseInt(enteredValue) > 0)) {
      return;
    }

    if (inputIdentifier === 'targetHeight' && parseInt(enteredValue) > 400) {
      return;
    }

    if (inputIdentifier === 'cycle' && parseInt(enteredValue) > 50) {
      return;
    }

    if (inputIdentifier === 'strength' && 
        (Number(enteredValue) < 1 || Number(enteredValue) > 10)
    ) {
      return;
    }

    setFusionInputs((curInputs) => {
      if (inputIdentifier === 'strength') {
        return {
          ...curInputs,
          [inputIdentifier]: { value: Number(enteredValue), isValid: true },
        }
      } else {
        return {
          ...curInputs,
          [inputIdentifier]: { value: parseInt(enteredValue), isValid: true },
        }
      }
    })
  };

  console.log(fusionInputs)
  // const setHeightHandler = (event) => {
  //   if (Number(event.target.value) > 0) {
  //     setTargetHeight(parseInt(event.target.value));
  //   }
  // }

  // const setCycleHandler = (event) => {
  //   if (Number(event.target.value) > 0) {
  //     setCycle(parseInt(event.target.value));
  //   }
  // }

  

  const handleInputClick = (event, inputNum) => {
    if (inputNum === 1) {
      hiddenFileInput.current.click();
    } else {
      hiddenFileInput2.current.click();
    }
  };

  const blobProcessHandler = (event, inputNum) => {
    const fr = new FileReader();
    //@ts-ignore
    let file;

    if (inputNum === 1) {
      file = document.getElementById("fileinput").files[0];
      setInputFile(file);
    } else {
      file = document.getElementById("fileinput2").files[0];
      setInputFile2(file);
    }

    fr.readAsArrayBuffer(file);
    fr.onload = async function () {
      // you can keep blob or save blob to another position
      const blob = new Blob([fr.result]);

      console.log(blob);
      //@ts-ignore

      if (inputNum === 1) {
        setFileBlob(blob);
      } else {
        setFileBlob2(blob);
      }
    };
  };



  const imageFusionHandler = async (
    url, 
    token, 
    contentFile, 
    styleFile,
    targetHeight,
    cycle,
    strength,
  ) => {
    try {
      dispatch('SET_GLOADING', true);
      dispatch('SET_ISUSERFUSIONRUNNING', true);

      const data = new FormData();

      const getTokenResut = await fetch(`${url}/style-transfer/fusion-start-token`, {
        // const res = await fetch(`${url}/run`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
            // 'Content-Type': 'application/json'
          },
          // body: data,
      });

      const getTokenResData = await getTokenResut.json();

      // console.log('getTokenResData', getTokenResData)

      if (getTokenResData?.data) {
        data.append("fusionStartToken", getTokenResData.data);
      }


      const cFile = await resizeFile(
        contentFile,
        'file',
        contentFile.type.split('/')[1],
        1024,
        targetHeight,
      );

      const sFile = await resizeFile(
        styleFile,
        'file',
        contentFile.type.split('/')[1],
        1024,
        targetHeight,
      );

      console.log(cFile, sFile, contentFile, styleFile);

      // throw new Error('error-error');


      // data.append("images", contentFile);
      // data.append("images", styleFile);

      if (cFile.size < contentFile.size) {
        data.append("images", cFile);
      } else {
        data.append("images", contentFile);
      } 

      if (sFile.size < styleFile.size) {
        data.append("images", sFile);
      } else {
        data.append("images", styleFile);
      }

      data.append("height", targetHeight);
      data.append("iteration", cycle);
      data.append("strength", strength);
      // data.append('files', selectedFiles);

      // data.append('storePath', path);
      //   data.append("bucket", bucketName);

      if (localStorage.getItem("customerId")) {
        data.append("customerId", localStorage.getItem("customerId"));
      }


      const res = await fetch(`${url}/style-transfer`, {
      // const res = await fetch(`${url}/run`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          // 'Content-Type': 'application/json'
        },
        body: data,
      });

      console.log('res', res);
      
      if (res.ok) {
        // const resData = await res.json();
        const resData = await res.blob();
        console.log(resData);
        // throw new Error('error-error');

        const resFile = new File([resData], `fusion-${Date.now()}.jpeg`, {
          type: 'image/jpeg',
        });

        console.log(resFile, resData);

        setFusionFile(resFile);

        dispatch('SET_GLOADING', false);

        setTimeout(() => {
          dispatch('SET_ISUSERFUSIONRUNNING', false);
        }, 1000 * 30);

        // return { blob: blob }
      } else {
        const errMsg = await res.text()
        throw Error(errMsg)
      }
      
     
    } catch (err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      setTimeout(() => {
        dispatch('SET_ISUSERFUSIONRUNNING', false);
      }, 1000 * 0);

      throw err;
    }
  };


  // const imageFusionHandler2 = async (
  //   url, 
  //   token, 
  //   contentFile, 
  //   styleFile,
  //   targetHeight,
  //   cycle,
  //   strength,
  // ) => {
  //   try {
  //     dispatch('SET_GLOADING', true);
  //     dispatch('SET_ISUSERFUSIONRUNNING', true);
  //     const data = new FormData();

  //     const cFile = await resizeFile(
  //       contentFile,
  //       'file',
  //       contentFile.type.split('/')[1],
  //       1024,
  //       targetHeight,
  //     );

  //     const sFile = await resizeFile(
  //       styleFile,
  //       'file',
  //       contentFile.type.split('/')[1],
  //       1024,
  //       targetHeight,
  //     );

  //     // console.log(cFile, sFile, contentFile, styleFile);

  //     // throw new Error('error-error');


  //     // data.append("images", contentFile);
  //     // data.append("images", styleFile);
  //     data.append("images", cFile);
  //     data.append("images", sFile);

  //     data.append("height", targetHeight);
  //     data.append("iteration", cycle);
  //     data.append("strength", strength);
  //     // data.append('files', selectedFiles);

  //     // data.append('storePath', path);
  //     //   data.append("bucket", bucketName);

  //     if (localStorage.getItem("customerId")) {
  //       data.append("customerId", localStorage.getItem("customerId"));
  //     }


  //     const res = await fetch(`${url}/style-transfer/fusion2`, {
  //     // const res = await fetch(`${url}/run`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: 'Bearer ' + token,
  //         // 'Content-Type': 'application/json'
  //       },
  //       body: data,
  //     });

  //     console.log('res', res);
      
  //     if (res.ok) {
  //       // const resData = await res.json();
  //       const resData = await res.json();
  //       console.log(resData);

  //       const imageData = await fetch(resData.data);
  //       const imageBlob = await imageData.blob();
  //       // throw new Error('error-error');

  //       // const blob = await res.blob()
  //       // console.log('blob', blob);

  //       // const result = await fetch(resData.b64String);
  //       // const blob = await result.blob();
  //       // const file = new File([resData], 'image.jpeg', { type: 'image/jpeg' });

  //       const resFile = new File([imageBlob], `fusion-${Date.now()}.png`, {
  //         type: 'image/png',
  //       });

  //       console.log(resFile, resData);

  //       setFusionFile(resFile);

  //       dispatch('SET_GLOADING', false);

  //       setTimeout(() => {
  //         dispatch('SET_ISUSERFUSIONRUNNING', false);
  //       }, 1000 * 30);

  //       // return { blob: blob }
  //     }
      
  //     const errMsg = await res.text()
  //     throw Error(errMsg)
     
  //   } catch (err) {
  //     console.log(err);
  //     dispatch('SET_GLOADING', false);

  //     setTimeout(() => {
  //       dispatch('SET_ISUSERFUSIONRUNNING', false);
  //     }, 1000 * 0);

  //     throw err;
  //   }
  // };


  const resizeFile = (file, outPut, ext, maxWidth, maxHeight) => {
    const outputType = outPut ? outPut : 'base64';
    const extName = ext ? ext : "JPEG";
    // console.log(extName)

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        extName,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        outputType,
        1,
        1,
      );
    });
  }

  const getImgDimension = (event, inputNum) => {
    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);

    // console.log(event, event.target.naturalHeight, event.target.naturalWidth);
    if (inputNum === 1) {
      if (
        event.target.naturalHeight !== fileDimension.height ||
        event.target.naturalWidth !== fileDimension.width
      ) {
        setFileDimension({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
    } else {
      if (
        event.target.naturalHeight !== fileDimension2.height ||
        event.target.naturalWidth !== fileDimension2.width
      ) {
        setFileDimension2({
          height: event.target.naturalHeight,
          width: event.target.naturalWidth,
        });
      }
    }
  };


  // let sameTypeErrorMessage = "";
  let fileSizeLimitMessage = "";
  // let fileScaleLimitMessage = "";


  // if (inputFile && inputFile2 && inputFile.type !== inputFile2.type) {
  //   sameTypeErrorMessage = <span>
  //     {t('combiner12', 'Image type is different. (accept jpeg or png file. Files should be same type.)')}
  //   </span>
  // }

  if (inputFile && inputFile.size > 5*10**6) {
    fileSizeLimitMessage = <span>
      {t('combiner13', 'File size should be less than 5 MB.')}
    </span>
  }

  if (inputFile2 && inputFile2.size > 5*10**6) {
    fileSizeLimitMessage = <span>
      {t('combiner13', 'File size should be less than 5 MB.')}
    </span>
  }

  // if (fileDimension && (fileDimension.height > 2000 || fileDimension.width > 2000)) {
  //   fileScaleLimitMessage = <span>
  //     {t('combiner14', 'Width and Height should be less than 2000px.')}
  //   </span>
  // }

  // if (fileDimension2 && (fileDimension2.height > 2000 || fileDimension2.width > 2000)) {
  //   fileScaleLimitMessage = <span>
  //     {t('combiner14', 'Width and Height should be less than 2000px.')}
  //   </span>
  // }

  return (
    <Fragment>
      <ImageFusionProgress 
        fusionInputs={fusionInputs}
      />

      <section className="fusionSectoin">
        <div className="fusionInfoMark">
          <span
            onClick={() => {
              setShowFusionInfo(!showFusionInfo);
            }}
          >
            {t('combiner01', 'info')} {marks.infoCircleMark}
          </span>
        </div>
        {showFusionInfo && (
          <div>
            <ImageFusionInfo />
          </div>
        )}
      </section>
      <section className="fusionSectoin">
        <div className="fusionInputButtons">
          <button
            //   disabled={gLoading}
            className="btnBase"
            onClick={(event) => {
              handleInputClick(event, 1);
            }}
          >
            {t('imageFusion01', 'Select base image')}
          </button>
          <button
            //   disabled={gLoading}
            className="btnBase"
            onClick={(event) => {
              handleInputClick(event, 2);
            }}
          >
            {t('imageFusion02', 'Select style image')}
          </button>
          <input
            id="fileinput"
            type="file"
            ref={hiddenFileInput}
            style={{ display: "none" }}
            // onChange={imageInputHandler}
            onChange={(event) => {
              blobProcessHandler(event, 1);
            }}
            accept="image/png,image/jpeg"
          />
          <input
            id="fileinput2"
            type="file"
            ref={hiddenFileInput2}
            style={{ display: "none" }}
            // onChange={imageInputHandler}
            onChange={(event) => {
              blobProcessHandler(event, 2);
            }}
            accept="image/png,image/jpeg"
          />
          <span> or </span>
          <button className="btnBase"
            onClick={() => {
              setShowFileSelectModal(true);
            }}
          >
            {marks.cloudDownloadMark} 
            {' '}
            {t('imageFusion03', 'Use uploaded files')}
          </button>
        </div>
      </section>

      <section className="fusionSectoin">
        <div className="fusionInputImages">
          <div>
            {inputFile && (
              <div>
                <div>{t('imageFusion04', 'base image')}</div>
                <img
                  className="fusionInputImage"
                  // style={{ maxHeight: "100px" }}
                  title={inputFile.name}
                  src={URL.createObjectURL(inputFile)}
                  alt="for resize"
                  onClick={() => {}}
                  onLoad={(event) => {
                    getImgDimension(event, 1);
                  }}
                />
              </div>
            )}
            {/* {fileDimension.height > 0 && (
              <div className="combinerImageDeminsions">
                (H: {fileDimension.height}, W: {fileDimension.width})
              </div>
            )}
              {inputFile && (
              <div className="combinerImageDeminsions">
                {numeral(inputFile.size).format('0.0 b')} ({inputFile.type.split('/')[1]})
              </div>
            )} */}
          </div>
          <div>
            {inputFile2 && (
              <div>
                <div>{t('imageFusion05', 'style image')}</div>
                <img
                  className="fusionInputImage"
                  // style={{ maxHeight: "100px" }}
                  title={inputFile2.name}
                  src={URL.createObjectURL(inputFile2)}
                  alt="for resize"
                  onClick={() => {}}
                  onLoad={(event) => {
                    getImgDimension(event, 2);
                  }}
                />
              </div>
            )}
            {/* {fileDimension2.height > 0 && (
              <div className="combinerImageDeminsions">
                (H: {fileDimension2.height}, W: {fileDimension2.width})

                
              </div>
            )}
            {inputFile2 && (
              <div className="combinerImageDeminsions">
                {numeral(inputFile2.size).format('0.0 b')} ({inputFile2.type.split('/')[1]})
              </div>
            )} */}

          </div>
        </div>

        {/* {sameTypeErrorMessage && (
          <div className="combinerSectoin">{sameTypeErrorMessage}</div>
        )} */}
        {fileSizeLimitMessage && (
          <div className="fusionSectoin">{fileSizeLimitMessage}</div>
        )}
        {/* {fileScaleLimitMessage && (
          <div className="combinerSectoin">{fileScaleLimitMessage}</div>
        )} */}
      </section>
      
      <section className="fusionSectoin">
        <div className="fusionParamInputs">
          <div>
            <div className="fusionParamRow">
              <label>{t('imageFusion06', 'Target height')} (Max 400 (px))</label>
            </div>
            <div className="fusionParamRow">
              <input className="inputBase"
                style={{ width: "10rem", maxWidth: "100%"}}
                type="text"
                onChangeCapture={(event) => {
                  setFusionInputsHandler('targetHeight', event.target.value);
                }}
                // onChange={setHeightHandler}
              />
            </div>
            <div className="fusionParamRow">
              {fusionInputs.targetHeight.value}
            </div>
          </div>

          {/* <div>
            <div className="fusionParamRow">
              <label>Cycle (Max 50)</label>
            </div>
            <div className="fusionParamRow">
              <input className="inputBase"
                style={{ width: "10rem", maxWidth: "100%"}}
                type="text"
                onChangeCapture={(event) => {
                  setFusionInputsHandler('cycle', event.target.value);
                }}
                // onChange={setCycleHandler}
              />
            </div>
            <div className="fusionParamRow">
              {fusionInputs.cycle.value}
            </div>
          </div> */}

          <div>
            <div className="fusionParamRow">
              <label>{t('imageFusion07', 'Strength')} (1-10)</label>
            </div>
            <div className="fusionParamRow">
              <input className="inputBase"
                style={{ width: "10rem", maxWidth: "100%"}}
                type="text"
                onChangeCapture={(event) => {
                  setFusionInputsHandler('strength', event.target.value);
                }}
                // onChange={setCycleHandler}
              />
            </div>
            <div className="fusionParamRow">
              {fusionInputs.strength.value}
            </div>
          </div>
        </div>
      </section>

      <div className="fusionSectoin">
        <button
          className="btnBase"
          style={{ padding: "1rem", fontSize: "1.25rem", fontWeight: "bold"}}
          disabled={!inputFile || !inputFile2 || isUserFusionRunning || 
            fileSizeLimitMessage || !isFusionServer || isFusionServerProcessing
          }
          onClick={() => {
            imageFusionHandler(
              BACKEND_URL,
              localStorage.getItem("token"),
              inputFile, 
              inputFile2,
              fusionInputs.targetHeight.value,
              fusionInputs.cycle.value,
              fusionInputs.strength.value,
            );
            // imageFusionHandler2(
            //   BACKEND_URL,
            //   localStorage.getItem("token"),
            //   inputFile, 
            //   inputFile2,
            //   fusionInputs.targetHeight.value,
            //   fusionInputs.cycle.value,
            //   fusionInputs.strength.value,
            // );
          }}
        >
          {t('imageFusion08', 'Start Image Fusion')}
        </button>
      </div>

      <div className="fusionSectoin">
        <ImageFusionResult 
          fusionFile={fusionFile}
        />
      </div>

      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.09', 'Login to upload and store files, or create account')}
          </p>

          <div className="resizerResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}

      {showFileSelectModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
            hideButtons={true}
          >
            <ImageFusionFileSelectContents 
              selectedFileKind={selectedFileKind}
              setSelectedFileKind={setSelectedFileKind}
              setInputFile={setInputFile}
              setInputFile2={setInputFile2}
              setShowFileSelectModal={setShowFileSelectModal}
              isLoading={isLoading}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}
