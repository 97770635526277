import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import ImageFusionUploadModalContent from './ImageFusionUploadModalContent';

import { useStore } from '../../hook-store/store';


export default function ImageFusionUpload(props) {
  const { 
    fusionFile
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  // const { 
  //   resizedFiles,
  // } = store.imageResizerStore;
  const { gLoading, isAuth, isAllApis } = store.uiStore;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const showUploadMoadlHandler = (value) => {
    setShowUploadModal(value);
    // dispatch('SET_SHOW_LAMAUPLOADMODAL', value);
  }

 

  let imageFusionUploadBody;


  if (isAuth && isAllApis) {
    imageFusionUploadBody = (
      <button
        className='btnBase'
        disabled={!fusionFile}
        onClick={() => {
          if (!isLoading) {
            showUploadMoadlHandler(true);
          }
          // cleanImageUploadHandler(''); 
        }}
      >
        {t('imageFusion13', 'Upload Fusion Image')}
      </button>
    );
  }


  return (
    <Fragment>
      {imageFusionUploadBody}
      {showUploadModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          >
            <ImageFusionUploadModalContent 
              showUploadMoadlHandler={showUploadMoadlHandler}
              fusionFile={fusionFile}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

