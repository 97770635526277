import { Fragment, useState, useEffect, useRef, } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Resizer from "react-image-file-resizer";

import { useStore } from '../../hook-store/store';

import './ConvertImages.css'
// import classes from './Footer.module.css';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

export default function ConvertImages(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  // const hiddenFileInput = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    convertFiles,
    convertSelected,
    convertedFiles,
  } = store.imageConvertStore;

  const cancelResizeHandler = (id) => {
    const deletedFiles = convertedFiles.filter(element => {
      return element.id !== id;
    });

    dispatch('SET_CONVERTEDFILES', deletedFiles);

  };

  let imageConvertImagesBody;

  // console.log(resizerFiles, store.imageResizerStore)
  if (convertFiles && convertFiles.length > 0) {
    // console.log(resizerFiles)
    imageConvertImagesBody = (
      <div>
        {!convertSelected && (
          <div>
            {t('imageConvert03', 'Select image for convert file format')}
          </div>
        )}
        {convertSelected && (
          <div>
            {t('imageConvert04', 'Go below to convert file format')}
          </div>
        )}
        <ul className='convertImages'>
          {convertFiles.map(item => {

            const convertedFile = convertedFiles.find(element => {
              return element.id === item.id;
            });

            let imageStyle = null;

            if (convertedFile) {
              imageStyle = {
                opacity: '0.5',
              }
            }

            if (convertSelected && item.id === convertSelected.id) {
              imageStyle = {
                ...imageStyle,
                border: "10px solid gray",
              }
            }
            return (
              <li className='convertImageContainer'>
                <img className='convertImage'
                  title={item.file.name}
                  // style={resizedFile ? {opacity: "0.5"} : null}
                  style={imageStyle}
                  src={URL.createObjectURL(item.file)}
                  alt="for resize"
                  onClick={() => { dispatch('SET_CONVERTSELECTED', item) }}

                />
                {/* <span>
                  {displayName}
                </span> */}
                {convertedFile && (
                  <span className='convertImageResult'>
                    <span>Done</span>
                    <span
                      onClick={() => { cancelResizeHandler(item.id) }}
                    > 
                      ({t('resizer06', 'Cancel')})
                    </span>
                  </span>
                )}
                {/* {resizedFile && resizedFile.targetDimension && (
                  <span className='resizerImageFinishMark'>
                    re-fl ({resizedFile.targetDimension.height}x{resizedFile.targetDimension.width})
                  </span>
                )} */}
              
          
              </li>
            );
          })}
        </ul>
      </div>
    );
  }




  return (
    <Fragment>
      <div>{imageConvertImagesBody}</div>
    </Fragment>
  );
}

