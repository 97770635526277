import { Fragment, useState, useEffect } from 'react';
// import _ from 'lodash';
// import numeral from 'numeral';
// import moment from 'moment';

import { useStore } from '../../../../hook-store/store';
import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';

// import * as fileMoveUtils from '../../../../utils/bucket/file-move-util';

import { marks } from '../../../../utils/marks';

// import BucketObjectMenu from '../BucketObjectMenu';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalPosition from '../../../Modal/ModalPosition';
// import ModalConfirm from '../../../Modal/ModalConfirm';
import TrashActions from '../TrashActions/TrashActions';

// import { BACKEND_URL } from '../../../App';

// import classes from './BucketObjectFolderItem.module.css';
import classes from '../../BucketObject/BucketObjectImageList/BucketObjectFolderItem.module.css';


function TrashboxImageFolderItem(props) {

  const {
    object,
    // folderClickSelectHandler,
    setClickedObjectKeyHandler, 
    showActionModalHandler,
    setClickedActionInfoHandler,
    clickedObjectKey,
    setClickedActionInfo,
    modalPositions,
    // deleteFolderHandler,
    // setMovePrefixesHandler,
    clickedActionInfo,
    // getAllBucketObjects,
    // storeObjectAccessHandler,
    imageStyle,
    // actionMoadalStyle,
    // createToplevelsHandler,


    trashObjects,
    selectedTrashObjects,
    selectObjectHandler,
    editSelectedTrashObjects,
    deleteTrashboxMetaHandler,
    deleteFilesFoldersHandler,
    isLoading,
  } = props;

  // console.log(props);
  // const checkMark = marks.checkMark;
  // const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const { currentPrefix } = store.bucketObjectStore;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  // const topLevels = store.bucketObjectStore.topLevels;

  // const { allBucketObjects } = store.bucketObjectStore;

  // const gLoading = store.uiStore.gLoading;
  // const windowValues = store.uiStore.windowValues;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
  // const [clickedActionInfo, setClickedActionInfo] = useState();
 
  // const [modalPositions, setModalPositions] = useState({ 
  //   top: null, 
  //   left: null,
  //   right: null,
  //   bottom: null,
  //   heightLimit: 300,
  // });

  // const [isLoading, setIsLoading] = useState(false);
  // const isInSelectedFileKeys = selectedFileKeys.find(key => {
  //   return key === object.Key;
  // });

  const folderActionElementId= `folder-action-${object.Key}`;
  // const fileActionElementId = `file-action-${object.Key}`;

  // const isInMovePrefixes = movePrefixes.find(px => {
  //   return px === object.Key;
  // });

  const isInSelectedTrashObjects = selectedTrashObjects.find(obj => {
    return obj.Key === object.Key;
  });


  let objectName;
  let objectMark;
  
  if (object.Key.endsWith('/')) {
    // const folderName = object.Key.split('/')[object.Key.split('/').length -2];
    // const timeEndIndex = folderName.indexOf('Z');
    // const originalName = folderName.substring(timeEndIndex+2);
    // objectName = originalName;
    const trashFolderName = bucketObjectUtils.getFolderNameFromKey(object.Key);
    
    // console.log(trashFolderName);
    if (trashFolderName.startsWith('trash-')) {
      const trashYIndex = trashFolderName.indexOf('Y');
      objectName = trashFolderName.substring(trashYIndex + 2);
    } else {
      objectName = trashFolderName;
    }
    // console.log(objectName)
    objectMark = marks.folderFillMark;
  }
  else {
    objectName = bucketObjectUtils.getOriginalFileNameFromKey(object.Key);
    objectMark = createFileMark(object);
  }


  
  // const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  // const timeEndIndex = fileName.indexOf('Z');
  // // console.log(timeEndIndex);
  // const originalName = fileName.substring(timeEndIndex+2);
  const originalName = object.Key.substring(currentPrefix.length, object.Key.length -1);

  const displayFolderName = object.Key.length > 20 
    ? originalName.slice(0, 20) + '...'
    : originalName;

  let bucketObjectFolderItemBody;

  if (originalName === '/') {
    // return null;
    bucketObjectFolderItemBody = null;
  }
  else {
    bucketObjectFolderItemBody = (
      <div className={classes.objectFolderItem}>
        <span
          title={objectName}
          style={imageStyle}
          onClick={() => {    
            if (!isLoading) {
              // getAllBucketObjects(bucketName, object.Key); 
              // createToplevelsHandler(object.Key, allBucketObjects);
              // storeObjectAccessHandler(object.Key);
              selectObjectHandler(object.Key, trashObjects);
            }            
          }}
        >
          <span className={classes.objectFolderItemFolderMark}>
           {/* {marks.folderFillMark}  */}
           {objectMark}
          </span>


          {objectName.length > 20 && (
            <span className={classes.objectFolderItemFolderName}>
              {/* {displayFolderName} */}
              {objectName.slice(0, 20) + '...'}
            </span>
          )}

          {objectName.length <= 20 && (
            <span className={classes.objectFolderItemFolderName}>
              {/* {displayFolderName} */}
              {objectName}
            </span>
          )}

        </span>

          {/* {isInMovePrefixes ? 'sele' : 'dese'} */}

          <span
            className={classes.objectFolderItemCheck}
            onClick={() => { 
              if (!isLoading) {
                // folderClickSelectHandler(isInMovePrefixes, object.Key);
                editSelectedTrashObjects(object);
              }
            }}
          >
            {isInSelectedTrashObjects &&
              <span>
                {marks.checkMark}
              </span> 
            }
            {!isInSelectedTrashObjects && (
              <span>
                {marks.noCheckMark}
              </span>
            )}
        </span>
                   
        <span // id={folderActionElementId}
          className={classes.objectFolderItemAction}
          title={'select action for folder'}
          onClick={() =>{ 
            if (!isLoading) {
              setClickedObjectKeyHandler(object.Key); 
              showActionModalHandler(object.Key, 'folder');
              setClickedActionInfoHandler(null); 
            }
          }}
        >
          action {marks.triangleDown}
        </span>
        
        <span id={folderActionElementId}
          className={classes.objectFolderItemBottomLeft}
        >
        </span>

        {clickedObjectKey === object.Key && (
          <div>
            <Backdrop 
              zIndex={'90'}
              // backgroundColor={'rgba(0, 0, 0, 0.1)'}
              backgroundColor={'rgba(0, 0, 0, 0)'}
              onCancel={() => { 
                if (!isLoading) {
                  setClickedObjectKeyHandler(''); 
                  setClickedActionInfo(null);
                }
              }}
            />
            <ModalPosition 
              top={modalPositions.top}
              left={modalPositions.left}
              right={modalPositions.right}
              bottom={modalPositions.bottom}
              heightLimit={modalPositions.heightLimit}
              zIndex='100'
              // modalStyle={actionMoadalStyle}
              // onClose={() => {
              //   setClickedObjectKeyHandler(''); 
              //   setClickedActionInfo(null);
              // }}
            >
              <TrashActions
                setClickedActionInfoHandler={setClickedActionInfoHandler}
                setClickedObjectKeyHandler={setClickedObjectKeyHandler}
                clickedActionInfo={clickedActionInfo}
                object={object}
                trashObjects={trashObjects}
                deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
                deleteFilesFoldersHandler={deleteFilesFoldersHandler}
                selectedTrashObjects={selectedTrashObjects}
                isLoading={isLoading}
              />
            </ModalPosition>
          </div>
        )}
      </div>
    )
  }

  // let folderNameChangeBody;

  // if (clickedActionInfo && clickedActionInfo.key === object.Key && clickedActionInfo.name === 'change-folder-name') {
  //   folderNameChangeBody = (
  //     <ModalConfirm 
  //       hideButtons={true}
  //       onClose={() => {
  //         if (!isLoading) {
  //           setClickedActionInfoHandler(null);
  //         }
  //       }}
  //     >
  //       <FolderNameChange 
  //         setClickedActionInfoHandler={setClickedActionInfoHandler}
  //         object={object}
  //       />
  //     </ModalConfirm>
  //   );
  // }
    


  return (
    <Fragment>
      {bucketObjectFolderItemBody}
      {/* <div>{folderNameChangeBody}</div> */}
    </Fragment>
  );
}

export default TrashboxImageFolderItem;
