import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import { 
  imageHeightState, 
  imageWidthState,
  fileState,
 } from '../../store/Atoms.tsx'

import { useStore } from '../../../../hook-store/store.js'

const ImageSize = () => {
  const imageWidth = useRecoilValue(imageWidthState)
  const imageHeight = useRecoilValue(imageHeightState)

  const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;
  const { 
    dimensionsLimit,
   } = store.lamaCleanerStore;


  if (!imageWidth || !imageHeight) {
    return null
  }

  let imageSizeBody;

  if (imageWidth && imageHeight) {
    imageSizeBody = (
      <div className="imageSize">
      {imageWidth} x {imageHeight} px

      {(imageWidth > dimensionsLimit && imageHeight > dimensionsLimit) && (
        <div>
          <span className='imageSizeExceed'>
            Image should be less than 800px
          </span>
          <br/>
          <span>
            It is possible to resize in Image Resizer
          </span>
          <br/>
          <button>
            <Link 
              // to={`/image-resizer?key=${object.Key}`}
              to={`/image-resizer`}
              style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
            >
              {/* {marks.compressMark} resize in Image Resizer */}
              Image Resizer page
            </Link>
          </button>
        </div>
      )}
    </div>
    )
  }

  return (
    <Fragment>
      <div>{imageSizeBody}</div>
    </Fragment>
  )
}

export default ImageSize
