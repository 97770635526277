import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../hook-store/store';

export default function InfoButtonModalContent() {
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();

  return (
    <Fragment>
      <div style={{fontSize: "1.1rem" }}>

        <p>
          Image Cleaner {' '}
          {t('imageCleaner01', 'is an AI-assisted image cleaning tool. It is possible to remove unwanted objects, defects, people from your image.')}
        </p>
        <br/>
        <p>
        {t('imageCleaner02', 'Advanced AI technologies enable to finish image cleaning process in less than 10 seconds in many cases.')}
        </p>
        <br/>
        <p>
        {t('imageCleaner03', 'Accepted file types are JPEG and PNG.')}
        </p>
        <br/>

        <p>
          <strong>{t('imageCleaner04', 'Please note')}</strong>
        </p>
        <p>
        {t('imageCleaner05', 'Because of the limitation of image processing server resouces, Image cleaning sometimes fails during image cleaning process and clean button become inresponsive.')}
          <br />
          {t('imageCleaner06', 'After downloading or uploading previously cleaned image, reloading the page solve the problem usually.')}
        </p>
        <br/>
      </div>
    </Fragment>
  );
}
