import { Fragment, useState, useEffect, useRef, } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// import Resizer from "react-image-file-resizer";

import Backdrop from "../Backdrop/Backdrop";
import Modal from '../Modal/Modal';
import ResizerImages from './ResizerImages';
import ResizerResize from './ResizerResize';
import ImageResizerFileSelectContents from "./ImageResizerFileSelectContents";

import { useStore } from '../../hook-store/store';
import { 
  getObjectFile, 
  getOriginalFileNameFromKey 
} from '../../utils/bucket/bucket-object-util';

import { marks } from '../../utils/marks';

import './ImageResizer.css'
// import classes from './Footer.module.css';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';
import { BACKEND_URL } from '../../App';

export default function ImageResizer(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const hiddenFileInput = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    resizerFiles,
    // resizerSelected,
  } = store.imageResizerStore;
  const { bucketName, allBucketObjects } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;


  const [fileFromBucket, setFileFromBucket] = useState(false);
  const [showFileSelectModal, setShowFileSelectModal] = useState(false);
  // const [uploadSelectedKeys, setUploadSelectedKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  // const paramKey = searchParams.get('key');
  let paramKeys = searchParams.get('keys');

  if (paramKeys) {
    paramKeys = JSON.parse(paramKeys);
  }

  // console.log(paramKeys);

  useEffect(() => {

    window.scrollTo(0,0);

    //// initialize store when leave page
    return () => {
      dispatch('SET_RESIZERFILES', []);
      dispatch('SET_RESIZERSELECTED', null);
      dispatch('SET_RESIZEDFILES', []);
    }
  },[]);

  useEffect(() => {
    if (paramKeys) {
      if ((paramKeys.length + resizerFiles.length) > 10) {
        alert('maximum 10 files');
        return;
      }

      getObjectFilesHandler(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        paramKeys,
        'image/png',
      );
    }

  },[]);

  const handleInputClick = event => {
    hiddenFileInput.current.click();
  };


  const filesHandler = (event, paramFiles, uploadSelectedFiles) => {
    try { 
      let files = [];

      if (event) {
        files = event.target.files;
  
        if ((event.target.files.length + resizerFiles.length) > 10) {
          alert('maximum 10 files');
          event.preventDefault();
          return;
        }
      }

      if (paramFiles) {
        if ((paramFiles.length + resizerFiles.length) > 10) {
          alert('maximum 10 files');
          return;
        }

        // console.log(paramFiles);
        for (const paramFile of paramFiles) {
          if (paramFile.type.split('/')[0] === 'image') {
            files.push(paramFile);
          }
        }

        // setFileFromBucket(true);
      }


      if (uploadSelectedFiles) {
        if ((uploadSelectedFiles.length + resizerFiles.length) > 10) {
          alert('maximum 10 files');
          return;
        }
        // console.log(paramFiles);
        for (const uploadSelectedFile of uploadSelectedFiles) {
          if (uploadSelectedFile.type.split('/')[0] === 'image') {
            files.push(uploadSelectedFile);
          }
        }

        // setFileFromBucket(true);
      }


      const fileList = [];

      for (let i=0; i<files.length; i++) {
        const uuid = uuidv4();

        fileList.push({
          id: uuid,
          file: files[i], 
          // src: URL.createObjectURL(files[i]),
        })
      }

      // console.log(fileList);
      // console.log(files);
      const existFiles = resizerFiles;
      const addedList = existFiles.concat(fileList);
      dispatch('SET_RESIZERFILES', addedList);

    } catch(err) {
      console.log(err);
    }

  }

  const clearFileConfirm = () => {
    if (window.confirm(`${t('resizer02', 'Clear files')}?`)) {
      dispatch('SET_RESIZERFILES', []);
      dispatch('SET_RESIZERSELECTED', null);
      dispatch('SET_RESIZEDFILES', []);

      //// clear qery params when files cleared
      navigate('/image-resizer', { replace: true });
      setFileFromBucket(false);

      // window.open("exit.html", "Thanks for Visiting!");
    }
  }


  const getObjectFilesHandler = async (url, token, bucket, keys, mimeType) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const paramFiles = [];

      for (const key of keys) {

        const objectData = allBucketObjects.find(element => {
          return element.Key === key;
        });

        //// not get file when file is not image
        if (!objectData.mimeType ||
            (objectData.mimeType && objectData.mimeType.split('/')[0] !== 'image') ||
            (objectData.mimeType && objectData.mimeType.split('/')[1] === 'gif')
        ) {
          continue;
        }

        const resData = await getObjectFile(
          url,
          token,
          bucket,
          key,
          mimeType,
        );
  
        if (resData) {
          console.log(resData);
  
          const originalName = getOriginalFileNameFromKey(key);

          const file = new File([resData], originalName, {type: objectData.mimeType});
          // console.log(file);
          
          paramFiles.push(file);
          // filesHandler(null, [file]);
          // handleLoadImageFromFile(file, 'edit-' + originalName);
  
          navigate('/image-resizer', { replace: true });
          // searchParams.delete('key');
          // setSearchParams(searchParams);
  
        }
      }

      filesHandler(null, paramFiles);


      // setImageLoadNum(imageLoadNum+1);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);


    } catch(err) {
      console.log(err);
      // setImageLoadNum(imageLoadNum+1);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };

  const setUploadSelectedKeysHandler = (selectedObjects) => {
    const keyList = [];

    for (const obj of selectedObjects) {
      if (!obj.Key.endsWith('/') && obj.mimeType && 
            obj.mimeType.split('/')[0] === 'image'
      ) {
        keyList.push(obj.Key);
      }
    }
    
    return keyList;
  };

  const setResizerFilesFromUploadedHandler = async (selectedObjects) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      const selectedFiles = [];

      const keys = setUploadSelectedKeysHandler(selectedObjects);

      if ((keys.length + resizerFiles.length) > 10) {
        alert('maximum 10 files');
        throw new Error('file number limit error')
        return;
      }

      for (const key of keys) {

        const objectData = allBucketObjects.find(element => {
          return element.Key === key;
        });
        //// not get file when file is not image
        if (!objectData.mimeType ||
            (objectData.mimeType && objectData.mimeType.split('/')[0] !== 'image') ||
            (objectData.mimeType && objectData.mimeType.split('/')[1] === 'gif')
        ) {
          continue;
        }

        const resData = await getObjectFile(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          key,
          objectData.mimeType,
        );
  
        if (resData) {
          console.log(resData);
  
          const originalName = getOriginalFileNameFromKey(key);

          const file = new File([resData], originalName, {type: objectData.mimeType});
          // console.log(file);
          
          selectedFiles.push(file);
        }
      }

      filesHandler(null, null, selectedFiles);


      // setImageLoadNum(imageLoadNum+1);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
      setShowFileSelectModal(false);


    } catch(err) {
      console.log(err);
      // setImageLoadNum(imageLoadNum+1);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437603549"
        style={{width:"310px", height:"70px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437603549"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437618599"
        style={{width:"460px", height:"95px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437618599"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437639506"
        style={{width:"610px", height:"135px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437639506"
      />
    );
  }

  let imageResizerBody;

  imageResizerBody = (
    <div className='imageResizer'>

      {/* <div>image-resizer-page</div> */}

      <div 
        style={{
          display: 'flex',
          justifyContent: "center",
          paddingBottom: "2rem"
        }}
      >
        {bannerElement}
      </div>
      
      <div className='imageResizeElement'>
        <div className='imageResizerInputButtons'>
          <button 
            disabled={gLoading}
            className="btnBase" 
            onClick={handleInputClick}
          >
            {marks.televisionMark} {t('resizer01', 'Select device images (max 10 files)')}
          </button>

          <button className='btnBase'
            disabled={gLoading}
            onClick={() => { setShowFileSelectModal(true); }}
          >
            {marks.cloudDownloadMark} 
            {' '}
            {t('resizer17', 'Select from uploaded files')}
          </button>

          {resizerFiles.length > 0 && (
            <button
              disabled={gLoading}
              className="btnBase"
              // onClick={() => { dispatch('SET_RESIZERFILES', []) }}
              onClick={() => { clearFileConfirm() }}
            >
              {t('resizer02', 'Clear files')}
            </button>
          )}
        </div>
        <input 
          id='fileinput'
          type="file"
          ref={hiddenFileInput}
          style={{display: 'none'}} 
          onChange={(event) => { 
            // fileInputHandler(event) 
            filesHandler(event);
          }}
          onClick={(event) => { 
            // https://stackoverflow.com/questions/12030686/html-input-file-selection-event-not-firing-upon-selecting-the-same-file
            event.target.value = '' 
          }}
          accept='image/png,image/jpeg,image/webp'
          multiple
        />
      </div>

      <div>
        <ResizerImages />
      </div>

      <div>
        <ResizerResize />
      </div>
    </div>
  );


  return (
    <Fragment>
      <div>{imageResizerBody}</div>

      {showFileSelectModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                setShowFileSelectModal(false);
              }
            }}
            hideButtons={true}
          >
            <ImageResizerFileSelectContents 
              setShowFileSelectModal={setShowFileSelectModal}
              setResizerFilesFromUploadedHandler={setResizerFilesFromUploadedHandler}
              isLoading={isLoading}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

