import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from '../Backdrop/Backdrop';
import Modal from '../Modal/Modal';
import CombinerUploadModalContent from './CombinerUploadModalContent';
import { useStore } from '../../hook-store/store';


export default function CombinerUpload(props) {
  const { 
    combinedB64String,
    combinedFile,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  // const { 
  //   resizedFiles,
  // } = store.imageResizerStore;
  const { gLoading, isAuth, isAllApis } = store.uiStore;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const showUploadMoadlHandler = (value) => {
    setShowUploadModal(value);
    // dispatch('SET_SHOW_LAMAUPLOADMODAL', value);
  }

 

  let imageResizerUploadBody;

  if (isAuth && isAllApis) {
    imageResizerUploadBody = (
      <button
        className='btnBase'
        disabled={!combinedB64String}
        onClick={() => {
          if (!isLoading) {
            showUploadMoadlHandler(true);
          }
          // cleanImageUploadHandler(''); 
        }}
      >
        {t('combiner09', 'Upload combined image')}
      </button>
    );
  }


  return (
    <Fragment>
      {imageResizerUploadBody}
      {showUploadModal && (
        <div>
          <Backdrop 
            onCancel={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          />
          <Modal
            onClose={() => { 
              if (!isLoading) {
                showUploadMoadlHandler(false);
              }
            }}
          >
            <CombinerUploadModalContent 
              showUploadMoadlHandler={showUploadMoadlHandler}
              combinedB64String={combinedB64String}
              combinedFile={combinedFile}
            />
          </Modal>
        </div>
      )}
    </Fragment>
  );
}

