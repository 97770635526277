import { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import Img from 'react-cool-img'
// import axios from 'axios';

// import CreateFolder from '../ObjectControl/CreateFolder';
// import FileActions from '../FileAction/FileActions';
// import FileNameChange from '../FileAction/FileNameChange';
// import FullSizeImage from './FullSizeImage';
import TrashActions from '../TrashActions/TrashActions';
// import FolderActions from '../FolderAction/FolderActions';
// import FolderNameChange from '../FolderAction/FolderNameChange';
// import PrefixLine from '../PrefixLine';
// import SelectedObjectsControll from '../../SelectedObjects/SelectedObjectsControl';

import { useStore } from '../../../../hook-store/store';
import * as bucketObjectUtils from '../../../../utils/bucket/bucket-object-util';
import { createFileMark } from '../../../../utils/bucket/bucket-ui-util';


// import BucketObjectMenu from '../BucketObjectMenu';
import Backdrop from '../../../Backdrop/Backdrop';
import ModalPosition from '../../../Modal/ModalPosition';
import ModalConfirm from '../../../Modal/ModalConfirm';
// import { BACKEND_URL } from '../../../App';

import { marks } from '../../../../utils/marks';
// import classes from './BucketObjectImageItem.module.css';
import classes from '../../BucketObject/BucketObjectImageList/BucketObjectImageItem.module.css';


function TrashboxImageItem(props) {

  const {
    object,
    // fileClickSelectHandler,
    setClickedObjectKeyHandler, 
    showActionModalHandler, 
    setClickedActionInfoHandler,
    clickedObjectKey,
    setClickedActionInfo,
    // modalPositions,
    // getObjectSignedUrlHandler,
    // fileDownloadHandler,
    // deleteBucketObjectsHandler,
    // selectedFileKeysHandler,
    clickedActionInfo,
    // objectSignedUrl,
    // isInSelectedFileKeys,
    imageStyle,
    actionModalStyle,
    isImage,

    trashObjects,
    selectedTrashObjects,
    // selectObjectHandler,
    editSelectedTrashObjects,
    deleteTrashboxMetaHandler,
    deleteFilesFoldersHandler,

    isLoading,
  } = props;

  // const checkMark = marks.checkMark;
  // const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  const { 
    imageUrlList,
   } = store.bucketObjectStore;
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  // const bucketName = store.bucketObjectStore.bucketName;
  // const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  // const topLevels = store.bucketObjectStore.topLevels;

  // const { topLevels } = store.bucketObjectStore;

  // const gLoading = store.uiStore.gLoading;
  // const windowValues = store.uiStore.windowValues;

  // const [showNameChangeModal, setShowNameChangeModal] = useState(false);
  const [showFullSize, setShowFullSize] = useState();


  // const handleImageClick = (e) => {
  //   switch (e.detail) {
  //     case 1:
  //       console.log("click");
  //       break;
  //     case 2:
  //       console.log("double click");
  //       setShowFullSize(true);
  //       break;
  //     case 3:
  //       console.log("triple click");
  //       break;
  //   }
  // };



  const isInSelectedTrashObjects = selectedTrashObjects.find(obj => {
    return obj.Key === object.Key;
  });


  let objectName;
  let objectMark;
  
  if (object.Key.endsWith('/')) {
    // const folderName = object.Key.split('/')[object.Key.split('/').length -2];
    // const timeEndIndex = folderName.indexOf('Z');
    // const originalName = folderName.substring(timeEndIndex+2);
    // objectName = originalName;
    const trashFolderName = bucketObjectUtils.getFolderNameFromKey(object.Key);
    
    // console.log(trashFolderName);
    if (trashFolderName.startsWith('trash-')) {
      const trashYIndex = trashFolderName.indexOf('Y');
      objectName = trashFolderName.substring(trashYIndex + 2);
    } else {
      objectName = trashFolderName;
    }
    // console.log(objectName)
    objectMark = marks.folderFillMark;
  }
  else {
    objectName = bucketObjectUtils.getOriginalFileNameFromKey(object.Key);
    objectMark = createFileMark(object);
  }


  const fileActionElementId = `file-action-${object.Key}`;
  const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);


  let imageBody;

  if (isImage) {

    let imageUrl;

    const imageUrlObj = imageUrlList.find(element => {
      return element.key === object.Key;
    });

    if (imageUrlObj?.imageUrl) {
      imageUrl = imageUrlObj.imageUrl;
    }

    if (imageUrlObj?.smallImageUrl) {
      imageUrl = imageUrlObj.smallImageUrl;
    }

    imageBody = (
      <Img 
        // title={originalName}
        // title={''}
        // className={classes.objectImageItemImage}
        style={imageStyle}
        // src={object.imageUrl}
        src={imageUrl}
        onClick={(e) =>{ 
          if (!isLoading) {
            setClickedObjectKeyHandler(object.Key); 
            showActionModalHandler(object.Key, 'file'); 
            setClickedActionInfoHandler(null);
            // handleImageClick(e)
          }
        }}
        alt="file"
      />
    )
  } else {

    const fileMark = createFileMark(object);

    imageBody = (
      <div 
        title={objectName}
        // title={''}
        className={classes.objectImageItemOther}
        // src={object.imageUrl}
        onClick={() =>{ 
          if (!isLoading) {
            setClickedObjectKeyHandler(object.Key); 
            showActionModalHandler(object.Key, 'file'); 
            setClickedActionInfoHandler(null);
          }
        }}
        alt="file"
      >
      <span className={classes.objectImageItemFileMark}>
        {/* {fileMark} */}
        {objectMark}
      </span>
      
      <span className={classes.objectImageItemOtherName}>
        {objectName.length > 20 && (
          <span>{objectName.slice(0, 20)}...</span>
        )}
        {objectName.length <= 20 && (
          <span>
            {/* {originalName} */}
            {objectName}
          </span>
        )}
      </span>
    </div>
    )
  }




  let bucketObjectImageItemBody;

  bucketObjectImageItemBody = (
    <div 
      className={classes.objectImageItem}
      // className={classes.trashImageItem}
    >
      {imageBody}

      {/* <div>original-name: {originalName}</div> */}

      <span
        onClick={(event) => {
          if (!isLoading) {
            // fileClickSelectHandler(event, isInSelectedFileKeys, object.Key)
            editSelectedTrashObjects(object);
          }
        }}
      >
        {/* {isInSelectedFileKeys ? 'sele' : 'dese'} */}
        <span id={fileActionElementId}
          className={isImage 
            ? classes.objectImageItemCheck 
            : classes.objectImageItemOtherCheck
          }
        >
          {isInSelectedTrashObjects &&
            <span
            // className={classes.objectImageItemCheck}
            >
              {marks.checkMark}
            </span> 
          }
          {!isInSelectedTrashObjects && (
            <span 
            // className={classes.objectImageItemCheck}
            >
              {marks.noCheckMark}
            </span>
          )}
        </span>
      </span>

      <span
        title={'select action for file'}
        onClick={() =>{ 
          if (!isLoading) {
            setClickedObjectKeyHandler(object.Key); 
            showActionModalHandler(object.Key, 'file'); 
            setClickedActionInfoHandler(null);
          }
        }}
      >
        {/* trash-action {marks.triangleDown} */}
      </span>

      {clickedObjectKey === object.Key && (
        <div>
          <Backdrop 
            zIndex={'90'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              if (!isLoading) {
                setClickedObjectKeyHandler(''); 
                setClickedActionInfo(null);
              }
            }}
          />
          <ModalPosition 
            // top={modalPositions.top}
            // left={modalPositions.left}
            // right={modalPositions.right}
            // bottom={modalPositions.bottom}
            // heightLimit={modalPositions.heightLimit}
            // zIndex='100'
            modalStyle={actionModalStyle}
            // onClose={() => {
            //   setClickedObjectKeyHandler(''); 
            //   setClickedActionInfo(null);
            // }}
          >
            <TrashActions
              setClickedActionInfoHandler={setClickedActionInfoHandler}
              setClickedObjectKeyHandler={setClickedObjectKeyHandler}
              clickedActionInfo={clickedActionInfo}
              object={object}
              trashObjects={trashObjects}
              deleteTrashboxMetaHandler={deleteTrashboxMetaHandler}
              deleteFilesFoldersHandler={deleteFilesFoldersHandler}
              selectedTrashObjects={selectedTrashObjects}
              isLoading={isLoading}
            />
          </ModalPosition>
        </div>
      )}
      
    </div>
  );


  // let fileNameChangeBody;

  // if (clickedActionInfo && 
  //   clickedActionInfo.key === object.Key && 
  //   clickedActionInfo.name === 'change-file-name'
  // ) {
  //   fileNameChangeBody = (
  //     <ModalConfirm 
  //       hideButtons={true}
  //       onClose={() => {
  //         if (!isLoading) {
  //           setClickedActionInfoHandler(null);
  //         }
  //       }}
  //     >
  //       <FileNameChange 
  //         // setClickedObjectKeyHandler={setClickedActionInfo}
  //         setClickedActionInfoHandler={setClickedActionInfoHandler}
  //         object={object}
  //       />
  //     </ModalConfirm>
  //   );
  // }
    
    




  return (
  <Fragment>
    <div>{bucketObjectImageItemBody}</div>
    {/* <div>{fileNameChangeBody}</div> */}

    {/* {showFullSize && (
      <div>
      <Backdrop />
        <FullSizeImage 
          object={object}
          setShowFullSize={setShowFullSize}
          showFullSize={showFullSize}
        />
      </div>
    )} */}
  </Fragment>
  );
}

export default TrashboxImageItem;
