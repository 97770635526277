import { Fragment } from "react";
import { useTranslation } from 'react-i18next';

import FusionBaseImage from '../../images/imageFusion/lion.jpg';
import FusionStyleImage from '../../images/imageFusion/candy.jpg';
import FusionImage from '../../images/imageFusion/fusion-1705997507640.jpeg';

import FusionBaseImage2 from '../../images/imageFusion/green_bridge-w1024.jpg';
import FusionStyleImage2 from '../../images/imageFusion/mosaic.jpg';
import FusionImage2 from '../../images/imageFusion/fusion-1706000349693.jpeg';

// import "./ImageCombiner.css";

export default function ImageFusionInfo(props) {
  const {} = props;

  const [t] = useTranslation('translation');

  return (
    <Fragment>
      <div>
        <div className="combinerInfoTitle">
            Image Fusion
        </div>
        <br />

        <p>
          Image Fusion 
          {' '}
          {t('imageFusion17', 'is an AI-assisted image generation tool.')}
          <br/>
          {t('imageFusion18', 'User can generate a fused image from two input images (base image and style image).')}
        </p>
        <br />

        <p>
          {t('imageFusion19', 'By transfering styles of style image to base image, style image-like base image is generated.')}
        </p>
        <br />

        <p>
          {t('imageFusion20', 'It is possible to control how much amount of styles are transfered, by changing strength number.')}
        </p>
        <br />
        
        <p>
          {t('imageFusion21', 'Accept file types are jpeg and png files. File size should be less than 5 MB.')}
        </p>
      </div>
      <br/>

      <div>
        <div>
            <strong>
              {t('imageFusion22', 'Examples of Image Fusion')}
            </strong>
        </div>
        <br/>

        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionBaseImage} />
            </div>
            <div>{t('imageFusion04', 'base image')}</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionStyleImage} />
            </div>
            <div>{t('imageFusion05', 'style image')}</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionImage} />
            </div>
            <div>{t('imageFusion09', 'fusion image')}</div>
          </span>
        </div>

        <br/>
        <br/>
        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ maxHeight: "225px", maxWidth: "100%"}} src={FusionBaseImage2} />
            </div>
            <div>{t('imageFusion04', 'base image')}</div>
          </span>
          <span>
            <div>
              <img style={{ maxHeight: "225px", maxWidth: "100%"}} src={FusionStyleImage2} />
            </div>
            <div>{t('imageFusion05', 'style image')}</div>
          </span>
          <span>
            <div>
              <img style={{ maxHeight: "225px", maxWidth: "100%"}} src={FusionImage2} />
            </div>
            <div>{t('imageFusion09', 'fusion image')}</div>
          </span>
        </div>
      </div>
    </Fragment>
  );
}
