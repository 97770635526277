
export const isForEditorFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      mimeType.split('/')[1] !== 'gif' && 
      mimeType.split('/')[1] !== 'svg+xml'
  ) {
    return true;
  }
  return false;
};

export const isForCleanerFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || mimeType.split('/')[1] === 'jpeg')
  ) {
    return true;
  }
  return false;
};

export const isForDreamInpainterFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || mimeType.split('/')[1] === 'jpeg')
  ) {
    return true;
  }
  return false;
};

export const isForBgRemoverFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      mimeType.split('/')[1] !== 'gif' && 
      mimeType.split('/')[1] !== 'svg+xml'
  ) {
    return true;
  }
  return false;
};

export const isForIllustGeneratorFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || 
        mimeType.split('/')[1] === 'jpeg' ||
        mimeType.split('/')[1] === 'webp')
  ) {
    return true;
  }
  return false;
};

export const isForResizerFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || 
        mimeType.split('/')[1] === 'jpeg' ||
        mimeType.split('/')[1] === 'webp')
  ) {
    return true;
  }
  return false;
};

export const isForConvertFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || 
        mimeType.split('/')[1] === 'jpeg' ||
        mimeType.split('/')[1] === 'webp')
  ) {
    return true;
  }
  return false;
};


export const isForImageToImageFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || mimeType.split('/')[1] === 'jpeg')
  ) {
    return true;
  }
  return false;
};

export const isForImageRefinerFile = (mimeType) => {
  if (mimeType && mimeType.split('/')[0] === 'image' && 
      (mimeType.split('/')[1] === 'png' || mimeType.split('/')[1] === 'jpeg')
  ) {
    return true;
  }
  return false;
};