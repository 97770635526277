import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from "react-i18next";

import InputImage from '../../images/dreamInpainter/dreamInpainter/iopaint-input-200.png';
import MaskImage from '../../images/dreamInpainter/dreamInpainter/iopaint-mask-200.png';
import InpaintImage from '../../images/dreamInpainter/dreamInpainter/iopaint_cleanup-a-woman-face-200.png';
import CnImage from '../../images/dreamInpainter/dreamInpainter/iopaint-controlnet-canny-200.png';
import InputImage2 from '../../images/dreamInpainter/dreamInpainter/lion-100.jpg';
import MaskImage2 from '../../images/dreamInpainter/dreamInpainter/lion-mask-100.png';
import InpaintImage21 from '../../images/dreamInpainter/dreamInpainter/lion-512-an-ancient-man-face-100.png';
import InpaintImage22 from '../../images/dreamInpainter/dreamInpainter/lion-512-an-ancient-woman-face-100.png';
import InputImage3 from '../../images/dreamInpainter/dreamInpainter/rg-apple-output-h150.jpeg';
import MaskImage3 from '../../images/dreamInpainter/dreamInpainter/rg-apple-mask-h150.png';
import InpaintImage3 from '../../images/dreamInpainter/dreamInpainter/rr-apple-output-h150.png';
import InpaintImage32 from '../../images/dreamInpainter/dreamInpainter/rg-apple-inpaint-orange-output-h150.png';
import InputImage4 from '../../images/dreamInpainter/dreamInpainter/boy-man-150.png';
import MaskImage4 from '../../images/dreamInpainter/dreamInpainter/boy-man-mask-h150.png';
import InpaintImage4 from '../../images/dreamInpainter/dreamInpainter/boy-man-cleanup-h150.png';
import InpaintImage42 from '../../images/dreamInpainter/dreamInpainter/boy-man-cleanup2-h150.png';


import BgRemoveInputImage from '../../images/dreamInpainter/BgRemove/coffee-cups-150.jpg';
import BgRemoveRemoveImage from '../../images/dreamInpainter/BgRemove/coffee-cups-romove-bg-150.png';
import BgRemoveInputImage2 from '../../images/dreamInpainter/BgRemove/man-150.png';
import BgRemoveRemoveImage2 from '../../images/dreamInpainter/BgRemove/man-bg-remove-150.png';
import IllustInputImage1 from '../../images/dreamInpainter/IllustrationGenerator/illust-man-input.png';
import IllustImage1 from '../../images/dreamInpainter/IllustrationGenerator/illust-man.jpeg';
import IllustInputImage6 from '../../images/dreamInpainter/IllustrationGenerator/illust-bridge-input-h350.jpg';
import IllustImage6 from '../../images/dreamInpainter/IllustrationGenerator/illust-bridge.jpg';

import { dreamInpaniterLink } from '../../App';

import { marks } from '../../utils/marks';
import classes from './AboutDreamInpainter.module.css';


function AboutDreamInpainter(props) {
  const { } = props;

  const [t] = useTranslation("translation");

  const [showMoreExample, setShowMoreExample] = useState(false);
  const [showBgRemoveDetail, setShowBgRemoveDetail] = useState(false);
  const [showIllustGeneratorDetail, setShowIllustGeneratorDetail] = useState(false);

  let aboutIopaintBody;

  aboutIopaintBody = (
    <div>
      <div className={classes.aboutIopaintSection}>
      <p>
          Kura Dream Inpainter has image editing tools that include 
          {' '}
          <strong>
            Kura Dream Inpainter, 
          </strong>
          {' '}
          <span
          // onClick={scrollToBgRemove}
          >
            <strong>
              Background Remover, 
            </strong>
          </span>
          {' '}
          <span 
          // onClick={scrollToIllustGenerator}
          >
            <strong>
              Illustration Generator.
            </strong>
          </span>
        </p>
        <br/>

        <p>
          At Kura Dream Inpainter, 
          {' '}
          {t('aboutPage.31', 'user can modify specific parts of the image without affecting other parts.')}
        </p>
        <br/>
        <p>
        {t('aboutPage.32', 'Using advanced AI-technologies, selected part of image is replaced with generated new image that matches text prompt description.')}
        </p>
      </div>
      
      <div>
        <div>
          <strong>
          {t('aboutPage.34', 'Example of image inpainting')}
          </strong>
        </div>
        <br/>

                  {/* <p>
            {t('aboutPage.33', 'Generated image more precicely captures the alignment of objects')}
            {' '}
            by using ControlNet. 
          </p> */}
          {/* <div>
          {t('aboutPage.35', 'Image inpainting with text prompt description')}
            , 
            {' '}
            "a woman face"
          </div> */}
          <br/>

        <div className={classes.aboutIopaintImageContainer}>
          <div>
            <img className={classes.aboutIopaintImage}
              src={InputImage}
            />
            <div>Input image</div>
          </div>
          <div>
            <img className={classes.aboutIopaintImage}
              src={MaskImage}
            />
            <div>Selected part</div>
          </div>
          <div>
            <img className={classes.aboutIopaintImage}
              src={InpaintImage}
            />
            <div>a woman face</div>
          </div>
          {/* <div>
            <img className={classes.aboutIopaintImage}
              src={CnImage}
            />
            <div>with ControlNet</div>
          </div> */}
        </div>
        <br/>

        <div className={classes.aboutIopaintImageContainer}>
          <div>
              <img className={classes.aboutIopaintImage}
                src={InputImage4}
              />
              <div>Input image</div>
            </div>
            <div>
              <img className={classes.aboutIopaintImage}
                src={MaskImage4}
              />
              <div>Selected part</div>
            </div>
            <div>
              <img className={classes.aboutIopaintImage}
                src={InpaintImage4}
              />
              <div>a man with sunglasses</div>
            </div>
            <div>
              <img className={classes.aboutIopaintImage}
                src={InpaintImage42}
              />
              <div>a man with sunglasses</div>
            </div>
          </div>
          <br/>

        <div>
          <span onClick={() => { setShowMoreExample(!showMoreExample) }}>
            {t('aboutPage.42', 'Display more examples')} {marks.triangleDown}
          </span>
        </div>
        <br/>

        {showMoreExample && (
          <div>
            <br/>
            <div className={classes.aboutIopaintImageContainer}>
              <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InputImage3}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={MaskImage3}
                  />
                  <div>Selected part</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InpaintImage3}
                  />
                  <div>a red apple</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InpaintImage32}
                  />
                  <div>an orange</div>
                </div>
              </div>
              <br/>
              <br/>

              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InputImage2}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={MaskImage2}
                  />
                  <div>Selected part</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InpaintImage21}
                  />
                  <div>a man face</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintMoreImage}
                    src={InpaintImage22}
                  />
                  <div>a woman face</div>
                </div>
              </div>
              <br/>
            <br/>
          </div>
        )}
        <br/>

        <div>
          <span
            onClick={() => { setShowBgRemoveDetail(!showBgRemoveDetail) }}
          >
            About Background Remover (in Dream Inpainter) {marks.triangleDown}
          </span>
        </div>
        <br/>

        {showBgRemoveDetail && (
          <div>
            <div>
              <strong>
                Background Remover
              </strong>
            </div>
            <br/>
            <div>
              <p>
                {t('aboutPage.39', 'Advanced AI-technologies enabled to delete background from image.')}
              </p>
            </div>
            <br/>

            <div>
              <div>
                Examples of background remove
              </div>

              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={BgRemoveInputImage}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={BgRemoveRemoveImage}
                  />
                  <div>Removed</div>
                </div>
              </div>
              <br/>

              <div className={classes.aboutIopaintImageContainer}>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={BgRemoveInputImage2}
                  />
                  <div>Input image</div>
                </div>
                <div>
                  <img className={classes.aboutIopaintImage}
                    src={BgRemoveRemoveImage2}
                  />
                  <div>Removed</div>
                </div>
              </div>
            </div>
            <br/>
            <div>
              <a 
                href={`${dreamInpaniterLink}/background-remover`} 
                target="_blank" rel="noreferrer noopener"
              >
                Go to Background Remover Page {marks.externalLinkMark}
              </a>
            </div>
            <br/>
            <br/>
          </div>

        )}

        <div>
          <span
            onClick={() => { 
              setShowIllustGeneratorDetail(!showIllustGeneratorDetail) 
            }}
          >
            About Illustration Generator  (in Dream Inpainter) {marks.triangleDown}
          </span>
        </div>
        <br/>

        {showIllustGeneratorDetail && (
          <div>
            <div>
              <strong>
                Illustration Generator
              </strong>
            </div>
            <br/>

            <p>
              Illustration Generator is 
              {' '}
              {t('aboutPage.40', 'a tool to generate a illustration image from a input image.')}
            </p>

            <p>
              {t('aboutPage.41', 'By using AI-technologies, input picture image can be converted into illustarion image.')}
            </p>
            <br/>

            <div>
              Examples of Illustration Generation
            </div>

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={IllustInputImage1}
                />
                <div>
                  input image
                </div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={IllustImage1}
                />
                <div>
                  generated image
                </div>
              </div>
            </div>
            <br/>

            <div className={classes.aboutIopaintImageContainer}>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={IllustInputImage6}
                />
                <div>
                  input image
                </div>
              </div>
              <div>
                <img className={classes.aboutIopaintImage}
                  src={IllustImage6}
                />
                <div>
                  generated image
                </div>
              </div>
            </div>
            <br/>

            <div>
              <a 
                href={`${dreamInpaniterLink}/illustration-generator`} 
                target="_blank" rel="noreferrer noopener"
              >
                Go to Illustration Generator Page {marks.externalLinkMark}
              </a>
            </div>
            <br/>
            <br/>

          </div>
        )}
        <br/>

      </div>
    </div>
  )


  
  return (
    <Fragment>
      {aboutIopaintBody}
    </Fragment>
  );
}

export default AboutDreamInpainter;
