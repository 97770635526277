import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import FolderAndFile from "../BucketComponents/FolderAndFile/FolderAndFile";

import { useStore } from "../../hook-store/store";
import { 
  getObjectFile, 
  getOriginalFileNameFromKey,
} from "../../utils/bucket/bucket-object-util";
import { BACKEND_URL, loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
// import './ImageFusion.css';
import './ConvertFileSelectContents.css'

export default function ConvertFileSelectContents(props) {
  const { 
    setShowFileSelectModal,
    setConvertFilesFromUploadedHandler,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { bucketName } = store.bucketObjectStore;
  const { isFusionServer } = store.imageFusionStore;
  const { isAuth } = store.uiStore;

  const [uploadSelectedObjects, setUploadSelectedObjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');


  // console.log(uploadSelectedObjects);
  const getUploadSelectedHandler = (objects) => {
    setUploadSelectedObjects(objects);
  }


  let imageConvertFileSelectContentsBody;

  if (!isAuth) {
    imageConvertFileSelectContentsBody = (
      <div>
        <div>
          <p>
          {t('imageConvert14', 'To select from uploaded files, login is required, or signup to create account.')}
          </p>
          <br/>

          <div className="convertResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      </div>
    );

  } else {
    imageConvertFileSelectContentsBody = (
      <div>
        <section className="convertFileSelectContentsSection">
          <div className="convertFileSelectContentsSectionTitle">
          {t('imageConvert15', 'Selected Files')}
          </div>
          <ul className="convertFileSelectedFiles">
            {uploadSelectedObjects.map(object => {
              if (!object.Key.endsWith('/')) {
                return (
                  <li key={object.Key}
                  >
                    {getOriginalFileNameFromKey(object.Key)}
                  </li>
                );
              }
            })}
          </ul>
          <button className="btnBase"
            disabled={isLoading || uploadSelectedObjects.length === 0}
            onClick={() => {
              setConvertFilesFromUploadedHandler(uploadSelectedObjects);
            }}
          >
            {t('imageConvert16', 'Import into')}
            {' '}
            Image Convert
          </button>
        </section>

        <section className="convertFileSelectContentsSection">
          <div className="convertFileSelectContentsSectionRow">
            <span className="convertFileSelectContentsSectionTitle">
              Select image files
            </span>
          </div>
          <div className="convertFileSelectContentsSectionRow">
            <FolderAndFile 
              onFileClick={() => {}}
              withImageLink={true}
              withAllLink={false}
              withCheckbox={true}
              getUploadSelectedHandler={getUploadSelectedHandler}
            />
          </div>
        </section>
      </div>
    );
  }



  return (
    <Fragment>
      <div className="convertFileSelectContents">
        {imageConvertFileSelectContentsBody}
        {errorMessage && (
          <div>
            <strong>{errorMessage}</strong>
          </div>
        )}
      </div>
    </Fragment>
  );
}
