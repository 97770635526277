import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import { useStore } from '../../hook-store/store';
import { createCompressedImage } from '../../utils/image-util';

import { WATAKURAURL } from '../../App';

import { marks } from '../../utils/marks';

// import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";



function ShareAbout(props) {
  const { 
    // editorRef,
    fileName,
    outputFormat,
    createShareFile,
    fileForShare,
    shareStartHandler,
    shareButtonDisabled,
    errorMessage,
    isLoading,
    // loadedImageName,
    // undoStackLength,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  // const { 
  //   bucketName, 
  //   topLevels, 
  //   totalSizeGB, 
  //   limitGB,
  //   selectedPrefix,
  // } = store.bucketObjectStore;
  const { isAuth } = store.uiStore;
  const { loadedImageName, undoStackLength } = store.imageEditorStore;

  const [editShareFile, setEditShareFile] = useState();

  useEffect(() => {
    if (fileName && outputFormat && loadedImageName) {
      getEditFileHandler();
    }
  },[fileName, outputFormat, loadedImageName]);

  const getEditFileHandler = () => {
    const editedFile = createShareFile(fileName, outputFormat)

    // console.log(editedFile);
    if (editedFile) {
      setEditShareFile(editedFile);
    }
  }

  let shareAboutBody;
  
  shareAboutBody = (
    <div>
      <p>
      {t('shareImage02', 'Your images can be shared at')}
        {' '}
        <a href={WATAKURAURL} 
          target="_blank"
          rel="noreferrer noopener">
            watakura
        </a>. 
        <br/>
        watakura 
        {' '}
        {t('shareImage03', 'is communication tools.')} 
        {' '}
        {t('shareImage04', 'Users can communicate with other users using various ways that include media feed, talk, and group.')}
      </p>
      <br />

      <p>
      {t('shareImage05', 'It is also possible to create NFT with your image for trading at')}  
        {' '}
        <a href={'https://stellar-nftplace.spaceeight.work'} 
          target="_blank"
          rel="noreferrer noopener">
            Stellar NFT Place.
        </a>
        <br/>
      </p>
      <br />
      <p>
      {t('shareImage06', 'For image file sharing')}
        {' '}at watakura, 
        {' '}
        {t('shareImage07', 'file size should be less than 5 MB')}
      </p>

      <br/>
      {!fileForShare && editShareFile && loadedImageName && (
        <p>
          <div>
          {t('shareImage08', 'file size')}: {numeral(editShareFile.size).format('0.0 b')}
          </div>
        </p>
      )}
      {fileForShare && (
        <p>
          <div>
          {t('shareImage08', 'file size')}: {numeral(fileForShare.size).format('0.0 b')}
          </div>
        </p>
      )}

      <br/>
      <p>
        <div>
        {t('shareImage09', 'Image files can be resized at')}
          {' '}
          Image Resizer or Image Convert.
          {/* {' '}
          <Link to="/image-resizer">
          </Link> */}
        </div>
      </p>
     <br/>
      <div className='shareEditImageButtons'>
          <button className="btnBase"
            title="share at watakura"
            // disabled={!loadedImageName}
            disabled={shareButtonDisabled}
            onClick={() => { 
              if (!isLoading) {
                shareStartHandler()
              }
            }}
          >
            {t('shareImage01', 'Share image')} 
            {' '}
            at watakura {marks.shareSquareOMark}
          </button>

        </div>
  
        {errorMessage && (
          <div>
            <strong>{errorMessage}</strong>
          </div>
        )}
    </div>
  )


  return (
    <Fragment>
      <div>{shareAboutBody}</div>
    </Fragment>
  );
}

export default ShareAbout;
