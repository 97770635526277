import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import download from 'downloadjs';

import { useStore } from '../../../hook-store/store';

import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

function DownLoadFile(props) {
  const { 
    editorRef,
    // loadedImageName,
    // undoStackLength,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    selectedPrefix,
  } = store.bucketObjectStore;
  const { isAuth } = store.uiStore;
  const { loadedImageName, undoStackLength } = store.imageEditorStore;


  const [imageData, setImageData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [outputFormat, setOutputFormat] = useState('png');
  const [editFile, setEditFile] = useState();

  const [isLoading, setIsLoading] = useState();



  useEffect(() => {
    if (loadedImageName) {
      const nList = loadedImageName.split('.');
      nList.pop();
      const noExtName = nList.join('.');
      setFileName(noExtName);


      const ext = loadedImageName.split('.').pop().toLowerCase();
      // console.log(ext);
      if (ext === 'jpg' || ext === 'jpeg') {
        setOutputFormat(ext);
      } else {
        setOutputFormat('png');
      }
    }
  },[loadedImageName]);


  const setFileNameHandler = (event) => {
    // setFileName(event.target.value + '.' + outputFormat);
    setFileName(event.target.value);
  };


  // const handleToDataURL = () => {
  //   console.log('handleToDataURL');
  //   const editorInstance = editorRef.current.getInstance();
  //   console.log(editorInstance);
  //   const toDataUrl = editorInstance.toDataURL(
  //     // {
  //     //   format: 'jpeg',
  //     // }
  //   );

  //   // console.log(toDataUrl);

  //   if (toDataUrl) {
  //     setImageData(toDataUrl);
  //   }
  //   // editorInstance.loadImageFromURL(editorInstance.toDataURL(), 'FilterImage').then(() => {
    
  //   //     // editorInstance.addImageObject(imgUrl);
  //   // });
  // };


  // const selectFileHandler = (event) => {
  //   console.log(event.target.files);

  //   setSelectedFile(event.target.files[0]);
  //   setFileName(event.target.files[0].name);

  //   handleLoadImageFromFile(event.target.files[0], event.target.files[0].name);
  // };

  // const handleLoadImageFromFile = (file, name) => {
  //   const imageEditor = editorRef.current.getInstance();
  //   // console.log(editorInstance);
  //   imageEditor.loadImageFromFile(file, name).then(result => {
  //     console.log(result);
  //     console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
  //     console.log('new : ' + result.newWidth + ', ' + result.newHeight);
  //     imageEditor.ui.activeMenuEvent();
  //   });
  // };


  // const imageUrlInputChangeHandler = (event) => {
  //   //// extract path from url
  //   const inputUrl = event.target.value;

  //   if (inputUrl) {
  //     const url = new URL(inputUrl);
  //     console.log(url);
  
  //     const filePath = url.pathname;
  //     const fileName = filePath.split('/')[filePath.split('/').length -1];
  //     console.log(filePath, fileName);
  
  //     handleLoadImageFromURL(inputUrl, filePath);
  
  //     setFilePath(filePath);
  //     setFileName(fileName);
  //   }
  // };

  // const handleLoadImageFromURL = (url, name) => {
  //   const imageEditor = editorRef.current.getInstance();
  //   // console.log(editorInstance);

  //   imageEditor.loadImageFromURL(url, name).then(result => {
  //     console.log(result);
  //     console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
  //     console.log('new : ' + result.newWidth + ', ' + result.newHeight);
  //     imageEditor.ui.activeMenuEvent();
  //   });
  // };


  const downloadImageHandler = (outputFormat) => {
    const imageEditorInst = editorRef.current.imageEditorInst;
    // const data = imageEditorInst.toDataURL();

    let data;

    if (outputFormat === 'jpeg' || outputFormat === 'jpg') {
      data = imageEditorInst.toDataURL({ 
        format: 'jpeg',
        // quality: 1,
      });
    } else {
      data = imageEditorInst.toDataURL();
    }

    const imageName = imageEditorInst._graphics.imageName;

    if (imageName && data) {
      const nList = imageName.split('.');
      nList.pop();
      const noExtName = nList.join('.');

      const mimeType = data.split(";")[0];
      const extension = data.split(";")[0].split("/")[1];
      // download(data, `image.${extension}`, mimeType);
      // download(data, `${noExtName}`, mimeType);
      download(data, `${noExtName}`, mimeType);
    }
  };


  const createFileHandler = (fileName, outputFormat) => {
    const imageEditor = editorRef.current.getInstance();
    
    // const imageName = imageEditor._graphics.imageName;
    // const ext = imageName.split('.').pop().toLowerCase();
    // console.log(imageName, ext);

    let data;

    if (outputFormat === 'jpeg' || outputFormat === 'jpg') {
      data = imageEditor.toDataURL({ format: 'jpeg' });
    } else {
      data = imageEditor.toDataURL();
    }

    const createdFile = dataURLtoFile(data, fileName + '.' + outputFormat);
    return createdFile;
    // console.log(createdFile);
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }



  return (
    <Fragment>
      <div>
        <button className="btnBase"
          title="download"
          disabled={!loadedImageName}
          onClick={() => { downloadImageHandler(outputFormat) }}
        >
          {t('imageEditor.03', 'Download')}
        </button>
      </div>
    </Fragment>
  );
}

export default DownLoadFile;
