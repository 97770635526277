import { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import DogeCImage from "../../images/combiner/dog2-combine.png";
import Doge1Image from "../../images/combiner/dog-1.png";
import Doge2Image from "../../images/combiner/dog2-1.png";
import InputImage21 from "../../images/combiner/lsimage-for-combine-1-h200.jpeg";
import InputImage22 from "../../images/combiner/lsimage-for-combine-2-h200.jpeg";
import CombineResultImage2 from "../../images/combiner/lsimage-combine-result-h200.jpeg";

import "./ImageCombiner.css";

export default function CombinerInfo(props) {
  const {} = props;

  const [t] = useTranslation('translation');

  return (
    <Fragment>
      <div>
        <div className="combinerInfoTitle">
            Image Combiner
        </div>
        <br />

        <p>
          Image Combiner 
          {' '}
          {t('combinerInfo01', 'is a tool to combine two input images and generate a new mixed image.')}
        </p>
        <br />
        <p>
          {t('combinerInfo02', 'Accept file types are jpeg and png files. Input files should be same type.')} 
          <br/>
          {t('combinerInfo09', 'Image width and height should be less than 2000px.')}
          <br/>
          {t('combinerInfo03', 'File size should be less than 5 MB. Files can be resized')} 
          {' '}
          at Image Resizer.
        </p>
        <br />

        <p>
          <div>
          {t('combinerInfo04', 'It is recommended to use two images with same aspect ratio, because output image size is size of smaller input image.')}
          </div>
          <div>
            At Image Editor, 
            {' '}
            {t('combinerInfo05', 'it is possible to crop images for desired image aspect ratio.')}
          </div>
        </p>
        <br />

        <p>
        {t('combinerInfo06', 'Image quality and appearance are affected by displayed image size. Please check the image after combining images.')}
        </p>
      </div>
      <br/>

      <div>
        <div>
            <strong>
            {t('combinerInfo07', 'Example of Image combine')}
            </strong>
        </div>
        <br/>
        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ height: "225px" }} src={Doge1Image} />
            </div>
            <div>{t('combiner03', 'Image')} 1</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={Doge2Image} />
            </div>
            <div>{t('combiner03', 'Image')} 2</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={DogeCImage} />
            </div>
            <div>{t('combinerInfo08', 'Combined image')}</div>
          </span>
        </div>
        <br/>

        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ height: "250px" }} src={InputImage21} />
            </div>
            <div>{t('combiner03', 'Image')} 1</div>
          </span>
          <span>
            <div>
              <img style={{ height: "250px" }} src={InputImage22} />
            </div>
            <div>{t('combiner03', 'Image')} 2</div>
          </span>
          <span>
            <div>
              <img style={{ height: "250px" }} src={CombineResultImage2} />
            </div>
            <div>{t('combinerInfo08', 'Combined image')}</div>
          </span>
        </div>
        <br/>
        
      </div>
    </Fragment>
  );
}
