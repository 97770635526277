import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import FolderAndFile from "../BucketComponents/FolderAndFile/FolderAndFile";

import { useStore } from "../../hook-store/store";
import { 
  getObjectFile, 
  getOriginalFileNameFromKey,
} from "../../utils/bucket/bucket-object-util";
import { BACKEND_URL, loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import './ImageCombiner.css';
import './CombinerFileSelectContents.css'

export default function CombinerFileSelectContents(props) {
  const { 
    selectedFileKind,
    setSelectedFileKind,
    setInputFile,
    setInputFile2,
    setShowFileSelectModal,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { bucketName } = store.bucketObjectStore;
  const { isFusionServer } = store.imageFusionStore;
  const { isAuth } = store.uiStore;

  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    setSelectedFileKind('');

    return () => {
      setSelectedFileKind('');
    }
  },[]);

  const objectFileDownloadHandler = async (object) => {
    try {
      setErrorMessage('');
      dispatch('SET_GLOADING', true);

      if (object.mimeType !== 'image/png' && object.mimeType !== 'image/jpeg') {
        throw new Error('file should be png or jpeg file');
      }

      const fileBlob = await getObjectFile(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucketName,
        object.Key,
        object.mimeType,
      );

      console.log(fileBlob);

      if (fileBlob) {
        const file = new File([fileBlob], getOriginalFileNameFromKey(object.Key), { type: object.mimeType });
  
        if (selectedFileKind === 'one') {
          setInputFile(file);
        }

        if (selectedFileKind === 'two') {
          setInputFile2(file);
        }
      }

      dispatch('SET_GLOADING', false);
      setShowFileSelectModal(false);

    } catch(err) {
      console.log(err);
      setErrorMessage(err.message);

      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 5);
      throw(err);
    }
  };
  
  
  let imageFusionFileSelectContentsBody;

  if (!isAuth) {
    imageFusionFileSelectContentsBody = (
      <div>
        <div>
          <p>
            To select from uploaded files, login is required, or signup to create account.
          </p>
          <br/>

          <div className="resizerResizeLoginButtons">
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      </div>
    );

  } else {
    imageFusionFileSelectContentsBody = (
      <div>
        <section className="fusionFileSelectContentsSection">
          <div className="fusionFileSelectContentsSectionRow">
            <span className="fusionFileSelectContentsSectionTitle">
              Select file type
            </span>
          </div>
          <div className="fusionFileSelectContentsSectionRow">
            <div className="fusionFileSelectContentsButtons">
              <button className="btnBase"
                onClick={() => {
                  if (!isLoading) {
                    setSelectedFileKind('one');
                  }
                }}
              >
                Image 1
              </button>
              <button className="btnBase"
                onClick={() => {
                  if (!isLoading) {
                    setSelectedFileKind('two');
                  }
                }}
              >
                Image 2
              </button>
            </div>
          </div>
          <div className="fusionFileSelectContentsSectionRow">
            {selectedFileKind === 'one' && (
              <div>
                <strong>image 1</strong>
              </div>
            )}
            {selectedFileKind === 'two' && (
              <div>
                <strong>image 2</strong>
              </div>
            )}
          </div>
        </section>
        
        {selectedFileKind && (
          <section className="fusionFileSelectContentsSection">
            <div className="fusionFileSelectContentsSectionRow">
              <span className="fusionFileSelectContentsSectionTitle">
                Select image file (png or jpeg file)
              </span>
            </div>
            <div className="fusionFileSelectContentsSectionRow">
              <FolderAndFile 
                onFileClick={objectFileDownloadHandler}
                withImageLink={true}
                withAllLink={false}
                withCheckbox={false}
              />
            </div>
          </section>
        )}
      </div>
    );
  }



  return (
    <Fragment>
      <div className="fusionFileSelectContents">
        {imageFusionFileSelectContentsBody}
        {errorMessage && (
          <div>
            <strong>{errorMessage}</strong>
          </div>
        )}
      </div>
    </Fragment>
  );
}
