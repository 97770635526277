import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

import Backdrop from '../../../Backdrop/Backdrop';
import FileAddTrashboxMeta from './FileAddTrashboxMeta';
import ModalConfirm from '../../../Modal/ModalConfirm';
import ImageEditActions from './ImageEditActions';

import { useStore } from '../../../../hook-store/store';
import { isImageMimeType } from '../../../../utils/bucket/bucket-object-util';
import { 
  getObjectMetadata,
  addTrashMetadata,
 } from '../../../../utils/bucket/object-metadata-util';
import {
  isForEditorFile,
  isForCleanerFile,
  isForDreamInpainterFile,
  isForBgRemoverFile,
  isForResizerFile,
  isForConvertFile,
  isForIllustGeneratorFile,
} from '../../../../utils/bucket/image-file-edit-util';

 import { 
  BACKEND_URL, 
  dreamInpaniterLink,
} from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';


function FileActions(props) {

  const {
    setClickedActionInfoHandler,
    getObjectSignedUrlHandler,
    fileDownloadHandler,
    deleteBucketObjectsHandler,
    selectedFileKeysHandler,
    setClickedObjectKeyHandler,
    clickedActionInfo,
    objectSignedUrl,
    isInSelectedFileKeys,
    object,
    // showFullSize,
    setShowFullSize,
    isImage,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('FileActions.js-props', props, object.Key);

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  // const movePrefix = store.bucketObjectStore.movePrefix;
  // const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;

  const { currentPrefix } = store.bucketObjectStore;
  const { allObjectsMetaList } = store.objectMetadataStore;

  const gLoading = store.uiStore.gLoading;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditImageButtons, setShowEditImageButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  const addTrashboxMetaHandler = async (bucketName, currentPrefix, allObjectsMetaList) => {
    try {

      dispatch('SET_GLOADING', true);

      let oldMetadata;
        
      const objectMeta = allObjectsMetaList.find(element => {
        return element.Key === object.Key;
      });

      if (objectMeta) {
        oldMetadata = objectMeta
      }

      // const objectMeta = await getObjectMetadata(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   bucketName,
      //   object.Key,
      // );
      
      // if (objectMeta) {
      //   oldMetadata = objectMeta.data;
      // }
  

    console.log(objectMeta, oldMetadata);

    if (oldMetadata) {
      console.log('oldMetadata', oldMetadata);
      const parsedOldMeta = JSON.parse(oldMetadata.objectMeta.Metadata.metastring);
      const updateMeta = {
        ...parsedOldMeta,
        location: 'trashbox',
        trashMoveTime: Date.now(),
      };

      console.log(updateMeta);

      // return;

      const resData = await addTrashMetadata(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          currentPrefix,
          object.Key,
          updateMeta,
          dispatch,
        );
      
        console.log(resData);


        if (resData.data) {

          dispatch('SET_OBJECTACTION', {
            actionType: 'update-metadata',
            delete: true,
            add: true,
            result: resData.data,
          });

          const noOldObjectMetaList = allObjectsMetaList.filter(element => {
            return element.Key !== object.Key;
          });
          
          const addedList = noOldObjectMetaList.concat({
            Key: resData.data.Key,
            objectMeta: resData.data.objectMeta,
          });

          dispatch('SET_ALLOBJECTSMETALIST', addedList);

          dispatch('SHOW_NOTIFICATION', {
            status: '',
            title: 'moved to trash box',
            message: '',
          });
    
          setTimeout(() => {
            dispatch('CLEAR_NOTIFICATION');
          },1000 * 3);
        }

        dispatch('SET_GLOADING', false);

    }
    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `move to trashbox failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);
    }
  };


  // let deleteModalBody;

  // if (clickedActionInfo && clickedActionInfo.name === 'delete-object') {
  //   deleteModalBody = (
  //     <ModalConfirm 
  //     onClose={() => { setClickedActionInfoHandler(null); }}
  //     onAccept={() => { deleteBucketObjectsHandler(bucketName, [object.Key]); }}
  //     isLoading={isLoading}
  //   >
  //     <div>
  //       {t('fileAction.01', 'delete file')} ?
  //     </div>
  //   </ModalConfirm>
  //   )
  // }

  let addTrashMetaModalBody;

  if (clickedActionInfo && clickedActionInfo.name === 'file-add-trashbox-meta') {
    addTrashMetaModalBody = (
      <div>
        <Backdrop 
          zIndex={'110'}
          // backgroundColor={'rgba(0, 0, 0, 0.1)'}
          onCancel={() => { 
            if (!isLoading) {
              setClickedActionInfoHandler(null);
            }
          }}
        />  
        <ModalConfirm 
          onClose={() => { setClickedActionInfoHandler(null); }}
          onAccept={() => { 
            addTrashboxMetaHandler(
              bucketName,
              currentPrefix,
              allObjectsMetaList,
            );
           }}
          isLoading={isLoading}
        >

          <div>
            {t('trash.13', 'move to trash box')} ?
          </div>
        </ModalConfirm>
      </div>
    )
  }

  const fileName = object.Key.split('/')[object.Key.split('/').length - 1]
  const timeEndIndex = fileName.indexOf('Z');
  // console.log(timeEndIndex);
  const originalName = fileName.substring(timeEndIndex+2);

  let fileActionsBody;

  fileActionsBody = (
    <div className={classes.objectActionModal}
    // style={{display:"flex", flexDirection:"column"}}
    >
      
      {/* {deleteModalBody} */}

      {/* <button name="get-url"
        disabled={isLoading}
        onClick={(event) => { 
          if (!isLoading) {
            getObjectSignedUrlHandler(bucketName, object.Key, 3600);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        get-signed-url
      </button>
      {objectSignedUrl && clickedActionInfo.key === object.Key && (
        <div>
          url-for-download (valid for 1 hour): <a href={objectSignedUrl} target='_blank' rel="noreferrer noopener">
            {objectSignedUrl}
            </a>
        </div>
      )} */}
    
    <button className={classes.objectActionListItem}
      name="filename"
    >
      {originalName}
      {' '}
      ({numeral(object.Size).format('0.0 b')})
    </button>

    {isImage && (
      <button className={classes.objectActionListItem}
        name="fullsize"
        onClick={(event) => { 
            if (!isLoading) {
              setShowFullSize(true);
              setClickedActionInfoHandler(event, object.Key);
              // console.log('fullSize')
          }}
        }
      >
        {marks.expandMark} {t('fileAction.08', 'display full size')}
      </button>
    )}



    <button className={classes.objectActionListItem}
      name="download"
      disabled={isLoading}
      onClick={(event) => { 
        if (!isLoading) {
          fileDownloadHandler(bucketName, object.Key, 3600); 
          setClickedActionInfoHandler(event, object.Key);
        }
      }}
    >
      {marks.downloadMark} {t('fileAction.03', 'download file')}
    </button>
    {/* <button className={classes.objectActionListItem}
      name="delete-object"
      disabled={isLoading}
      onClick={(event) => {
        if (!isLoading) {
          // deleteBucketObjectsHandler(bucketName, [object.Key]);
          setClickedActionInfoHandler(event, object.Key);
          // setShowDeleteModal(true);
        }
      }}
    >
      {marks.trashMark} {t('fileAction.01', 'delete file')}
    </button> */}

    {/* {!isInSelectedFileKeys && (
      <button name="select-object" 
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'add', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-add-object
      </button>
    )}
    {isInSelectedFileKeys && (
      <button name="deselect-object"
        disabled={isLoading}
        onClick={(event) => {
          if (!isLoading) {
            selectedFileKeysHandler(object.Key, 'delete', selectedFileKeys);
            setClickedActionInfoHandler(event, object.Key);
          }
        }}
      >
        select-delete-object
      </button>
    )} */}

  
    <button className={classes.objectActionListItem}
      disabled={isLoading}
      name="change-file-name"
      onClick={(event) => {
        if (!isLoading) {
          setClickedActionInfoHandler(event, object.Key);
          setClickedObjectKeyHandler('');
        }
      }}
    >
      {marks.editMark} {t('fileAction.02', 'change file name')}
    </button>
    
    {isImageMimeType(object.mimeType) && (
      <button className={classes.objectActionListItem}
        disabled={isLoading}
        name=""
        onClick={(event) => {
          if (!isLoading) {
            setShowEditImageButtons(!showEditImageButtons);
          }
        }}
      >
        {t('fileAction.14', 'image edit')} {marks.triangleDown}
      </button>
    )}
    {showEditImageButtons && (
      <ImageEditActions 
        object={object}
      />
    )}

    <FileAddTrashboxMeta 
      object={object}
      setClickedActionInfoHandler={setClickedActionInfoHandler}
    />
  </div>
  )


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* {deleteModalBody} */}
      {addTrashMetaModalBody}
      {fileActionsBody}
    </Fragment>
  );
}

export default FileActions;
