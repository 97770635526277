import React, { Fragment, useEffect, useState } from 'react';

// import openSocket from 'socket.io-client';
import { io } from "socket.io-client";

import { useStore } from '../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'

import { BACKEND_URL } from '../../App';

// export let socket;
// import classes from './PostSelect.module.css';

function UserSocketConnect(props) {
  // const { } = props;

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;
  // const { allBucketObjects } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  // const { shareObjects } = store.shareObjectStore;
  // const { bucketName, currentPrefix } = store.bucketObjectStore;

  // const [shareObjectUpdateData, setShareObjectUpdateData] = useState();

  useEffect(() => {
      // const socket = openSocket(BACKEND_URL);
      const socket = io(BACKEND_URL, {
        // autoConnect: false
      });

      const userId = localStorage.getItem('userId');

      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
        // dispatch('SET_SOCKETID', socket.id);

        socket.emit('user-id', {
          socketId: socket.id,
          userId: userId,
          token: localStorage.getItem('token'),
        });
      });

      socket.on("casauthsso-user-name-data-update", (data) => {
        console.log('casauthsso-user-name-data-update data', data);
        // dispatch('SET_SOCKETID', socket.id);
        if (data?.userNameData?.userId === localStorage.getItem('userId')) {
          dispatch('SET_USERNAMEDATA', data.userNameData);
        }
      });


      socket.on('upload', data => {
        // console.log('upload socket data', data);
  
        dispatch('SHOW_NOTIFICATION', {
          status: 'pending',
          title: '',
          message: `Storing... ${data.originalname} ${data.message}`,
        });
  
        // setTimeout(() => {
        //   dispatch('CLEAR_NOTIFICATION');
        // }, 1000*3);
        
      });


      //// for object and metadata update, when  files uploaded from other image-photo frontend
      socket.on('files-upload', data => {
        console.log('files-upload data', data);
        
        const uData = data.data;
        
        //// update objects when upload origin is different
        if (isAuth && uData && uData.origin && 
            uData.origin !== window.location.origin 
        ) {
            dispatch('SET_OBJECTACTION', {
              actionType: 'file-upload',
              delete: false,
              add: true,
              result: '',
            });

            if (uData.dataForMetadata) {
              dispatch('SET_DATAFORMETADATA', uData.dataForMetadata);
            }
          
        }
      });

  },[]);


  return (
    <Fragment></Fragment>
  );
}

export default UserSocketConnect;