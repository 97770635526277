import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// import AuthRequired from "../../components/Auth/AuthRequired";

import { useStore } from "../../hook-store/store";

import { marks } from "../../utils/marks";
import classes from './NotPageFound.module.css';

const NotApisAvailable = (props) => {
  // const { } = props;
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth, gLoading } = store.uiStore;
  const { isStripeApi } = store.stripeData;

  useEffect(() => {

  }, []);

  let notServiceAvailableBody;

  notServiceAvailableBody = (
    <div className={classes.notPageFoundContent}>
      <div>
        {t('nptPageFound.04', 'File access and file upload service is not available now.')}
      </div>
      <div className={classes.notPageFoundButtons}>
        <Link to="/image-editor">
          <button className="btnBase">
            {marks.paintBrushMark} {t('nptPageFound.03', 'Go to image editor page')}
          </button>
        </Link>
      </div>
    </div>
  )
  

  if (gLoading) {
    notServiceAvailableBody = (<div></div>);
  }


  return (
    <Fragment>
      <div className="pageContentContainer">
        {notServiceAvailableBody}
      </div>
    </Fragment>
  );
};

export default NotApisAvailable;
