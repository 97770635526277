import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { SUBSCRIPTION_BACKEND_URL } from "./App";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';



import 'hacktimer'
import { RecoilRoot } from 'recoil'
import './components/LamaCleaner/styles/_index.scss';

import configureUiStore from './hook-store/ui-store';
import configureBucketObjectStore from './hook-store/bucket-object-store';
import configureAdminStore from './hook-store/adimin-store';
import configureStripeDataStore from "./hook-store/stripe-data-store";
import configureImageEditorStore from './hook-store/image-editor-store';
import configureImageFusionStore from './hook-store/image-fusion-store';
import configureImageResizerStore from './hook-store/image-resizer-store';
import configureLamaCleanerStore from './hook-store/lama-cleaner-store';
import configureImageConvertStore from './hook-store/image-convert-store';
import configureServiceBucketsStore from './hook-store/service-buckets-store';
import configureObjectMetadataStore from './hook-store/object-metadata-store';

configureUiStore();
configureBucketObjectStore();
configureAdminStore();
configureStripeDataStore();
configureImageEditorStore();
configureImageFusionStore();
configureImageConvertStore();
configureLamaCleanerStore();
configureServiceBucketsStore();
configureObjectMetadataStore();
configureImageResizerStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

//// load stripe publishableKey
fetch(SUBSCRIPTION_BACKEND_URL + '/price/config')
  .then(r => {
    return r.json()
  })
  .then(resData => {
    console.log('stripe-config resData', resData);
    const stripePromise = loadStripe(resData.publishableKey);

    // ReactDOM.render(
    //   <BrowserRouter>
    //     <Elements stripe={stripePromise}>
    //       <App />
    //       <div>div-div</div>
    //     </Elements>
    //   </BrowserRouter>,
    //   document.getElementById("root")
    // );

    // const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      // <React.StrictMode>
      <Elements stripe={stripePromise}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </Elements>
      // </React.StrictMode>
    );

  })
  .catch(err => {
    // console.log('stripe-config err', err)
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// console.log('navigator', window.location)
///// service worker deploy not working well... why??
///// Uncaught SyntaxError: Unexpected token '<' 
///// but app install using deferredprompt worked in newer android mobile device

// // Check that service workers are supported
// if ('serviceWorker' in navigator && !window.location.host.includes('localhost')) {
// // if ('serviceWorker' in navigator) {

// 	// eslint-disable-next-line no-console
// 	// console.log('serviceWorker is there');

// 	// Use the window load event to keep the page load performant
// 	window.addEventListener('load', () => {
// 		navigator.serviceWorker.register('/service-worker.js')
// 			.then((res) => {
// 				// eslint-disable-next-line no-console
// 				console.log(res);
// 			})
// 			.catch((err) => {
// 				// eslint-disable-next-line no-console
// 				console.log(err);
// 			});
// 	});
// }