import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import _, { result } from 'lodash';
// import axios from 'axios';

import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';
// import * as objectAccessUtils from '../../../utils/bucket/object-access-util';
import * as objectMetadataUtils from '../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../App';


function GetObjectMetadata(props) {

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  const { 
    bucketName,
    allBucketObjects,
   } = store.bucketObjectStore;
  const {
    allObjectsMetaList,
    dataForMetadata,
  } = store.objectMetadataStore;

  const { gLoading } = store.uiStore;


  useEffect(() => {
    if (allBucketObjects.length > 0 && 
      allObjectsMetaList.length === 0
    ) {
      getAllMetaHandler(bucketName, '');
    }
  },[allBucketObjects, gLoading]);


  useEffect(() => {
    console.log('dataForMetadata', dataForMetadata);

    if (dataForMetadata) {
      const addKeys = dataForMetadata.addKeys;
      if (addKeys && addKeys.length > 0) {
        addMetadataHandler(bucketName, addKeys);
      }
    }
  },[dataForMetadata]);



  const getAllMetaHandler = async (bucket, prefix) => {
    try {
      // setIsLoading(true);
      dispatch('SET_METALOADING', true);

      const resData = await objectMetadataUtils.getAllObjectsMetadata(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        prefix,
      );
      // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
      console.log(resData);

      // dispatch('SET_CURRENT_PREFIX', prefix);
      
      dispatch('SET_ALLOBJECTSMETALIST', resData.data);
      
      // setIsLoading(false);
      dispatch('SET_METALOADING', false);
    } catch(err) {
      console.log(err);
      // setIsLoading(false);
      dispatch('SET_METALOADING', false);
    }
  };


  // const getObjectMetaHandler = async (bucket, prefix) => {
  //   try {
  //     // setIsLoading(true);
  //     // dispatch('SET_GLOADING', true);

  //     const resData = await objectMetadataUtils.getObjectMetadata(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       prefix,
  //     );
  //     // const result = await fetch(BACKEND_URL + `/bucket/all-objects?bucket=${bucket}&prefix=${prefix}`);
  //     console.log(resData);

      
  //     // dispatch('SET_CURRENT_PREFIX', prefix);
      
  //     // dispatch('SET_ALLOBJECTSMETALIST', resData.data);
      
  //     // setIsLoading(false);
  //     // dispatch('SET_GLOADING', false);
  //   } catch(err) {
  //     console.log(err);
  //     // setIsLoading(false);
  //     // dispatch('SET_GLOADING', false);
  //   }
  // };

  const addMetadataHandler = async (bucket, addKeys) => {
    try {
      const addMetaList = [];

      for (const key of addKeys) {
        const resData = await objectMetadataUtils.getObjectMetadata(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucket,
          key,
        );
        
        if (resData && resData.data) {
          addMetaList.push(resData.data);
        }
      }

      const addedList = allObjectsMetaList.concat(addMetaList);
      dispatch('SET_ALLOBJECTSMETALIST', addedList);

    } catch(err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
    </Fragment>
  );
}

export default GetObjectMetadata;
