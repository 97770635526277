import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { 
  imageHeightState, 
  imageWidthState,
  fileState,
 } from '../../store/Atoms.tsx'
import { themeState } from '../Header/ThemeChanger.tsx'

import Backdrop from '../../../Backdrop/Backdrop.js'
import Modal from '../../../Modal/Modal';
import Loader from '../../../Loader/Loader.js'
import { useStore } from '../../../../hook-store/store.js'
import { changeImageDimensionSize } from '../../../../utils/image-util.js';

import './CleanerFileControl.css';

const CleanerFileControl = () => {
  const [t] = useTranslation('translation');

  const setFile = useSetRecoilState(fileState);
  const inputFile = useRecoilValue(fileState);
  const imageWidth = useRecoilValue(imageWidthState)
  const imageHeight = useRecoilValue(imageHeightState)
  const [theme, setTheme] = useRecoilState(themeState)

  const [store, dispatch] = useStore();
  // const { isAuth } = store.uiStore;
  const { 
    dimensionsLimit,
   } = store.lamaCleanerStore;

  const [isLoading, setIsLoading] = useState(false);

  //  console.log(imageWidth, imageHeight, dimensionsLimit, inputFile);

  const resizeFileHandler = async(file, maxWidth, maxHeight) => {
    try {
      setIsLoading(true);

      const resizedFile = await changeImageDimensionSize(
        file,
        'file',
        file.type.split('/')[1],
        maxWidth,
        maxHeight,
      );

      console.log(resizedFile);

      if (resizedFile) {
        setFile(resizedFile);
      }

      setIsLoading(false);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  }
  if (!imageWidth || !imageHeight) {
    return null
  }

  let cleanerFileControlBody;

  if (imageWidth && imageHeight) {
    cleanerFileControlBody = (
      <div 
      // className="imageSize"
      >
        <div className='cleanerFileControlSection'>
          {t('imageCleaner07', 'Image size (width x height)')} 
          <br/>
          {imageWidth} x {imageHeight} px
        </div>

        <div className='cleanerFileControlSection'>
          <span className='cleanerFileControlWarning'>
          {t('imageCleaner08', 'Image width or height shold be less than 800px')}
          </span>
        </div>

        <div className='cleanerFileControlSection'>
          <button className='btnBase'
            style={{padding: "1rem", fontSize: "1.25rem", fontWeight: "bold"}}
            disabled={isLoading}
            onClick={() => {
              resizeFileHandler(
                inputFile, 800, 800
              );
            }}
          >
            {t('imageCleaner09', 'Resize image file')}
          </button>
        </div>

        {isLoading && (
          <Loader />
        )}
        
        <div>{t('imageCleaner10', 'Or,')} </div>
        <div className='cleanerFileControlSection'>
          <span>
          {t('imageCleaner11', 'Image size can be resized from file actions in file list, or it is possible to resize')}
            {' '}
            in Image Resizer

          </span>
        </div>

        <div className='cleanerFileControlButtons'>
          <Link 
            to={`/image-resizer`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <button className='btnBase'>
              Image Resizer {t('imageCleaner12', 'page')}
            </button>
          </Link>
        {' '}
        <Link 
            to={`/`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <button className='btnBase'>
            {t('imageCleaner13', 'file list page')}
            </button>
        </Link>
        </div>
        {/* <div>
          <button>
            <Link 
              to={`/image-resizer`}
              style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
            >
              Image Resizer page
            </Link>
          </button>
        </div> */}
        
     
    </div>
    )
  }

  return (
    <Fragment>
      {(imageWidth > dimensionsLimit && imageHeight > dimensionsLimit) && (
        <div>
          <Backdrop 
            onCancel={() => { 
              //@ts-ignore
              // dispatch('SET_SHOW_LAMAUPLOADMODAL', false);
              // if (!isLoading) {
              //   // showUploadMoadlHandler(false);
              // }
            }}
          />
          <Modal
            onClose={() => { 
              //@ts-ignore
              // dispatch('SET_SHOW_LAMAUPLOADMODAL', false);
              // if (!isLoading) {
              //   showUploadMoadlHandler(false);
              // }
            }}
            modalStyle={theme === 'dark' 
              ? { background: "var(--color-gray-900)", border: "1px solid gray" } 
              : null
            }
          >
            <div>{cleanerFileControlBody}</div>
          </Modal>
        </div>
      )}
    </Fragment>
  )
}

export default CleanerFileControl
