import _ from 'lodash';

import { marks } from "../marks";

export const createFileMark = (object) => {
  const fileType = object.mimeType ? object.mimeType.split('/')[0] : null;
  
  let fileMark = marks.fileTextOMark;

  if (fileType === 'image') {
    fileMark = marks.fileImageOMark;
  }

  if (fileType === 'video') {
    fileMark = marks.fileVideoOMark;
  }

  if (fileType === 'audio') {
    fileMark = marks.fileAudioOMark;
  }

  if (fileType === 'text') {
    fileMark = marks.fileTextOMark;
  }

  if (object.mimeType === 'application/pdf') {
    fileMark = marks.filePdfOMark;
  }

  return fileMark;
};


export const createSortedTopLevels = (topLevels) => {
  const folderList = [];
  const fileList = [];

  topLevels.forEach(ele => {
    if (ele.Key.endsWith('/')) {
      folderList.push(ele);
    } else {
      fileList.push(ele);
    }
  });

  const sortedFileList = _.sortBy(fileList, [function(o) {
    const fileName = o.Key.split('/')[o.Key.split('/').length - 1];
    const timeEndIndex = fileName.indexOf('Z');
    // console.log(fileName, timeEndIndex);
    return fileName.slice(timeEndIndex+2).toLowerCase();
  }]);

  // console.log(folderList, fileList, sortedFileList);
  const sortedTopLevels = folderList.concat(sortedFileList);

  return sortedTopLevels;
};