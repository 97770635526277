// const React = window.React = require('react');
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from 'react-meta-tags';

const HeadMetaTag = (props) => {

  const [t] = useTranslation('translation');

  const title = `Kura Image Photo - ${t('headMeta01', 'Simple image and file storage with various image editing tools')}`
  
  const description = `Kura Image Photo by SpaceEight. 
${t('headMeta02', 'Simple image and file storage with image editing tools.')} 
${t('headMeta03', 'Edit and store your images everywhere from mobile devices and desktop devices. There is free storage space to upload files.')}
Kura Image Photo ${t('headMeta04', 'has varions image editing tools.')}
Simple Image Editor, ${t('headMeta11', 'for image editing')}
Image Cleaner, ${t('headMeta05', 'AI-assisted tool for image cleaning')}
Draw Dreamer, ${t('headMeta06', 'tool for image generation with AI')}
Text Prompt Image Generation, ${t('headMeta12', 'tool for generating image from text prompt input.')}
Image to Image Generation, ${t('headMeta13', 'tool for generating image based on input image and text prompt description.')}
Image Refiner, ${t('headMeta14', 'tool to generate higher resolution image from small or unclear image.')}
Image Fusion ${t('headMeta07', 'is an AI-assisted image generation tool')} (neural style transfer)
Image Combiner, ${t('headMeta08', 'tool for combining two images to generate a mixed image')}
Image Resizer, ${t('headMeta09', 'tool for resizing multiple images')}
Image Convert, ${t('headMeta10', 'tool for converting image file format')}
Background Remover, ${t('headMeta15', 'tool for removing background from image. AI-technologies enabled to delete background from image.')}
Illustration Generator, ${t('headMeta16', 'tool to generate illustration image from input image. By using AI-technologies, input picture image can be converted into illustarion image.')}
`;

  return (
    <Fragment>
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" /> */}
      </MetaTags>
    </Fragment>
  );
}

export default HeadMetaTag;