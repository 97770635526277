import { useState, useEffect, Fragment } from 'react';
// import axios from 'axios';
// import _ from 'lodash';

import Loader from '../../Loader/Loader';
import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import { isTrashboxObject } from '../../../utils/bucket/object-metadata-util';

// import { BACKEND_URL } from '../../../App';
import { marks } from '../../../utils/marks';

import classes from './FolderList.module.css';

function FolderList(props) {
  const { 
    getPrefixesHandler,
    selectedPrefixHandler,
    // isLoading,
  } = props;

  // console.log(marks);
  const checkMark = marks.checkMark;
  const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-FolderList.js', store);
  // const currentPrefix = store.bucketObjectStore.currentPrefix;
  // const bucketName = store.bucketObjectStore.bucketName;
  const { prefixList, selectedPrefix, } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;
  const { allObjectsMetaList } = store.objectMetadataStore;


  const [clickedPrefix, setClickedPrefix] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // const setCurrentPrefixHandler = (prefix) => {
  //   console.log(prefix); 
  //   // setCurrentPrefix(prefix);
  //   dispatch('SET_CURRENT_PREFIX', prefix);
  // };

  // const selectedPrefixHandler = (prefix) => {
  //   if (prefix === selectedPrefix) {
  //     // setSelectedPrefix('');
  //     dispatch('SET_SELECTED_PREFIX', '');
  //   } else {
  //     // setSelectedPrefix(prefix);
  //     dispatch('SET_SELECTED_PREFIX', prefix);
  //   }

  // };

  const hideSelectPrefixHandler = (prefix) => {
    console.log(prefix);

    // const isTopLevel = prefix.split('/').length === 2;

    const prefixes2 = prefixList;

    for (const px of prefixes2) {
      // console.log(px.Prefix, px.Prefix.split('/'), prefix.split('/'));
      if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length === prefix.split('/').length + 1) {
        // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
        px.hide = !px.hide;
      }
      if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length > prefix.split('/').length + 1) {
        // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
        px.hide = true;
      }
    }

    console.log(prefixes2);
    dispatch('SET_PREFIXLIST', prefixes2);

  };



  let foldersBody;

  if (prefixList.length > 0) {
    foldersBody = (
      <tbody>
        <tr>
          <td>
            <span 
              onClick={() => {
                if (!isLoading) {
                    // getPrefixesHandler(bucketName, prefix.Prefix);
                    selectedPrefixHandler('');
                }
              }}
            >
              {!selectedPrefix && checkMark}
              {selectedPrefix && noCheckMark}
            </span>
            {' '}
            <span className={classes.folderTableFolderName}>
              top
            </span>  
          </td>
        </tr>
        {prefixList.map((prefix, index) => {
        // console.log(prefix);
        const isTopLeve = prefix.Prefix.split('/').length === 2;
        const splitList = prefix.Prefix.split('/');
      
        const isInTrash = isTrashboxObject(prefix.Prefix, allObjectsMetaList);
          
        if (isInTrash) {
          return null;
        }
        
        const underExist = prefixList.find(px => {
          return  px.Prefix.startsWith(prefix.Prefix) && px.Prefix.split('/').length > prefix.Prefix.split('/').length;
        });

        const underOpen = prefixList.find(px2 => {
          return  px2.Prefix.startsWith(prefix.Prefix) && 
            px2.Prefix.split('/').length === prefix.Prefix.split('/').length + 1 && 
            !px2.hide;
        });
        // console.log('underOpen', prefix, underOpen);

        if (isTopLeve || !prefix.hide) {
        // if (isTopLeve || (!prefix.hide && prefix.open)) {
          return (
            <tr key={prefix.Prefix}>
              {/* <td>
                <span>..x..</span>
              </td> */}
              <td>
                <span style={{ paddingLeft: `${splitList.length*1 - 2}rem`, paddingRight: "0.5rem"}}>
                  <span
                    onClick={() => {
                      if (!isLoading) {
                        // getPrefixesHandler(bucketName, prefix.Prefix);
                        selectedPrefixHandler(prefix.Prefix);
                      }
                    }}
                  >
                      {selectedPrefix === prefix.Prefix && checkMark}
                      {selectedPrefix !== prefix.Prefix && noCheckMark}    
                  </span>
                  {' '}
                  <span className={classes.folderTableFolderName}
                    onClick={() => {
                      if (!isLoading) {
                        // getPrefixesHandler(bucketName, prefix.Prefix);
                        // selectedPrefixHandler(prefix.Prefix);
                        hideSelectPrefixHandler(prefix.Prefix);
                        setClickedPrefix(prefix.Prefix);
                      }
                    }}
                  >
                    {!underExist && (
                      <span>
                        {marks.folderFillMark} {splitList[splitList.length - 2]} 
                        {isLoading && clickedPrefix === prefix.Prefix && (
                          marks.spinner
                        )}
                      </span>
                    )}
                    {underExist && underOpen && (
                      <span>
                        {marks.folderFillOpenMark} {splitList[splitList.length - 2]}
                      </span>
                    )}
                    {underExist && !underOpen && (
                      <span>
                        {marks.folderFillMark} {splitList[splitList.length - 2]} {marks.triangleDown}
                      </span>
                    )}
                    
                  </span>
                  
                </span>
              </td>
            </tr>
          );
        }
      })}
      </tbody>
    );
  }


  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {/* {isLoading && !clickedPrefix && (
        <Loader />
      )} */}
      <table className={classes.folderTable}>
        <thead>
          <tr>
            {/* <th>f-h0</th> */}
            <th></th>
            {/* <th></th> */}
          </tr>
        </thead>
        {foldersBody}
      </table>
    </Fragment>
  );
}

export default FolderList;
