import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'react-cool-img';

import { useStore } from '../../../../hook-store/store';

import { marks } from '../../../../utils/marks';
import classes from './FullSizeImage.module.css';

function FullSizeImage(props) {
  const { 
    // nft, 
    object,
    setShowFullSize,
    showFullSize,
    isLoading,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    imageUrlList,
   } = store.bucketObjectStore;

  const [fitScreen, setFitScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setFitScreen(true);
    }
  },[]);

  let imgStyle = null;

  if (fitScreen) {
    imgStyle = {
      maxHeight: "80vh",
      maxWidth: "90vw",
    };
  }

  let imageUrl;

  const imageUrlObj = imageUrlList.find(element => {
    return element.key === object.Key;
  });

  if (imageUrlObj?.imageUrl) {
    imageUrl = imageUrlObj.imageUrl;
  }

  let fullSizeBody;

  if (object) {
    fullSizeBody = (
      <div>
        {/* <div>
          <button 
            onClick={() => {
              // document.getElementById('image')?.requestFullscreen();
              setShowFullSize(!showFullSize);
            }}
          >
            full-size
          </button>
        </div> */}

        {showFullSize && (
          <div className={classes.fullSizeContainer}>
            <div style={{
              // position:"fixed", top:"1rem", right:"1rem"
              display:"flex", justifyContent:"space-between", padding:"1rem",
            }}
            >
              <div 
                onClick={() => {
                  setFitScreen(!fitScreen);
                }}
              >
                {fitScreen && (
                  <span className={classes.fullSizeImageFit}>
                   {t('fileAction.10', 'full size')} {marks.southEastArrow}
                  </span>
                )}
                {!fitScreen && (
                  <span className={classes.fullSizeImageFit}>
                    {t('fileAction.09', 'fit screen')} {marks.northWestArrow}
                  </span>
                )}
              </div>
              <div className={classes.fullSizeImageClose}
                onClick={() => {
                  // document.getElementById('image')?.requestFullscreen();
                  setShowFullSize(false);
                }}
              >
                {marks.closeMark}
              </div>
            </div>
            <div className={classes.fullSizeImageContainer}>
              <Img  
                style={imgStyle}
                // src={object.imageUrl}
                src={imageUrl}
                alt="fullsize media"
              />
            </div>
          </div>
        )}
      </div>
    );
  }


  return (
    <Fragment>
      <div>
        {fullSizeBody}
      </div>
    </Fragment>
  );
}

export default FullSizeImage;
