// const React = window.React = require('react');
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Img from "react-cool-img";

import { useStore } from "../../hook-store/store";
import { loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import classes from "./AboutPage.module.css";

import DogeCImage from "../../images/combiner/dog2-combine.png";
import Doge1Image from "../../images/combiner/dog-1.png";
import Doge2Image from "../../images/combiner/dog2-1.png";

const AboutImageCombiner = (props) => {
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  let aboutImageCombinerBody;

  aboutImageCombinerBody = (
    <div>
      <div>
        <p>
          Image Combiner 
          {' '}
          {t('combinerInfo01', 'is a tool to combine two input images and generate a new mixed image.')}
        </p>
      </div>
      <br />

      <div>
        <div>
            <strong>
            {t('combinerInfo07', 'Example of Image combine')}
            </strong>
        </div>
        <br/>
        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ height: "225px" }} src={Doge1Image} />
            </div>
            <div>{t('combiner03', 'Image')} 1</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={Doge2Image} />
            </div>
            <div>{t('combiner03', 'Image')} 2</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={DogeCImage} />
            </div>
            <div>{t('combinerInfo08', 'Combined image')}</div>
          </span>
        </div>
      </div>
    </div>
  );

  return <Fragment>{aboutImageCombinerBody}</Fragment>;
};
export default AboutImageCombiner;
