import React from 'react';
import { Fragment, useEffect, useState } from "react";

// import download from 'downloadjs';

// import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
// import colorPicker from 'tui-color-picker'; 

// import ColorPick from './ColorPick';
import ChangeImage from './UploadEditFile/ChangeImage';
import FileControl from './FileControl';
import FileUrlLoad from './FileUrlLoad';
import UploadEditFile from './UploadEditFile/UploadEditFile';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';

import "./tui-image-editor.css";
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");

function ToastUiImageEditor() {

  const editorRef = React.createRef();

  const myTheme = {
    // Theme object to extends default dark theme.
    // "menu.backgroundColor": "white",
    // "common.backgroundColor": "#151515",
    // "downloadButton.backgroundColor": "white",
    // "downloadButton.borderColor": "white",
    // "downloadButton.color": "black",
    // "menu.normalIcon.path": icond,
    // "menu.activeIcon.path": iconb,
    // "menu.disabledIcon.path": icona,
    // "menu.hoverIcon.path": iconc,
  };

  const [store, dispatch] = useStore();
  const { windowValues } = store.uiStore;
  const { initialWidth, loadedImageName } = store.imageEditorStore;

  // const [loadedImageName, setLoadedImageName] = useState('');
  // const [undoStackLength, setUndoStackLength] = useState(0);
  // const [initialWidth, setInitialWidth] = useState(0);

  useEffect(() => {
    window.scrollTo(0,0);
    const downloadBtnEls = document.getElementsByClassName('tui-image-editor-download-btn');
    const headerBtnEls = document.getElementsByClassName('tui-image-editor-header-buttons');
    
    // console.log('header', headerBtnEls);
    // console.log(downloadBtnEls);
    if (downloadBtnEls[0]) {
      downloadBtnEls[0].style.display = 'none';
    }
    if (downloadBtnEls[1]) {
      downloadBtnEls[1].style.display = 'none';
    }

    if (headerBtnEls[0] && headerBtnEls[0].children && headerBtnEls[0].children[0]) {
      // console.log(headerBtnEls[0].children[0], headerBtnEls[0])
      
      // headerBtnEls[0].children[0].style.opacity = 0.2
      // headerBtnEls[0].children[0].style.display = 'none'
      // // headerBtnEls[0].children[0].children[0].style.width = "250px"
      // headerBtnEls[0].append(document.createElement("div"))
      
      // console.log(headerBtnEls[0].children)
      // const addedEl = headerBtnEls[0].children[2];
      // addedEl.style.background = 'red'
      // addedEl.textContent = 'test-button'
      // addedEl.id = 'added-el'


      // headerBtnEls[0].children[0].style.width = '250px'
    }

  }, []);

  useEffect(() => {
    // setInitialWidth(window.innerWidth);
    //// initialize store values
    dispatch('SET_INITIALWIDTH' , window.innerWidth);
    dispatch('SET_UNDOSTACKLENGTH', 0);
    dispatch('SET_LOADEDIMAGENAME', '');
  },[]);

  useEffect(() => {
    const editorInstance = editorRef.current.getInstance();
    
    editorInstance.on('redoStackChanged', function(length) {
      // console.log('redo', length);
      // console.log(editorInstance.getImageName());
      // const invoker = editorInstance._invoker;
      // const redoStack = editorInstance._invoker._redoStack;
      // console.log('invoker redoChange', invoker, editorInstance);
    });

    editorInstance.on('undoStackChanged', function(length) {
      // console.log('undo', length);

      dispatch('SET_UNDOSTACKLENGTH', length);
      dispatch('SET_LOADEDIMAGENAME', editorInstance.getImageName());
      
      // setUndoStackLength(length);
      // setLoadedImageName(editorInstance.getImageName());

      // const invoker = editorInstance._invoker;
      // const redoStack = editorInstance._invoker._redoStack;
      // console.log('invoker undoChange', invoker);
      // console.log(editorInstance, editorInstance._invoker._undoStack);
    });
  }, []);

  useEffect(() => {
    if (windowValues) {
      if (windowValues.width > 768) {
        const editorMainEl = document.getElementsByClassName('tui-image-editor-main');
        
        if (editorMainEl) {
          // console.log(editorMainEl);
          // resizeCanvasHandler(
          //   // windowValues.width - 450,
          //   editorMainEl[0].offsetWidth - 375, //// main-width - side-controls
          //   editorMainEl[0].offsetHeight - 50,
          // );
        } else {
          resizeCanvasHandler(
            windowValues.width - 450, 
            windowValues.height * 0.9,
          );
        }

      }

      if (windowValues.width <= 768) {
        // resizeCanvasHandler(
        //   windowValues.width * 0.75,
        //   700 - 300,                  //// uiHeight - (top button + bottom controls)
        // );
      }


      // const editorInstance = editorRef.current.getInstance();
      // editorInstance._invoker._undoStack = editorInstance._invoker._undoStack.filter(ele => {
      //   console.log(ele);
      //   return ele.name !== 'resizeCanvasDimension';
      // });
    }
  },[windowValues]);

  // useEffect(() => {
  //   //// color picker style change when screen is narrow
  //   if (initialWidth <= 768) {
  //     const colorPickerValueEls = document.getElementsByClassName('color-picker-value');
  //     // console.log(colorFixEls, colorPickerValueEls);
  //     if (colorPickerValueEls && colorPickerValueEls.length > 0) {
  //       colorPickerValueEls[0].addEventListener('click', (event) => {
  //         console.log('clicked');
  //         const colorFixEls = document.getElementsByClassName('tui-colorpicker-clearfix');
  //         const paletteButtonEls = document.getElementsByClassName('tui-colorpicker-palette-button');

  //         if (colorFixEls && colorFixEls.length >= 2) {
  //           if (colorFixEls[1]) {
  //             colorFixEls[1].style.display = 'none';
  //           }
  //           if (colorFixEls[0]) {
  //             colorFixEls[0].style.cssText = 'display: flex; flex-wrap: wrap';
  //           }
  //         }
      
  //         if (paletteButtonEls) {
  //           for (const button of paletteButtonEls) {
  //             button.style.margin = "10px";
  //           }
  //         }
  //       })
  //     }
  //   }
  // },[initialWidth]);


  const handleMousedown = () => {
    console.log('Mouse button is downed!');
  };


  async function resizeCanvasHandler(width, height) {
    try {
      const imageEditor = editorRef.current.getInstance();
      // console.log(imageEditor);
      const result = await imageEditor.resizeCanvasDimension({
        width: width,
        height: height,
      });

      console.log(result);

    } catch(err) {
      console.log(err);
    }
  }

  const handleFitScreen = () => {
    if (windowValues && windowValues.width > 768) {
      const editorMainEl = document.getElementsByClassName('tui-image-editor-main');
        
      if (editorMainEl) {
        console.log(editorMainEl);
        resizeCanvasHandler(
          // windowValues.width - 450,
          editorMainEl[0].offsetWidth - 375, //// main-width - side-controls
          editorMainEl[0].offsetHeight - 50,
        );
      }
    }
  };

 





  let menuBarPosition = 'bottom';
  // let menuList = ["crop", "flip", "rotate", "draw", "shape", "text", "icon", "mask", "filter"];
  let menuList = ["crop", "flip", "rotate", "draw", "shape", "text", "filter"];
  
  let cssMaxWidth = document.documentElement.clientWidth * 0.9
  // let uiSizeHeight = 500;
  // let cssMaxHeight = uiSizeHeight - 150;

  let uiSizeHeight = 700;
  let cssMaxHeight = uiSizeHeight - 350;

  // if (windowValues && windowValues.height > 700) {
  //   uiSizeHeight = windowValues.height;
  //   // cssMaxHeight = uiSizeHeight - 150;
  //   cssMaxHeight = uiSizeHeight - 350;

  //   if (windowValues && windowValues.width > 768 && windowValues.height > 700) {
  //     cssMaxHeight = uiSizeHeight - 450;
  //   }
  // }

  // if (windowValues && windowValues.width > 768 && windowValues.height > 700) {
  //   cssMaxHeight = uiSizeHeight - 450;
  // }

  if (window.innerWidth > 768) {
    cssMaxHeight = uiSizeHeight - 100;
  }


  const controlOneEls = document.getElementsByClassName('tui-image-editor-help-menu');
  // const downloadBtnEls = document.getElementsByClassName('tui-image-editor-download-btn');
  // const loadBtnEls = document.getElementsByClassName('tui-image-editor-load-btn');

  // console.log(loadBtnEls);
  // console.log(downloadBtnEls);
  // if (downloadBtnEls[0] && downloadBtnEls[1]) {
  //   downloadBtnEls[0].style.display = 'none';
  //   downloadBtnEls[1].style.display = 'none';
  // }

  // if (loadBtnEls[0] && loadBtnEls[1]) {
  //   // loadBtnEls[1].style.position = 'fixed';
  //   // loadBtnEls[1].style.opacity = '40';
  // }

  if (window.innerWidth <= 768) {
  // if (windowValues && windowValues.width <= 768) {
    menuList = ["crop", "flip", "rotate"];
  }

  if (window.innerWidth > 768) {
  // if (windowValues && windowValues.width > 768) {
    if (controlOneEls[0]) {
      // controlOneEls[0].style.display = 'block';
      const deleteAllEls = document.querySelectorAll('[tooltip-content="DeleteAll"]');
      const deleteEls = document.querySelectorAll('[tooltip-content="Delete"]');
      const resetEls = document.querySelectorAll('[tooltip-content="Reset"]');
      const helpRightEls = document.getElementsByClassName('tui-image-editor-help-menu');
      const separatorEls = document.getElementsByClassName('tui-image-editor-icpartition');
      
      // console.log('sep', separatorEls);

      if (helpRightEls[0]) {
        helpRightEls[0].style.height = '325px'
      }
      // deleteAllEls[0].style.display = 'none';
      // deleteEls[0].style.display = 'none';
      if (resetEls[0]) {
        resetEls[0].style.display = 'none';
        // resetEls[0].remove()
      }
      if (deleteAllEls[0]) {
        deleteAllEls[0].style.display = 'none';
        // deleteAllEls[0].remove()
      }
      if (deleteEls[0]) {
        deleteEls[0].style.display = 'none';
        // deleteEls[0].remove()
      }
      if (separatorEls[0] && separatorEls[1]) {
        separatorEls[1].style.display = 'none';
      }
    }
    menuBarPosition = 'left';
    // cssMaxWidth = 400;
    cssMaxWidth = document.documentElement.clientWidth - 450;
  }

  // console.log(document.querySelectorAll('[tooltip-content="DeleteAll"]'));

  return (
    <Fragment>
      {windowValues && (
        <div>
          <ImageEditor
            ref={editorRef}
            includeUI={{
              loadImage: {
                // path: "img/sampleImage.jpg",
                // name: "SampleImage",
              },
              theme: myTheme,
              // menu: ["shape", "filter"],
              // menu: ["crop", "flip", "rotate", "draw", "shape", "text", "filter"],
              menu: menuList,
              // menu: ["filter"],
              // initMenu: "filter",
              initMenu: "",
              uiSize: {
                // width: "1000px",
                // width: `${window.innerWidth}`,
                width: `${document.documentElement.clientWidth}`,
                // height: "700px",
                // height: `${document.documentElement.clientHeight}`,
                // height: "700px",
                height: `${uiSizeHeight}px`
              },
              menuBarPosition: menuBarPosition,
              // menuBarPosition: "bottom",
              // menuBarPosition: "left",
            }}
            // cssMaxHeight={500}
            // cssMaxWidth={700}
            // cssMaxHeight={400}
            cssMaxHeight={`${cssMaxHeight}`}
            // cssMaxWidth={document.documentElement.clientWidth * 0.8}
            cssMaxWidth={cssMaxWidth}
            selectionStyle={{
              cornerSize: 20,
              // cornerSize: 50,
              rotatingPointOffset: 70,
            }}
            usageStatistics={true}
            onMousedown={handleMousedown}
          />

          <FileControl 
            editorRef={editorRef}
          />

          {initialWidth > 768 && windowValues && windowValues.width > 768 &&
            loadedImageName && (
              <div style={{textAlign:"right"}}>
                <button className='btnBase'
                  
                  onClick={handleFitScreen}
                >
                  fit-image
                </button>
              </div>
          )}

          {/* <ChangeImage 
            editorRef={editorRef}
          /> */}
          
          <UploadEditFile 
            editorRef={editorRef}
          />

          {editorRef && (
            <FileUrlLoad 
            editorRef={editorRef}
            />
          )}
        
        </div>
      )}
    </Fragment>
  );
}

export default ToastUiImageEditor;
