import React, { useState, useEffect, Fragment } from 'react';
import _ from 'lodash';
// import { Link } from 'react-router-dom';
// import { useRedux } from "hooks/useRedux";

import { useStore } from '../../../../hook-store/store';
import { postGetObjectImageUrls } from '../../../../utils/bucket/bucket-object-util';
import { getParsedMetadataFromObjectMeta } from '../../../../utils/bucket/object-metadata-util';

import { BACKEND_URL } from '../../../../App';

// import { marks } from '../../../../utils/marks';
// import classes from './ImageSizeControl.module.css';

function GetImageUrls(props) {
  const { 
    // mobRowNum,
    // setMobRowNum,
    // imgMaxWidth,
    // setImgMaxWidth,
    // isLoading,
  } = props;



  const [store, dispatch] = useStore();
  const {
    imageUrlList,
    bucketName,
  } = store.bucketObjectStore;
  const { 
    allObjectsMetaList,
  } = store.objectMetadataStore;


  useEffect(() => {
    if (allObjectsMetaList.length > 0) {
      getImageUrlsHandler();
    }
  },[allObjectsMetaList]);


  const getImageUrlsHandler = async () => {
    try {
      dispatch('SET_GLOADING', true);

      const requestKeys = createUrlKeyList(
        imageUrlList, 
        allObjectsMetaList
      );
      // console.log('small-keys', requestKeys);

      if (requestKeys?.length > 0) {

        const resData = await postGetObjectImageUrls(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          requestKeys,
          '',
        );
  
        // console.log('small-image-urls', resData);
  
        if (resData?.data?.urls?.length > 0) {

          const addList = [];

          for (const element of resData.data.urls) {
            const isInList = imageUrlList.find(ele => {
              return ele.key === element.key;
            });

            if (!isInList) {
              addList.push(element);
            }
          }

          console.log('small-image-add-keys', addList);

          const addedList = imageUrlList.concat(addList);
          const uniqList = _.uniqBy(addedList, 'key');
          dispatch('SET_IMAGEURLLIST', uniqList);
        }
      }

      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);
      dispatch('SET_GLOADING', false);
      throw err;
    }
  }

  const createUrlKeyList = (
    imageUrlList, 
    allObjectsMetaList
  ) => {
    const keyList = [];

    for (const metadata of allObjectsMetaList) {
      // console.log(metadata)
      if (metadata.objectMeta) {
        const parsedMeta = getParsedMetadataFromObjectMeta(metadata.objectMeta);
  
        if (parsedMeta) {
          let isInList = false;
  
          if (imageUrlList.length > 0) {
            isInList = imageUrlList.find(element => {
              return element.key === metadata.Key;
            });
          }
  
          if (!isInList) {
            keyList.push({
              key: metadata.Key,
              smallImageKey: parsedMeta.smallImageKey,
            });
          }
        }
      }
    }

    // console.log('small-image-key-list', keyList);

    return keyList;
  };

  return (
    <Fragment></Fragment>
  );
}

export default GetImageUrls;
