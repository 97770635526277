import { useState, Fragment, useEffect } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';

import Backdrop from '../../Backdrop/Backdrop';
import { useStore } from '../../../hook-store/store';
import { marks } from '../../../utils/marks';

import * as uploadUtils from '../../../utils/bucket/bucket-upload-util';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import { BACKEND_URL } from '../../../App';

import classes from './FilesUpload.module.css';

function FilesUpload(props) {
  
  const { 
    setShowDropArea,
  } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store in FileUpload.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const topLevels = store.bucketObjectStore.topLevels;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const gLoading = store.uiStore.gLoading;

  const totalSizeGB = store.bucketObjectStore.totalSizeGB;
  const limitGB = store.bucketObjectStore.limitGB;
  const subscriptions = store.stripeData.subscriptions;

  // const [storePath, setStorePath] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  // const [showFolderUpload, setShowFolderUpload] = useState(false);
  // const [showDropArea, setShowDropArea] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (droppedFiles && droppedFiles.length > 0) {
      onClickHandler(currentPrefix, droppedFiles);
    }
  },[droppedFiles]);



  const onChangeHandler = (event) => {
    console.log(event.target.files);
    setSelectedFile(event.target.files[0]);
    setSelectedFiles(event.target.files);
  };

  const dropFilesHandler = (files) => {
    console.log(files);
    setDroppedFiles(files);
  };

  const selectFilesHandler = (files) => {
    console.log(files);
    setSelectedFiles(files);
  };

  const onClickHandler = async (path, files) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);


      let resultsForAction = {
        fileUpload: null,
      };

      console.log(files);

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 10) {
        throw new Error('Upload limit is 10 files at once');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;

      // result = await uploadUtils.s3fileUpload(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   path,
      //   files,
      //   bucketName,
      // );
      result = await uploadUtils.s3fileUpload2(
        BACKEND_URL,
        localStorage.getItem('token'),
        path,
        files,
        bucketName,
        dispatch,
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      const addedList = topLevels.concat(result.data.data);
      dispatch('SET_TOPLEVELS', addedList);

      setSelectedFiles([]);
      setDroppedFiles([]);
      setShowDropArea(false);


      dispatch('SET_OBJECTACTION', {
        actionType: 'file-upload',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      setSelectedFiles([]);
      setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      dispatch('SET_ISUPLOADFAILED', true);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }

  };




  // const s3fileUpload = (url, token, path, selectedFiles, bucketName) => {
  //   return new Promise((resolve, reject) => {
  //     const data = new FormData(); 
  
  //     // console.log(selectedFiles);
  //     for (const file of selectedFiles) {
  //       data.append('files', file);
  //     }
  //     // data.append('files', selectedFiles);
  
  //     data.append('storePath', path);
  //     data.append('bucket', bucketName);
      
  //     if (localStorage.getItem('customerId')) {
  //       data.append('customerId', localStorage.getItem('customerId'));
  //     }
      
  //     // console.log('data', data, data.getAll('files'));
  //     const headers = {
  //       // 'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + token,
  //     };
    
  //     // axios.post(url + "/bucket/object", data, { 
  //     // axios.post(url + "/bucket/objects", data, {  
  //     //   headers: headers
  //     //   // receive two    parameter endpoint url ,form data
  //     // })
  //     axios.request({
  //       method: 'post',
  //       url: url + "/bucket/objects",
  //       data: data,
  //       headers: headers,
  //       onUploadProgress: (p) => {
  //         // console.log('onUploadProgress', (p.loaded/p.total*100).toFixed(0), p); 

  //         let message = `Uploading... ${(p.loaded/p.total*100).toFixed(0)}%`;

  //         if (p.loaded/p.total === 1) {
  //           message = 'Uploaded, please wait until storing files finish...'
  //         }

  //         dispatch('SHOW_NOTIFICATION', {
  //           status: '',
  //           title: message,
  //           message: '',
  //         });
  //       }
  //     })
  //     .then(res => { // then print response status
  //       // console.log(res);
  //       resolve(res);
  //     }).catch(err => {
  //       console.log(err);
  //       reject(err);
  //     });
  //   })
  
  //   // axios.post("http://localhost:4000/upload", data, { 
  //   //   // receive two    parameter endpoint url ,form data
  //   // })
  //   // .then(res => { // then print response status
  //   //   console.log(res);
  //   //   // console.log(res.statusText)
  //   // }).catch(err => {
  //   //   console.log(err);
  //   // });
  
  
  // };

  let dropAreaContent;
  
  dropAreaContent = (
    <div className={classes.dropArea}>
      <div className={classes.dropAreaContent}>
        {/* <div>{marks.uploadMark} files-upload</div> */}
        <div>{t('objectControl.06', 'Drop Files Here')}</div>
        <br/>
        <div>{t('objectControl.07', 'Or')}</div>
        <br/>
        <div>
          <button className='btnBase'>
            {t('objectControl.08', 'Select file')}
          </button>
        </div>
        <br/>
        <div>
          ({t('objectControl.09', 'up to 10 files')})
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      
      {/* <div onClick={() => {
        if (!isLoading) { 
          setShowDropArea(true); 
        }}
        }
      >
        show-drop-area
      </div> */}

        <div>
          <Backdrop 
            zIndex={'190'}
            onCancel={() => { 
              if (!isLoading) { 
                setShowDropArea(false); 
              }
            }}
          />
          <div className={classes.dropAreaContainer}>
            <div className={classes.dropAreaHead}>
              <span></span>
              <span className={classes.dropAreaCloseButton}
                onClick={() => { 
                  if (!isLoading) { 
                    setShowDropArea(false); 
                  }
                }}
              >
                {marks.closeMark}
              </span>
            </div>
            <FileUploader 
              // handleChange={dropFilesHandler} 
              name="file"
              multiple={true}
              disabled={isLoading}
              children={dropAreaContent}
              onDrop={dropFilesHandler} 
              // onSelect={selectFilesHandler}
              onSelect={dropFilesHandler}
              // types={fileTypes} 
            />
          </div>
        </div>


      {/* <div
        onClick={() => {
          if (!isLoading) {
            setShowFolderUpload(!showFolderUpload);
          }
        }}
      >
        files-upload-content
      </div>
      {showFolderUpload && (
        <div>
          <input type="file" name="file" onChange={onChangeHandler} multiple/>
          <button type="button" 
            disabled={isLoading} 
            onClick={() => { 
              if (!isLoading) {
                onClickHandler(currentPrefix, selectedFiles); 
              } 
            }}
          >
            Upload-to-currentPrefix-folder
          </button> 

        </div>
      )}  */}
    </Fragment>
  );
}

export default FilesUpload;
