import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import numeral from 'numeral';

import { useStore } from '../../../../hook-store/store';
import { isImageMimeType } from '../../../../utils/bucket/bucket-object-util';

import {
  isForEditorFile,
  isForCleanerFile,
  isForDreamInpainterFile,
  isForBgRemoverFile,
  isForResizerFile,
  isForConvertFile,
  isForIllustGeneratorFile,
  isForImageToImageFile,
  isForImageRefinerFile,
} from '../../../../utils/bucket/image-file-edit-util';

 import { 
  drawDreamerLink,
  dreamInpaniterLink,
} from '../../../../App';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css'
// import * as bucketObjectUtils from '../utils/bucket-object-util';


function ImageEditActions(props) {

  const {
    object,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('FileActions.js-props', props, object.Key);

  const [store, dispatch] = useStore();
  const { gLoading } = store.uiStore;

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);


  let imageEditActionsBody;

  imageEditActionsBody = (
    <>
      {isImageMimeType(object.mimeType) && isForEditorFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="image-edit"
          // onClick={(event) => {}}
        >
          <Link to={`/image-editor?key=${object.Key}`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.paintBrushMark} 
              {' '}
              {t('fileAction.07', 'edit in Image Editor')}
            </span>
          </Link>
        </button>
      )}

      {isImageMimeType(object.mimeType) && isForDreamInpainterFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name=""
          // onClick={(event) => {}}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&imagePhotoUserId=${localStorage.getItem('userId')}&tokenForCasTGT=&TGTexp=`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.paintBrushMark} 
              {' '}
              {t('fileAction.11', 'edit')} 
              {' '}
              in Dream Inpainter 
              {' '}
              {marks.externalLinkMark}
            </span>
          </a>
        </button>
      )}

      {isImageMimeType(object.mimeType) && isForImageToImageFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name=""
          // onClick={(event) => {}}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${drawDreamerLink}/?key=${object.Key}&mimeType=${object.mimeType}&imagePhotoUserId=${localStorage.getItem('userId')}&openApp=imageToImageGeneration`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.paintBrushMark} 
              {' '}
              {t('fileAction.11', 'edit')} 
              {' '}
              in Image to Image Generation 
              {' '}
              {marks.externalLinkMark}
            </span>
          </a>
        </button>
      )}

      {isImageMimeType(object.mimeType) && isForImageRefinerFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name=""
          // onClick={(event) => {}}
        >
          <a 
            // href={`${dreamInpaniterLink}/?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            href={`${drawDreamerLink}/?key=${object.Key}&mimeType=${object.mimeType}&imagePhotoUserId=${localStorage.getItem('userId')}&openApp=imageRefiner`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.paintBrushMark} 
              {' '}
              {t('fileAction.11', 'edit')} 
              {' '}
              in Image Refiner
              {' '}
              {marks.externalLinkMark}
            </span>
          </a>
        </button>
      )}

      {/* {isImageMimeType(object.mimeType) && isForIllustGeneratorFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name=""
          // onClick={(event) => {}}
        >
          <a
            href={`${dreamInpaniterLink}/illustration-generator?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.paintBrushMark} 
              {' '}
              edit-in-illustration-generator 
              {' '}
              {marks.externalLinkMark}
            </span>
          </a>
        </button>
      )} */}

      {/* {isImageMimeType(object.mimeType) && isForBgRemoverFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name=""
          // onClick={(event) => {}}
        >
          <a
            href={`${dreamInpaniterLink}/background-remover?key=${object.Key}&mimeType=${object.mimeType}&tokenForCasTGT=${localStorage.getItem('tokenForCasTGT')}&TGTexp=${localStorage.getItem('TGTexp')}`}
            target="_blank" rel="noreferrer noopener"
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.eraserMark} 
              {' '}
              edit-in-background-remover
              {' '}
              {marks.externalLinkMark}
            </span>
          </a>
        </button>
      )} */}

      {isImageMimeType(object.mimeType) && isForCleanerFile(object.mimeType) &&(
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="image-clean"
          // onClick={() => {}}
        >
          <Link to={`/image-cleaner?key=${object.Key}`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.eraserMark} 
              {' '}
              {t('fileAction.11', 'edit')}
              {' '}
              in Image Cleaner
            </span>
          </Link>
        </button>
      )}

      {isImageMimeType(object.mimeType) && isForResizerFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="image-resize"
          // onClick={() => {}}
        >
          <Link 
            // to={`/image-resizer?key=${object.Key}`}
            to={`/image-resizer?keys=${JSON.stringify([object.Key])}`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.compressMark} 
              {' '}
              {t('fileAction.12', 'resize')}
              {' '}
              in Image Resizer
            </span>
          </Link>
        </button>
      )}

      {isImageMimeType(object.mimeType) && isForConvertFile(object.mimeType) && (
        <button className={classes.objectActionListItem}
          disabled={isLoading}
          name="image-convert"
          // onClick={() => {}}
        >
          <Link 
            to={`/image-convert?keys=${JSON.stringify([object.Key])}`}
            style={{fontWeight: 'normal', color:"inherit", textDecoration:"none"}}
          >
            <span className={classes.fileActionEditImageName}>
              {marks.exchangeMark} 
              {' '}
              {t('fileAction.13', 'convert image format')}
            </span>
          </Link>
        </button>
      )}
    </>
  )


  return (
    <Fragment>
      {imageEditActionsBody}
    </Fragment>
  );
}

export default ImageEditActions;
