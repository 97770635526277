import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_LAMASERVEREXIST: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          lamaServerExist: payload, 
        }
      };
    },
    SET_SHOW_LAMAUPLOADMODAL: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          showLamaUploadModal: payload, 
        }
      };
    },
    SET_STORERENDERS: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          storeRenders: payload, 
        }
      };
    },
    SET_LAMAPROCESSING: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          lamaProcessing: payload, 
        }
      };
    },
    SET_DIMENSIONSLIMIT: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          dimensionsLimit: payload, 
        }
      };
    },
    SET_SHOWLAMAINFOMODAL: (state, payload) => {
      return { 
        lamaCleanerStore : {
          ...state.lamaCleanerStore,
          showLamaInfoModal: payload, 
        }
      };
    },

  };

  initStore(actions, {
    lamaCleanerStore: {
      lamaServerExist: false,
      showLamaUploadModal: false,
      storeRenders: [],
      lamaProcessing: false,
      dimensionsLimint: 800,
      showLamaInfoModal: false,
    }

  });
};

export default configureStore;
