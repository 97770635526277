import React from 'react';
import { Fragment, useEffect, useState } from "react";

// import download from 'downloadjs';

import { useStore } from '../../hook-store/store';

import "./tui-image-editor.css";
import "./FileControl.css";

function FileControl(props) {
  const { 
    editorRef,
    // undoStackLength,
    // initialWidth,
  } = props;


  const [store, dispatch] = useStore();
  const { initialWidth, undoStackLength } = store.imageEditorStore;


  const [imageData, setImageData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');



  const handleClickButton = () => {
    const editorInstance = editorRef.current.getInstance();

    editorInstance.flipX();
  };

  const handleClickButton2 = () => {
    editorRef.current.getRootElement().classList.add('image-editor-root');
  };


  const handleToDataURL = () => {
    console.log('handleToDataURL');
    const editorInstance = editorRef.current.getInstance();
    console.log(editorInstance);
    const toDataUrl = editorInstance.toDataURL(
      // {
      //   format: 'jpeg',
      // }
    );

    // console.log(toDataUrl);

    if (toDataUrl) {
      setImageData(toDataUrl);
    }
    // editorInstance.loadImageFromURL(editorInstance.toDataURL(), 'FilterImage').then(() => {
    
    //     // editorInstance.addImageObject(imgUrl);
    // });
  };


  const selectFileHandler = (event) => {
    console.log(event.target.files);

    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    handleLoadImageFromFile(event.target.files[0], event.target.files[0].name);
  };

  const handleLoadImageFromFile = (file, name) => {
    //// not work well load does not show up in history

    const imageEditor = editorRef.current.getInstance();
    // console.log(editorInstance);
    imageEditor.loadImageFromFile(file, name).then(result => {
      console.log(result);
      console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
      console.log('new : ' + result.newWidth + ', ' + result.newHeight);
      
      // imageEditor.clearUndoStack();

      imageEditor.ui.activeMenuEvent();
    });
  };


  const imageUrlInputChangeHandler = (event) => {
    //// extract path from url
    const inputUrl = event.target.value;

    if (inputUrl) {
      const url = new URL(inputUrl);
      console.log(url);
  
      const filePath = url.pathname;
      const fileName = filePath.split('/')[filePath.split('/').length -1];
      console.log(filePath, fileName);
  
      handleLoadImageFromURL(inputUrl, filePath);
  
      setFilePath(filePath);
      setFileName(fileName);
    }
  };

  const handleLoadImageFromURL = (url, name) => {
    const imageEditor = editorRef.current.getInstance();
    // console.log(editorInstance);

    imageEditor.loadImageFromURL(url, name).then(result => {
      console.log(result);
      console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
      console.log('new : ' + result.newWidth + ', ' + result.newHeight);
      imageEditor.ui.activeMenuEvent();
    });
  };



  // const createFileHandler = (fileName) => {
  //   const imageEditor = editorRef.current.getInstance();
  //   const data = imageEditor.toDataURL();

  //   const createdFile = dataURLtoFile(data, fileName);
  //   console.log(createdFile);
  // }

  // function dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  //       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  //   while(n--){
  //       u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, {type:mime});
  // }

  function undoHandler() {
    const imageEditor = editorRef.current.getInstance();
    
    const undoStack = imageEditor._invoker._undoStack;

    if (undoStack.length >= 1) {
      // imageEditor.undo();
      imageEditor.undo(undoStack.length);
    }
  }

  function redoHandler() {
    const imageEditor = editorRef.current.getInstance();

    const redoStack = imageEditor._invoker._redoStack;

    if (redoStack.length > 0) {
      // imageEditor.redo();
      imageEditor.redo(redoStack.length);
    }

  }



  async function resizeHandler() {
    try {
      const imageEditor = editorRef.current.getInstance();
      console.log(imageEditor);
      const result = await imageEditor.resizeCanvasDimension({
        width: 100,
        height: 100,
      });

      console.log(result);

    } catch(err) {
      console.log(err);
    }
  }

  // function resetEditHandler() {
  //   const imageEditor = editorRef.current.getInstance();
    
  //   const undoStack = imageEditor._invoker._undoStack;
  //   if (undoStack.length >= 2) {
  //     imageEditor.undo(undoStack.length -1);
  //   }
  // }

  // function deleteHandler() {
  //   const imageEditor = editorRef.current.getInstance();
    
  //   // imageEditor.destroy();
  //   imageEditor._initHistory();    

  //   // const undoStack = imageEditor._invoker._undoStack;
  //   // if (undoStack.length >= 1) {
  //   //   imageEditor.undo(undoStack.length);
  //   // }
  // }

  // function getInstance() {
  //   const imageEditor = editorRef.current.getInstance();
  //   console.log(imageEditor);
  //   console.log(imageEditor._invoker._undoStack);
  // }


  // return (<Fragment></Fragment>);

  return (
    <Fragment>

      {/* <div>file-control</div> */}

      {/* {window.innerWidth <= 768 && ( */}
      {initialWidth <= 768 && (
        <div className="upload-container">
          {/* <span className="select-wrapper">
            <input 
              title="select file"
              type="file" 
              name="image_src" 
              id="image_src" 
              onChange={selectFileHandler}
              accept="image/*"
            />
          </span> */}

          <span className="select-wrapper2"
            title="undo"
            onClick={undoHandler}
          >
          </span>
          <span className="select-wrapper3"
            title="redo"
            onClick={redoHandler}
          >
          </span>
          
          {/* <span className="select-wrapper4"
            title="download"
            onClick={downloadImageHandler}
          >
          </span> */}
          {/* <span className="select-wrapper3"
            title="delete"
            onClick={deleteHandler}
          >
          </span> */}
        </div>
      )}
          {/* <div className="fileControlDownloadButton">
            <button className="btnBase"
              title="download"
              disabled={undoStackLength === 0}
              onClick={downloadImageHandler}
            >
              Download
            </button>
          </div> */}

      {/* <div>hook-store, utils, get-window-size, marks</div>
      <div>down-load, upload-to-bucket, share, link-to-images</div>
      <button onClick={getInstance}>getInstance</button>
      <button onClick={handleToDataURL}>to-DataURL</button> */}
      {/* <button className='button' onClick={saveImageToDisk}>Save Image to Disk</button> */}
      
      {/* <input type='file' onChange={selectFileHandler} /> */}
      
      {/* <label>image-url-input</label>
      <input type='text' onChange={imageUrlInputChangeHandler} /> */}
      
      {/* {imageData && (
        <div>
          <img style={{height: "50px"}} 
            src={imageData}
          />
        </div>
      )} */}

      {/* <button 
      onClick={downloadImageHandler}
      >
        down-load-button
      </button> */}
      {/* <button 
      onClick={() => { createFileHandler(fileName) }}
      >
        create-file-button
      </button> */}
      {/* <button 
        onClick={() => { saveImageToDisk(filePath); }}
      >
        save-image-button
      </button> */}

      {/* <button onClick={resizeHandler}>resize-canvas</button> */}
    </Fragment>
  );
}

export default FileControl;
