import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Backdrop from '../../Backdrop/Backdrop';
import DownLoadFile from './DownloadFile';
import Folder from '../../BucketComponents/Folder/Folder';
import Modal from '../../Modal/Modal';
import Loader from '../../Loader/Loader';
import UploadEditFileModalContent from './UplaodEditFileModalContent';

import ShareEditImage from '../../ShareEditImage/ShareEditImage';

import { useStore } from '../../../hook-store/store';

import * as uploadUtils from '../../../utils/bucket/bucket-upload-util';

import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../App';

import { marks } from '../../../utils/marks';
import './UploadEditFile.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

function UploadEditFile(props) {
  const { 
    editorRef,
    // loadedImageName,
    // undoStackLength,
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    selectedPrefix,
  } = store.bucketObjectStore;
  const { isAuth, isAllApis } = store.uiStore;
  const { loadedImageName, undoStackLength } = store.imageEditorStore;


  const [imageData, setImageData] = useState();
  const [selectedFile, setSelectedFile] = useState();
  
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [outputFormat, setOutputFormat] = useState('png');
  const [editFile, setEditFile] = useState();

  const [isLoading, setIsLoading] = useState();



  useEffect(() => {
    if (loadedImageName) {
      const nList = loadedImageName.split('.');
      nList.pop();
      const noExtName = nList.join('.');
      setFileName(noExtName);


      const ext = loadedImageName.split('.').pop().toLowerCase();
      // console.log(ext);
      if (ext === 'jpg' || ext === 'jpeg') {
        setOutputFormat(ext);
      } else {
        setOutputFormat('png');
      }
    }
  },[loadedImageName]);


  const setFileNameHandler = (event) => {
    // setFileName(event.target.value + '.' + outputFormat);
    setFileName(event.target.value);
  };

  const showUploadMoadlHandler = (value) => {
    setShowUploadModal(value);

    if (value) {
      setEditFile(createFileHandler(fileName, outputFormat));
    } else {
      setEditFile(null);
    }
  }

  const handleToDataURL = () => {
    console.log('handleToDataURL');
    const editorInstance = editorRef.current.getInstance();
    console.log(editorInstance);
    const toDataUrl = editorInstance.toDataURL(
      // {
      //   format: 'jpeg',
      // }
    );

    // console.log(toDataUrl);

    if (toDataUrl) {
      setImageData(toDataUrl);
    }
    // editorInstance.loadImageFromURL(editorInstance.toDataURL(), 'FilterImage').then(() => {
    
    //     // editorInstance.addImageObject(imgUrl);
    // });
  };


  const selectFileHandler = (event) => {
    console.log(event.target.files);

    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);

    handleLoadImageFromFile(event.target.files[0], event.target.files[0].name);
  };

  const handleLoadImageFromFile = (file, name) => {
    const imageEditor = editorRef.current.getInstance();
    // console.log(editorInstance);
    imageEditor.loadImageFromFile(file, name).then(result => {
      console.log(result);
      console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
      console.log('new : ' + result.newWidth + ', ' + result.newHeight);
      imageEditor.ui.activeMenuEvent();
    });
  };


  const imageUrlInputChangeHandler = (event) => {
    //// extract path from url
    const inputUrl = event.target.value;

    if (inputUrl) {
      const url = new URL(inputUrl);
      console.log(url);
  
      const filePath = url.pathname;
      const fileName = filePath.split('/')[filePath.split('/').length -1];
      console.log(filePath, fileName);
  
      handleLoadImageFromURL(inputUrl, filePath);
  
      setFilePath(filePath);
      setFileName(fileName);
    }
  };

  const handleLoadImageFromURL = (url, name) => {
    const imageEditor = editorRef.current.getInstance();
    // console.log(editorInstance);

    imageEditor.loadImageFromURL(url, name).then(result => {
      console.log(result);
      console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
      console.log('new : ' + result.newWidth + ', ' + result.newHeight);
      imageEditor.ui.activeMenuEvent();
    });
  };


  const saveImageToDisk = async (fileName) => {
    try {

      let filePath = fileName;

      if (selectedPrefix) {
        filePath = selectedPrefix + fileName;
      }
      //// upload data to backend to store
      const file = createFileHandler(filePath, outputFormat);
      console.log(file);

      
      await editImageUploadHandler(selectedPrefix, [file]);

    } catch(err) {
      console.log(err);
      throw err;
    }

  };

  const createFileHandler = (fileName, outputFormat) => {
    const imageEditor = editorRef.current.getInstance();
    
    // const imageName = imageEditor._graphics.imageName;
    // const ext = imageName.split('.').pop().toLowerCase();
    // console.log(imageName, ext);

    let data;

    if (outputFormat === 'jpeg' || outputFormat === 'jpg') {
      data = imageEditor.toDataURL({ format: 'jpeg' });
    } else {
      data = imageEditor.toDataURL();
    }

    const createdFile = dataURLtoFile(data, fileName + '.' + outputFormat);
    return createdFile;
    // console.log(createdFile);
  }


  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  // function undoHandler() {
  //   const imageEditor = editorRef.current.getInstance();
    
  //   const undoStack = imageEditor._invoker._undoStack;

  //   if (undoStack.length >= 1) {
  //     imageEditor.undo();
  //   }
  // }

  // function resetEditHandler() {
  //   const imageEditor = editorRef.current.getInstance();
    
  //   const undoStack = imageEditor._invoker._undoStack;
  //   if (undoStack.length >= 2) {
  //     imageEditor.undo(undoStack.length -1);
  //   }
  // }

  // function deleteHandler() {
  //   const imageEditor = editorRef.current.getInstance();
    
  //   const undoStack = imageEditor._invoker._undoStack;
  //   if (undoStack.length >= 1) {
  //     imageEditor.undo(undoStack.length);
  //   }
  // }

  // function getInstance() {
  //   const imageEditor = editorRef.current.getInstance();
  //   console.log(imageEditor);
  //   console.log(imageEditor._invoker._undoStack);
  // }

  const editImageUploadHandler = async (path, files) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileUpload: null,
      };

      console.log(files);

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 1) {
        throw new Error('Upload limit is 1 file');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;

      // result = await uploadUtils.s3fileUpload(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   path,
      //   files,
      //   bucketName,
      // );
      result = await uploadUtils.s3fileUpload2(
        BACKEND_URL,
        localStorage.getItem('token'),
        path,
        files,
        bucketName,
        dispatch,
        'imageEditor'
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      const addedList = topLevels.concat(result.data.data);
      dispatch('SET_TOPLEVELS', addedList);

      // setSelectedFiles([]);
      // setDroppedFiles([]);
      // setShowDropArea(false);


      dispatch('SET_OBJECTACTION', {
        actionType: 'file-upload',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      showUploadMoadlHandler(false);
      
      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      // setSelectedFiles([]);
      // setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      dispatch('SET_ISUPLOADFAILED', true);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      
      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }

  };


  let uploadBody = (
    <div>
      <div className='uploadEditAuthTitle'>
        {t('imageEditor.04', 'Login to upload and store image, or create account to store image (signup)')}
      </div>
      <div className="uploadEditAuthButtons">
        <a href={loginPageLink}>
          <button className="btnBase">
            {marks.signInMrak} {t('imageEditor.05', 'Login')}
          </button>
        </a>
        
        <div> {t('imageEditor.06', 'or')} </div>
 
        <a href={loginPageLink}>
          <button className="btnBase">
            {marks.userPlusMrak} {t('imageEditor.07', 'Signup')}
          </button>
        </a>
      </div>

     
    </div>
  );

  if (isAuth && isAllApis) {
    uploadBody = (
      <div>
        <div>
          <button
            className="btnBase"
            disabled={isLoading || !loadedImageName}
            onClick={() => {
              if (!isLoading) {
                showUploadMoadlHandler(true);
                // console.log(createFileHandler(fileName, outputFormat))
              }
            }}
          >
            {t('imageEditor.01', 'Store image file')}
          </button>
        </div>

        {showUploadModal && (
          <div>
            <Backdrop 
              onCancel={() => { 
                if (!isLoading) {
                  showUploadMoadlHandler(false);
                }
              }}
            />
            <Modal
              onClose={() => { 
                if (!isLoading) {
                  showUploadMoadlHandler(false);
                }
              }}
            >
              <UploadEditFileModalContent 
                // loadedImageName={loadedImageName}
                fileName={fileName}
                setFileNameHandler={setFileNameHandler}
                outputFormat={outputFormat}
                editFile={editFile}
                saveImageToDisk={saveImageToDisk}
                isLoading={isLoading}
              />
            </Modal>
          </div>
        )}
      </div>
    );
  }


  return (
    <Fragment>
      <div className='uploadEditFile'>
        <DownLoadFile 
          editorRef={editorRef}
          // undoStackLength={undoStackLength}
        />

        <ShareEditImage
          // editorRef={editorRef}
          createShareFile={createFileHandler}
          // undoStackLength={undoStackLength}
        />

        <div>
          {uploadBody}
        </div>
      </div>
    </Fragment>
  );
}

export default UploadEditFile;
