// const React = window.React = require('react');
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Img from 'react-cool-img';

import AboutDrawDreamer from './AboutDrawDreamer';
import AboutImageCombiner from './AboutImageCombiner';
import AboutImageFusion from './AboutImageFusion';
import AboutDreamInpainter from './AboutDreamInpainter';

import { useStore } from '../../hook-store/store';
import { 
  loginPageLink, 
  signupPageLink, 
  WATAKURAURL,
  drawDreamerLink,
  dreamInpaniterLink,
 } from '../../App';

import { marks } from '../../utils/marks';
import classes from './AboutPage.module.css'

import adImage1 from '../../images/kura-image-photo-ad-image1.png';
import adImage2 from '../../images/kura-image-photo-ad-image2.jpg';
import cleanupImage from '../../images/image-cleanup.jpg';
import cleanupMaskImage from '../../images/image-cleanup-mask.png';
import nocleanupImage from '../../images/image-no-cleanup.jpg';
import ImageEditorBeforeImage from '../../images/imageEditor/image-editor-before.jpeg';
import ImageEditorSampleImage from '../../images/imageEditor/image-editor-sample.jpeg';


const AboutPage = props =>  {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);


  let aboutPageBody;

  aboutPageBody = (
    <div className={classes.aboutPage}>
      <div className={classes.aboutPageTitle}>
        Kura Image Photo
      </div>
      <p>
        Kura Image Photo 
        {' '}
        {t('aboutPage.26', 'provides simple files and images storage space. It has various image editing tools and image generation tools.')}
      </p>
      {/* <p>

      </p> */}
      <br/>
      <p>
        {t('aboutPage.04', 'There is free space to upload files. Edited Images by using image editor can be stored in the free file storage space, after login.')}
      </p>
      <p>
        {t('aboutPage.05', 'When you need more space to store files, you can upgrade to larger file storage space anytime.')}
      </p>
      <br/>

      <div className={classes.aboutPageImageContainer}>
        <Img className={classes.aboutPageImage}
          src={adImage1}
        />
        <Img className={classes.aboutPageImage}
          src={adImage2}
        />
      </div>
      <br/>

      <p>
      {t('aboutPage.27', 'Your Images can be shared')}
        {' '}
        <a href={WATAKURAURL} 
          target="_blank"
          rel="noreferrer noopener">
            at watakura
        </a>. 
        <br/>
        {t('aboutPage.28', 'NFTs can be created with your images for trading')} 
        {' '}
        <a href={'https://stellar-nftplace.spaceeight.work'} 
          target="_blank"
          rel="noreferrer noopener">
            at Stellar NFT Place
        </a>.
      </p>
      <br/>

      <p><strong>Image Editor</strong></p>
      <p>
      {t('aboutPage.02', 'Image Editor is simple and easy to use for editing image files.')}
        <br/>
        {t('aboutPage.03', 'It is possible to edit your image files from both mobile devices and desktop devices.')}
        <br/>
        {t('aboutPage.06', 'Image Editor for smaller screen has essential tools such as cropping, flipping, and rotating.')}
        <br/>
        {t('aboutPage.07', 'In addition to the essential tools, image Editor for larger screen has various functionalities, including drawing tools on image, and many image filters.')}
      </p>

      <div className={classes.aboutPageImageContainer}>
        <img 
          style={{ maxHeight: "150px", maxWidth: "100%"}}
          src={ImageEditorBeforeImage}
        />
        <img 
          style={{ maxHeight: "150px", maxWidth: "100%"}}
          src={ImageEditorSampleImage}
        />
      </div>
      <br/>

      <p><strong>Image Cleaner</strong></p>
      <p>
        Image Cleaner 
        {' '}
        {t('aboutPage.17', 'is an AI-assisted image cleaning tool. It is possible to remove unwanted objects, defects, people from your image.')}
        <br/>
        {t('aboutPage.18', 'Advanced AI technologies enable to finish image cleaning process in less than 10 seconds usually.')}
      </p>
      <br/>

      <p>
        <strong>
          {t('aboutPage.19', 'Example of image cleaning')}
        </strong>
      </p>

      <div className={classes.aboutPageImageContainer}>
        <img className={classes.aboutPageCleanupImage}
          src={nocleanupImage} alt="before clenup"
        />
        <img className={classes.aboutPageCleanupImage}
          src={cleanupMaskImage} alt="cleanup mask"
        />
        <img className={classes.aboutPageCleanupImage}
          src={cleanupImage} alt="after clenup"
        />
      </div>
      <br/>
      <br/>

      <p><strong>Dream Inpainter</strong></p>
      <AboutDreamInpainter />
      <br/>
      <br/>

      <p><strong>Draw Dreamer</strong></p>
      <AboutDrawDreamer />
      <br/>
      <br/>

      <p><strong>Image Resizer</strong></p>
      <p>
        Image Resizer
        {' '} 
        {t('aboutPage.24', 'is a tool to resize multiple image files.')}
      </p>
      <br/>

      <p><strong>Image Fusion</strong></p>
      <AboutImageFusion />
      <br/>
      <br/>

      <p><strong>Image Combiner</strong></p>
      <AboutImageCombiner />
      <br/>
      <br/>

      <p><strong>Image Convert</strong></p>
      <p>
        Image Convert
        {' '} 
        {t('aboutPage.25', 'is a tool for converting image file format. Image quality can be changed by setting target image quality.')}
      </p>
      <br/>
      <br/>

      <div className={classes.aboutPageButtons}>
        <Link to="/image-editor">
          <button className="btnBase">
          {marks.paintBrushMark} {t('aboutPage.08', 'Go to image editor page')}
          </button>
        </Link>
        <Link to="/image-cleaner">
          <button className="btnBase">
          {marks.eraserMark} Go to image cleaner page
          </button>
        </Link>
        <a 
          href={dreamInpaniterLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="btnBase">
          {marks.paintBrushMark} Go to Kura Dream Inpainter
          </button>
        </a>
        <a href={drawDreamerLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="btnBase">
          {marks.pictureOMark} Go to Kura Draw Dreamer
          </button>
        </a>
        <Link to="/image-resizer">
          <button className="btnBase">
          {marks.compressMark} Go to image resizer page
          </button>
        </Link>
        <Link to="/image-fusion">
          <button className="btnBase">
          {marks.objectGroupMark} Go to image fusion page
          </button>
        </Link>
        <Link to="/image-combiner">
          <button className="btnBase">
          {marks.objectGroupMark} Go to image combiner page
          </button>
        </Link>
        <Link to="/image-convert">
          <button className="btnBase">
          {marks.exchangeMark} Go to image convert page
          </button>
        </Link>
      </div>

      <br/>
      
      {!isAuth && (
        <div>
          <p>
            {t('aboutPage.09', 'Login to upload and store files, or create account')}
          </p>

          <div className={classes.aboutPageButtons}>
            <a href={loginPageLink}>
              <button className="btnBase">
                {marks.signInMrak} {t('aboutPage.10', 'Login')}
              </button>
            </a>
            <span> {t('aboutPage.11', 'or')} </span>
            <a href={signupPageLink}>
              <button className="btnBase">
                {marks.userPlusMrak} {t('aboutPage.12', 'Signup')}
              </button>
            </a>
          </div>
        </div>
      )}

      <p>
        {t('aboutPage.13', 'If you want to upload various types of files, It is possible to use')}
        {' '}
        Kura Storage.
        <br/>
        Kura Storage
        {' '}
        {t('aboutPage.14', 'is simple storage place for your files.')}

        <br/>
        {t('aboutPage.29', 'It works as collaboration tool, because of synchronized file editing with other users.')}
        {' '}
        {t('aboutPage.30', 'User can create document, spreadsheet, and slide presentation files, and work on these files with other users.')}
        <br/>
        <a
          href="https://kura-storage.spaceeight.net"
          target="_blank" rel="noopener noreferrer"
        >
          {t('aboutPage.15', 'Go to')}
          {' '}
          Kura Storage
        </a>
        <br/>       
        <br/>
        Kura Storage has Text Audio Tool.
        {' '}
        Text Audio Tool provide tools for handling text and audio data.
        <br/>
        That includes Audio Transcriber, Voice Generator, Answer Finder.
        <br/>
        <br/>


      </p>

    </div>
  );

  return (
    <Fragment>
      {aboutPageBody}
    </Fragment>
  );
}
export default AboutPage;

