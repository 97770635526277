import { Fragment, useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';


import Backdrop from "../Backdrop/Backdrop";
import Modal from "../Modal/Modal";

import { useStore } from '../../hook-store/store';
import { changeImageDimensionSize } from '../../utils/image-util'

import { marks } from '../../utils/marks';
import "./ImageCombiner.css";

export default function CombinerFileEdit(props) {
  const { 
    editFile,
    editSelectedNo,
    setEditSelectedNo,
    setInputFile,
    setInputFile2,
   } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    // isAuth, 
    gLoading
  } = store.uiStore;

  const [selectedExt, setSelectedExt] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (editFile && editFile.type) {
      setSelectedExt(editFile.type.split('/')[1]);
    }
  },[editFile]);


  const resizeFileHandler = async(
    file, 
    ext,
    maxWidth, 
    maxHeight
  ) => {
    try {
      setIsLoading(true);

      const resizedFile = await changeImageDimensionSize(
        file,
        'file',
        ext,
        maxWidth,
        maxHeight,
      );

      console.log(resizedFile);

      if (resizedFile) {
        if (editSelectedNo === 1) {
          setInputFile(resizedFile);
        }
        if (editSelectedNo === 2) {
          setInputFile2(resizedFile);
        }
      }

      setIsLoading(false);
      setEditSelectedNo(0);

    } catch(err) {
      console.log(err);
      setIsLoading(false);
      throw err;
    }
  };


  let combinerFileEditBody;

  if (editFile) {
    combinerFileEditBody = (
      <div>
        <div className="combinerSectoin">
          <img
            className="combinerInputImage"
            // style={{ maxHeight: "100px" }}
            title={editFile.name}
            src={URL.createObjectURL(editFile)}
            alt="for resize"
            onClick={() => {}}
            // onLoad={(event) => {
            //   getImgDimension(event, 2);
            // }}
          />
        </div>
        <div className="combinerSectoin">
          <label>
            File format
          </label>
          {' '}
          <select className="combinerFormatSelect"
            // name="pets" 
            // id="pet-select"
            value={selectedExt}
            onChange={(event) => { 
              setSelectedExt(event.target.value);
            }}
          >
            {/* <option value="">
              select-format
            </option> */}
            <option value="jpeg">jpeg</option>
            <option value="png">png</option>
            {/* <option value="webp">WEBP</option> */}
          </select>
        </div>

        <div className="combinerSectoin"></div>

        <div className="combinerSectoin">
          <div className="combinerActionButtons">
            <button className="btnBase"
              disabled={isLoading}
              onClick={() => { setEditSelectedNo(0); }}
            >
              Cancel
            </button>
            <button className="btnBase"
              disabled={isLoading}
              onClick={() => {
                resizeFileHandler(
                  editFile,
                  selectedExt,
                  2000,
                  2000,
                )
              }}
            >
              resize or change format
            </button>
          </div>
        </div>

      </div>
    )
  }



  return (
    <Fragment>
          <div>
            <Backdrop 
              onCancel={() => { 
                if (!isLoading) {
                  setEditSelectedNo(0);
                }
              }}
            />
            <Modal
              onClose={() => { 
                if (!isLoading) {
                  setEditSelectedNo(0);
                }
              }}
            >
              <div>
                {combinerFileEditBody}
              </div>
          </Modal>
        </div>
    </Fragment>
  );
}
