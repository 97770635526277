import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';

import { useStore } from '../../../hook-store/store';
import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import { createSortedTopLevels } from '../../../utils/bucket/bucket-ui-util';





function ObjectSearchHandle(props) {
  const {
    getBucketCommonPrefixes,
    getAllBucketObjects,
    createToplevelsHandler,
  } = props;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const topLevels = store.bucketObjectStore.topLevels;
  const searchSelectedItem = store.bucketObjectStore.searchSelectedItem;

  const { allBucketObjects } = store.bucketObjectStore;

  const gLoading = store.uiStore.gLoading;

  // const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (searchSelectedItem) {
      const prefixFromKey = bucketObjectUtils.getPrefixFromKey(searchSelectedItem.Key);
      console.log(prefixFromKey, searchSelectedItem);

      if (!prefixFromKey && !currentPrefix) {

        const sortedTopLevels = createSortedTopLevels(topLevels);
        const searchTopLevels = bucketObjectUtils.createSearchedTopLevels(searchSelectedItem, sortedTopLevels);
        // console.log(searchTopLevels)

        if (searchTopLevels) {
          dispatch('SET_TOPLEVELS', searchTopLevels);
          // dispatch('SET_SEARCH_SELECTEDITEM', null);
        }
      }

      if (!prefixFromKey && currentPrefix) {
        // getBucketCommonPrefixes(bucketName);
        createToplevelsHandler('', allBucketObjects);
      }

      if (prefixFromKey && !currentPrefix) {
        // getAllBucketObjects(bucketName, prefixFromKey);
        createToplevelsHandler(prefixFromKey, allBucketObjects);
      }

      if (prefixFromKey && currentPrefix) {
        if (prefixFromKey === currentPrefix) {
          const sortedTopLevels = createSortedTopLevels(topLevels);
          const searchTopLevels = bucketObjectUtils.createSearchedTopLevels(searchSelectedItem, sortedTopLevels);

          if (searchTopLevels) {
            dispatch('SET_TOPLEVELS', searchTopLevels);
            // dispatch('SET_SEARCH_SELECTEDITEM', null);
          }
        } else {
          // getAllBucketObjects(bucketName, prefixFromKey);
          createToplevelsHandler(prefixFromKey, allBucketObjects);
        }
      }

    }
  },[searchSelectedItem]);



  
  return (
    <Fragment>
    </Fragment>
  );
}

export default ObjectSearchHandle;
