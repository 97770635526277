import { Fragment, useState, useEffect, useRef } from "react";

// import CombinerInputs from "./ImageFusionInputs";
import Backdrop from "../Backdrop/Backdrop";
import Loader from "../Loader/Loader";
import ImageFusionInputs from "./ImageFusionInputs";
import ImageFusionSocket from "./ImageFusionSocket";
// import CombinerResult from "./CombinerResult";

import { useStore } from "../../hook-store/store";

import { BACKEND_URL } from "../../App";

import './ImageFusion.css';


export default function ImageFusion(props) {

  const [store, dispatch] = useStore();
  const { isFusionServer } = store.imageFusionStore;

  // const [isServerExist, setIsServerExist] = useState(false);
  // const [serverLoading, setServerLoading] = useState(false);

  useEffect(() => {
    const fetchServer = async () => {
      try {
        // setServerLoading(true);
        // const result = await fetch(FUSIONAPI_URL + '/test-get');
        const result = await fetch(BACKEND_URL + '/style-transfer/test-get');
        
        const resData = await result.json();

        if (result.status !== 200) {
          throw new Error('fetch server failed');
        }

        if (result.status === 200) {
          dispatch('SET_ISFUSIONSERVER', true);
          // setIsServerExist(true);
          
        } else {
          dispatch('SET_ISFUSIONSERVER', false);
          // setIsServerExist(false);
          throw new Error('fetch server failed');
        }

        // setServerLoading(false);
        // console.log(result, resData);

      } catch(err) {
        console.log('fetch server err', err);
        dispatch('SET_ISFUSIONSERVER', false);
        // setIsServerExist(false);

        // setServerLoading(false);
      }
    }

    fetchServer();

    const fetchServerInterval = setInterval(() => {
      fetchServer();
    }, 1000*5);



    return () => {
      clearInterval(fetchServerInterval);
    }

  },[]);


  let bannerElement;

  if (window.innerWidth <= 450) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437603549"
        style={{width:"310px", height:"70px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437603549"
      />
    );
  }

  if (window.innerWidth > 450 && window.innerWidth <= 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437618599"
        style={{width:"460px", height:"95px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437618599"
      />
    )
  }

  if (window.innerWidth > 768) {
    bannerElement = (
      <iframe id="kura-image-photo.spaceeight.net-1697437639506"
        style={{width:"610px", height:"135px", border:"none"}}  
        src="https://adnetwork-adserviceadpage.spaceeight.net?adPlaceId=kura-image-photo.spaceeight.net-1697437639506"
      />
    );
  }

  return (
    <Fragment>
        {/* {serverLoading && !isServerExist && (
          <Loader />
        )} */}

        <div>
          <div className="imageFusion">
            <div 
              style={{
                display: 'flex',
                justifyContent: "center",
                paddingBottom: "2rem"
              }}
            >
              {bannerElement}
            </div>
    

          <ImageFusionInputs />
        </div>

        <ImageFusionSocket />
      </div>
    </Fragment>
  );
}
