import { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { useStore } from '../../hook-store/store';

import { marks } from '../../utils/marks';
import "./ImageFusion.css";

export default function ImageFusionProgress(props) {
  const { fusionInputs } = props;

  const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();
  const { 
    runCycle, 
    isUserFusionRunning,
    isFusionServer,
    isFusionServerProcessing,
   } = store.imageFusionStore;

  return (
    <Fragment>

      {/* {runCycle > 0 && isUserFusionRunning && (
        <div className="fusionProgressCycle"
        // style={{position:"fixed", top:"100px", left:"100px", fontSize:"2rem"}}
        >
          {runCycle} / {fusionInputs.cycle.value}
        </div>
      )} */}

      {!isFusionServer && (
        <div className="fusionProgressServerNotify"
          // style={{display:"fixed", top: "80px", left: "10px", fontWeight:"bold", fontSize:"1.5rem"}}
        >
          {t('imageFusion15', 'Service is not available now. Please try again later.')}
        </div>
      )}

      {(isFusionServerProcessing || isUserFusionRunning) && (
        <div className="fusionProgressServerNotify"
          // style={{position:"fixed", top:"50px", left:"10px", fontSize:"1.5rem"}}
        >
          {t('imageFusion16', 'Fusion server is busy...')}
        </div>
      )}
    </Fragment>
  );
}
