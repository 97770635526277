import { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../utils/bucket-object-util';
// import * as fileMoveUtils from '../../../utils/bucket/file-move-util';

import Backdrop from '../../Backdrop/Backdrop';
import ModalConfirm from '../../Modal/ModalConfirm';
import ModalPosition from '../../Modal/ModalPosition';
import FilesUpload from './FilesUpload';
import CreateFolder from './CreateFolder';
import SizesSummary from '../SizesContent/SizesSummary';
import SizesContent from '../SizesContent/SizesContent';

// import { BACKEND_URL } from '../../../App';

import { marks } from '../../../utils/marks';
import classes from './ObjectControl.module.css';

function ObjectControl(props) {

  const {
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');

  // const checkMark = marks.checkMark;
  // const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-BucketObjects.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const selectedFileKeys = store.bucketObjectStore.selectedFileKeys;
  const movePrefix = store.bucketObjectStore.movePrefix;
  const movePrefixes = store.bucketObjectStore.movePrefixes;
  const topLevels = store.bucketObjectStore.topLevels;
  const showObjectControl = store.bucketObjectStore.showObjectControl;

  const totalSizeGB = store.bucketObjectStore.totalSizeGB;
  const limitGB = store.bucketObjectStore.limitGB;
  const freeSizeGB = store.bucketObjectStore.freeSizeGB;
  const subscriptions = store.stripeData.subscriptions;

  const gLoading = store.uiStore.gLoading;
  const windowValues = store.uiStore.windowValues;
  const isAuth = store.uiStore.isAuth;

  // const [clickedObjectKey, setClickedObjectKey] = useState('');
 
  const [modalPositions, setModalPositions] = useState({ 
    top: null, 
    left: null,
    right: null,
    bottom: null,
    heightLimit: 300,
  });

  // const [showObjectControl, setShowObjectControl] = useState(false);
  const [showDropArea, setShowDropArea] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showSizesSummary, setShowSizesSummary] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   const activeSubscription = subscriptions.find(subscription => {
  //     return subscription.status === 'active';
  //   });
  
  //   if (activeSubscription) {
  //     const subLimitGB = Number(activeSubscription.metadata.storeSizeGB);
      
  //     if (subLimitGB > 0) {
  //       dispatch('SET_LIMITGB', subLimitGB);
  //     }
  //   } else {
  //     dispatch('SET_LIMITGB', freeSizeGB);
  //   }

  // },[subscriptions]);

  const showObjectControlHandler = (value) => {
    // setShowObjectControl(value);
    dispatch('SET_SHOWOBJECTCONTROL', value);

    const targetEl = document.getElementById(`object-control`);
    const targetRect = targetEl.getBoundingClientRect();

    console.log(targetEl);
    console.log(targetRect);

    console.log(targetRect.bottom, window.innerHeight - targetRect.bottom, window.innerHeight)
    
    // const heightLimit = 300;

    setModalPositions({
      ...modalPositions,
      top: targetRect.bottom,
      heightLimit: window.innerHeight - targetRect.bottom,
    })

  };



  

 


  if (!isAuth || !bucketName || window.location.pathname !== '/') {
    return null;
  }

  return (
    <Fragment>
      <div className={classes.objectControll}
        id="object-control"
      >
        <span className={classes.objectControlButton}
          onClick={() => {
            if (!isLoading) {
              showObjectControlHandler(true);
            }
          }}
        >
          {t('objectControl.01', 'Menu')}
        </span>
      </div>

      {showObjectControl && (
        <div>
          <Backdrop 
            zIndex={'90'}
            // backgroundColor={'rgba(0, 0, 0, 0.1)'}
            backgroundColor={'rgba(0, 0, 0, 0)'}
            onCancel={() => { 
              if (!isLoading) {
                // setClickedObjectKeyHandler(''); 
                showObjectControlHandler(false);
              }
            }}
          />
          <ModalPosition 
            top={modalPositions.top}
            left={modalPositions.left}
            right={modalPositions.right}
            bottom={modalPositions.bottom}
            heightLimit={modalPositions.heightLimit}
            position='absolute'
            zIndex='100'
            // onClose={() => {
            //   if (!isLoading) {
            //     showObjectControlHandler(false);
            //   }
            // }}
          >
            <div className={classes.objectControlModal}>
              {/* <div className={classes.modalCloseMark}>
                <span className={classes.modalCloseMarkButton}
                  onClick={() => {
                    if (!isLoading) {
                      showObjectControlHandler(false);
                    } 
                  }}
                >
                  {marks.closeMark}
                </span>
              </div> */}
              {/* <div>
                object-control-modal
              </div> */}
              
              <div className={classes.objectControlListItem}>
                <span
                  onClick={() => { 
                    if (!isLoading) {
                      setShowDropArea(true); 
                      showObjectControlHandler(false);
                    }
                  }}
                >
                  {marks.uploadMark} {t('objectControl.02', 'upload files')}
                </span>
              </div>
              <div className={classes.objectControlListItem}>
                <span
                  onClick={() => { 
                    if (!isLoading) {
                      setShowCreateFolder(true); 
                      showObjectControlHandler(false);
                    }
                  }}
                >
                  {marks.folderFillMark} {t('objectControl.03', 'create folder')}
                </span>
              </div>

              <div className={classes.objectControlListItem}>
                <span
                  onClick={() => { 
                    if (!isLoading) {
                      dispatch('SET_DISPLAYTABLE', 'recent'); 
                      showObjectControlHandler(false);
                    }
                  }}
                >
                  {marks.replyMark} {t('objectControl.04', 'recent visit')}
                </span>
              </div>
              <div className={classes.objectControlListItem}>
                <span
                  onClick={() => { 
                    if (!isLoading) {
                      dispatch('SET_DISPLAYTABLE', ''); 
                      showObjectControlHandler(false);
                    }
                  }}
                >
                  {marks.tableMark} {t('objectControl.05', 'files')}
                </span>
              </div>

              <div className={classes.objectControlListItem}
                onClick={() => { 
                  if (!isLoading) {
                    dispatch('SET_DISPLAYTABLE', 'trashbox'); 
                    // showObjectControlHandler(false);
                  }
                }}
              >
                {marks.trashMark} {t('trash.14', 'trash box')}
              </div>

              <div className={classes.objectControlListItem}>
                <SizesContent
                  setShowSizesSummary={setShowSizesSummary}
                />
              </div>

            </div>
          
          </ModalPosition>
        </div>
      )}
    
      {showDropArea && (
        <FilesUpload 
          setShowDropArea={setShowDropArea}
        />
      )}
      {showCreateFolder && (
        <CreateFolder 
          setShowCreateFolder={setShowCreateFolder}
        />
      )}

      {showSizesSummary && (
        <div>
          <Backdrop 
            zIndex={'95'}
            onCancel={() => { 
              if (!isLoading) {
                setShowSizesSummary(false);
              }
            }}
          />
        <ModalConfirm 
        hideButtons={true}
        onClose={() => { 
          if (!isLoading) {
            setShowSizesSummary(false);
          } 
        }}
        >
          <SizesSummary />
        </ModalConfirm>
        </div>
      )}

    </Fragment>
  );
}

export default ObjectControl;
