import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

// import Loader from '../../Loader/Loader';
import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import { 
  getOriginalFileNameFromKey,
  getObjectSignedUrl,
} from '../../../utils/bucket/bucket-object-util';
import {
  createFileMark
} from '../../../utils/bucket/bucket-ui-util';
import { 
  isTrashboxObject,
} from '../../../utils/bucket/object-metadata-util';

// import { BACKEND_URL } from '../../../App';
import { marks } from '../../../utils/marks';

import classes from './FolderAndFileList.module.css';
import { BACKEND_URL } from '../../../App';

function FolderAndFileFileItem(props) {
  const { 
    // getPrefixesHandler,
    // selectedPrefixHandler,
    onFileClick,
    withImageLink,
    withAllLink,
    withCheckbox,
    selectedObjects,
    editSelectedObjectsHandler,
    file,
    // isLoading,
  } = props;

  const [t] = useTranslation('translation');
  // console.log(marks);
  // const checkMark = marks.checkMark;
  // const noCheckMark = marks.noCheckMark;

  const [store, dispatch] = useStore();
  // console.log('store-FolderList.js', store);
  const { 
    bucketName,
    prefixList,
    selectedPrefix,
    allBucketObjects,
    imageUrlList,
  } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;
  const { allObjectsMetaList } = store.objectMetadataStore;

  // const [clickedPrefix, setClickedPrefix] = useState('');
  const [isObjectUrlLoading, setIsObjectUrlLoading] = useState(false);
  const [objectUrl, setObjectUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  const getObjectSignedUrlHandler = async (bucket, key) => {
    try {
      setIsObjectUrlLoading(true);

      const resData = await getObjectSignedUrl(
        BACKEND_URL,
        localStorage.getItem('token'),
        bucket,
        key,
        '',
      );
      // console.log(resData);
      if (resData && resData.data) {
        setObjectUrl(resData.data);
      }

      setIsObjectUrlLoading(false);
    } catch(err) {
      console.log(err);
      setIsObjectUrlLoading(false);
      throw err;
    }
  };


  // console.log(topFiles);
  let isObjectSelected = false;


  let folderAndFileFileItemBody;

  if (file) {
    if (selectedObjects && selectedObjects.length > 0) {
      const isInSelected = selectedObjects.find(element => {
        return file.Key === element.Key;
      });
  
      if (isInSelected) {
        isObjectSelected = true;
      }
    }
    // console.log(file, selectedObjects, isObjectSelected);

    let isImage = false;
    let addPreviewLink = false;

    if (file.mimeType && file.mimeType.split('/')[0] === 'image') {
      isImage = true;
    }

    if (withAllLink || withImageLink) {
      addPreviewLink = true;
    }


    let imageUrl;

    const imageUrlObj = imageUrlList.find(element => {
      return element.key === file.Key;
    });

    if (imageUrlObj?.imageUrl) {
      imageUrl = imageUrlObj.imageUrl;
    }

    if (imageUrlObj?.smallImageUrl) {
      imageUrl = imageUrlObj.smallImageUrl;
    }

    let previewLink;

    if (addPreviewLink && isImage && !imageUrl) {
      previewLink = (
        <span>
          <span 
            onClick={() => {
              getObjectSignedUrlHandler(bucketName, file.Key);
            }}
          >
            (preview {createFileMark(file)})
          </span>
          {objectUrl && objectUrl.params.Key === file.Key && !isObjectUrlLoading && (
            <img 
              style={{ maxHeight: "75px", maxWidth: "75px"}}
              src={objectUrl.url}
              onClick={() => { onFileClick(file); }}
            />
          )}
          {objectUrl && objectUrl.params.Key === file.Key && isObjectUrlLoading && (
            <span>{marks.spinner}</span>
          )}


          <img 
            style={{ maxHeight: "75px", maxWidth: "75px"}}
            src={imageUrl}
            onClick={() => { onFileClick(file); }}
          />


        </span>
      );
    }

    if (addPreviewLink && isImage && imageUrl) {
      previewLink = (
        <span>
          <img 
            style={{ maxHeight: "75px", maxWidth: "75px"}}
            src={imageUrl}
            onClick={() => { onFileClick(file); }}
          />
        </span>
      );
    }


    folderAndFileFileItemBody = (
      <span>
        {withCheckbox && (
          <span 
            style={{paddingRight: "0.5rem"}}
            onClick={() => {
              if (!isLoading) {
                editSelectedObjectsHandler(file, selectedObjects);
              }
            }}
          >
            
            {!isObjectSelected && marks.noCheckMark}
            {isObjectSelected && marks.checkMark}
          </span>
        )}
        <span 
          style={{ lineHeight: "2rem" }}
          onClick={() => { onFileClick(file); }}
        >
          {createFileMark(file)}
          {' '}
          {getOriginalFileNameFromKey(file.Key)}
        </span>
        {' '}
        {previewLink}
      </span>
 
  );
  }

  return (
    <Fragment>
      {folderAndFileFileItemBody}
    </Fragment>
  );
}

export default FolderAndFileFileItem;
