import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';

// import Backdrop from '../../Backdrop/Backdrop';
import Folder from '../BucketComponents/Folder/Folder';
// import Modal from '../../Modal/Modal';
import Loader from '../Loader/Loader';

import { useStore } from '../../hook-store/store';
import * as uploadUtils from '../../utils/bucket/bucket-upload-util';
import { BACKEND_URL, loginPageLink, signupPageLink } from '../../App';


// import { BACKEND_URL, loginPageLink, signupPageLink } from '../../../App';

import './ResizerUpload.css';
// import "./tui-image-editor.css";
// import "./FileControl.css";

export default function ResizerUploadModalContent(props) {
  const {
    showUploadMoadlHandler
  } = props;

  const [t] = useTranslation('translation');
  // console.log('UploadEditFile-props', props);

  const [store, dispatch] = useStore();
  const { 
    bucketName, 
    topLevels, 
    totalSizeGB, 
    limitGB,
    selectedPrefix,
  } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;
  const { 
    resizedFiles,
  } = store.imageResizerStore;

  const uploadFiles = [];

  for (const resizedFile of resizedFiles) {
    uploadFiles.push(resizedFile.file);
  }

  // const [imageData, setImageData] = useState();
  // const [selectedFile, setSelectedFile] = useState();
  
  // const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const [outputFormat, setOutputFormat] = useState('png');
  // const [editFile, setEditFile] = useState();

  const [isLoading, setIsLoading] = useState();


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  // useEffect(() => {
  //   if (file) {
  //     // const fileName = file.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}-cleanup$1`);
  //     const fileName = file.name;
  //     const fileSeparate = fileName.split('.');
  //     const ext = fileSeparate.pop();
  //     setFileName(fileSeparate.join('.') + '-clean');
  //     setOutputFormat(ext);
  //   }
  // },[file]);


  const setFileNameHandler = (event) => {
    // setFileName(event.target.value + '.' + outputFormat);
    setFileName(event.target.value);

    if (event.target.valuse && event.target.value.includes('/')) {
      const separates = event.target.value.split('/');
      setFileName(separates.join(''));
    }
  };

  // const getCurrentRender = async (renders) => {
  //   let targetFile = file
  //   if (renders.length > 0) {
  //     const lastRender = renders[renders.length - 1]
  //     targetFile = await srcToFile(lastRender.currentSrc, file.name, file.type)
  //   }
  //   return targetFile
  // };

  const resizeImagesUploadHandler = async (path, files) => {
    try {
      // setIsLoading(true);
      dispatch('SET_GLOADING', true);

      let resultsForAction = {
        fileUpload: null,
      };


      // if (!file) {
      //   throw new Error('error occured');
      // }

      // const name = file.name.replace(/(\.[\w\d_-]+)$/i, `-${Date.now()}-cleanup$1`)
      // const curRender = renders[renders.length - 1];
      // const currentRenderFile = await getCurrentRender(storeRenders);
      // const uploadFile = new File([currentRenderFile], name, {type: currentRenderFile.type});
      // const uploadFile = new File([currentRenderFile], fileName, {type: currentRenderFile.type});

      // const uploadFile = await uploadFileCreate(curRender.currentSrc, name, file.type);
      
      // const files = [uploadFile];
      // console.log('file, files', file, files);
      // console.log(currentRenderFile);
      console.log(path, files);
      // throw new Error('error-error');

      if (!files || files.length === 0) {
        throw new Error('upload file is required');
      }

      if (files.length > 10) {
        throw new Error('Upload limit is 10 file');
      }

      let uploadSizeGB = 0;
      // let limitGB = 0.1;

      for (const file of files) {
        uploadSizeGB = uploadSizeGB + file.size / 10**9;
      }

      // console.log('uploadSizeGB', uploadSizeGB, totalSizeGB);

      // const activeSubscription = subscriptions.find(subscription => {
      //   return subscription.status === 'active';
      // });

      // if (activeSubscription) {
      //   limitGB = parseInt(activeSubscription.metadata.storeSizeGB);
      // }
      
      if ((totalSizeGB + uploadSizeGB) > limitGB) {
        throw new Error('Total file sizes are more than limit');
      }


      // console.log(hasActiveSubscription, hasActiveSubscription2);


      let result;

      // result = await uploadUtils.s3fileUpload(
      //   BACKEND_URL,
      //   localStorage.getItem('token'),
      //   path,
      //   files,
      //   bucketName,
      // );
      result = await uploadUtils.s3fileUpload2(
        BACKEND_URL,
        localStorage.getItem('token'),
        path,
        files,
        bucketName,
        dispatch,
        'imageResizer',
      );

      console.log(result);
      
      resultsForAction.fileUpload = result.data;

      // setSelectedFiles(null);

      const addedList = topLevels.concat(result.data.data);
      dispatch('SET_TOPLEVELS', addedList);

      // setSelectedFiles([]);
      // setDroppedFiles([]);
      // setShowDropArea(false);


      dispatch('SET_OBJECTACTION', {
        actionType: 'file-upload',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.data.dataForMetadata);

      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'upload success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);

      showUploadMoadlHandler(false);
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      // setSelectedFiles([]);
      // setDroppedFiles([]);

      //// set isUploadFailed to get services-info again
      dispatch('SET_ISUPLOADFAILED', true);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `upload failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      
      // setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }

  };

 

  let resizeUploadModalContentBody;

  resizeUploadModalContentBody = (
    <div className='fileUploadModalContent'>
      <section className='uploadEditFileSection'>
        <div>{t('resizer15', 'Resized files for uploading')}</div>
        <ul>
          {uploadFiles.map(item => {
            return (
              <li>{item.name}</li>
            );
          })}
        </ul>
      </section>

      <section className='uploadEditFileSection'>
        <Folder />
      </section>

      <section className='uploadEditFileSection'>
        <div>
          <button className="btnBase"
            disabled={uploadFiles.length === 0 || isLoading}
            // onClick={() => { saveImageToDisk(filePath); }}
            onClick={() => { 
              if (!isLoading) {
                resizeImagesUploadHandler(
                  selectedPrefix, 
                  uploadFiles,
                 );
              }
              // saveImageToDisk(fileName); 
            }}
          >
            {t('resizer16', 'upload files')}
          </button>
        </div>
      </section>

      {isLoading && (<Loader />)}
    </div>
  );


  return (
    <Fragment>
      <div>
        {resizeUploadModalContentBody}
      </div>
    </Fragment>
  );
}

