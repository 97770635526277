import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import numeral from "numeral";
// import _ from "lodash";

// import Backdrop from '../../Backdrop/Backdrop';
// import ModalConfirm from '../../Modal/ModalConfirm';

import { useStore } from "../../../hook-store/store";

// import { marks } from "../../../utils/marks";
// import { BACKEND_URL } from "../../../App";

import classes from "./SizesContent.module.css";

function ObjectControl(props) {
  const {
    // isLoading,
    setShowSizesSummary,
  } = props;

  const [t] = useTranslation('translation');


  const [store, dispatch] = useStore();

  const { 
    totalSizeGB, 
    limitGB 
  } =
    store.bucketObjectStore;
  const { 
      serviceBucketsDataList,
    } = store.serviceBucketsStore;
  const { gLoading, isAuth } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  
  let imagePhotoServiceData;
  let imagePhotoTotalGB;

  if (serviceBucketsDataList.length > 0) {
    imagePhotoServiceData = serviceBucketsDataList.find(element => {
      return element.bucket.endsWith('-image-photo');
    });

    if (imagePhotoServiceData) {
      imagePhotoTotalGB = imagePhotoServiceData.totalSizeGB;
    }
  }

  return (
    <Fragment>
      <div>
        <div className={classes.sizesContentItem}>
          {t('sizeContent.01', 'Total size')} {numeral(totalSizeGB * 10 ** 9).format("0.0b")}
        </div>
        <div className={classes.sizesContentItem}>
          {/* Kura Image Photo {numeral(bucketSizeGB * 10 ** 9).format("0.0b")} */}
          Kura Image Photo {numeral(imagePhotoTotalGB * 10 ** 9).format("0.0b")}
        </div>

        <div className={classes.sizesContentItem}>
          {t('sizeContent.02', 'Size limit')} {numeral(limitGB * 10 ** 9).format("0.0b")} 
        </div>
        
        <div className={classes.sizesContentItem}>
          ({((totalSizeGB / limitGB) * 100).toFixed(1)}% used)
        </div>

        {totalSizeGB > limitGB * 0.8 && (
          <div className={classes.sizesContentItem}>
            <Link to="sizemanage">
              <strong>
                {t('sizeContent.03', 'Close to size limit. Please consider upgrade size.')}
              </strong>
            </Link>
          </div>
        )}

        <div className={classes.sizesContentForModal}
          onClick={() => {
            if (!isLoading) {
              setShowSizesSummary(true);
            }
          }}
        >
          {t('sizeContent.04', 'Display sizes summary')}
        </div>

        <div className={classes.sizesContentItem}
          onClick={() => {
            if (!isLoading) {
            }
          }}
        >
          <Link to="sizemanage">
            {t('sizeContent.05', 'Upgrade Size')}
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default ObjectControl;
