import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import AuthRequired from "../../components/Auth/AuthRequired";
import NotApisAvailable from "./NotApisAvailable";

import { useStore } from "../../hook-store/store";

import { marks } from "../../utils/marks";
import classes from './NotPageFound.module.css';

const NotPageFound = (props) => {
  // const { } = props;
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { isAuth, gLoading, isAllApis } = store.uiStore;
  const { isStripeApi } = store.stripeData;

  useEffect(() => {

  }, []);

  let notPageFoundBody;

  if (isAuth && isAllApis) {
    notPageFoundBody = (
      <div className={classes.notPageFoundContent}>
        <div>{t('nptPageFound.01', 'Page not found')}</div>

        <div className={classes.notPageFoundButtons}>
          <Link to="/">
            <button className="btnBase">
              {marks.tableMark} {t('nptPageFound.02', 'Go to files page')}
            </button>
          </Link>
          <Link to="/image-editor">
            <button className="btnBase">
              {marks.paintBrushMark} {t('nptPageFound.03', 'Go to image editor page')}
            </button>
          </Link>
        </div>

        <div className={classes.notPageFoundAboutLink}>
          <Link to="/about">
            About Kura Image Photo
          </Link>
        </div>
     
        {/* <div>
          not-page-found-content
        </div> */}
      </div>
    )
  }

  if (isAuth && !isAllApis) {
    notPageFoundBody = (
      <div>
        <NotApisAvailable />
      </div>
    )
  }

  if (!isAuth) {
    notPageFoundBody = (
      <div>
        <AuthRequired />
      </div>
    );
  }


  if (gLoading) {
    notPageFoundBody = (<div></div>);
  }


  return (
    <Fragment>
      <div className="pageContentContainer">
        {notPageFoundBody}
      </div>
    </Fragment>
  );
};

export default NotPageFound;
