import React, { useState, useEffect, Fragment } from 'react';
// import { Link } from 'react-router-dom';
// import { useRedux } from "hooks/useRedux";

import { useStore } from '../../../../hook-store/store';

import { marks } from '../../../../utils/marks';
import classes from './ImageSizeControl.module.css';

function ImageSizeControl(props) {
  const { 
    mobRowNum,
    setMobRowNum,
    imgMaxWidth,
    setImgMaxWidth,
    isLoading,
  } = props;



  const [store, dispatch] = useStore();

  // const [fitScreen, setFitScreen] = useState(false);

  useEffect(() => {

  },[]);

  let imageSizeControlBody;

  if (window.innerWidth < 480) {
    imageSizeControlBody = (
      <div className={classes.imageSizeControl}>
        <span className={classes.imgeSizeControlMobButton}
          style={mobRowNum >= 5 ? {pointerEvents:"none", opacity: '0.3'} : null}
          onClick={() => {
            if (!isLoading && mobRowNum < 5) {
              setMobRowNum(mobRowNum + 1);
            }
          }}
        >
          {marks.minusMark}
        </span>
        {/* <span>Image Size</span> */}
        <span className={classes.imgeSizeControlMobButton}
          style={mobRowNum <= 3 ? {pointerEvents:"none", opacity: '0.3'} : null}
          onClick={() => {
            if (!isLoading && mobRowNum > 3) {
              setMobRowNum(mobRowNum - 1);
            }
          }}
        >
          {marks.plusMark}
        </span>
      </div>
    );
  } 

  if (window.innerWidth >= 480) {
    // imageSizeControlBody = (
    //   <div className={classes.imageSizeControl}>
    //     {/* {imgMaxWidth} */}
    //     <span className={classes.imgeSizeControlButton}
    //       style={imgMaxWidth <= 150 ? {pointerEvents:"none", opacity: '0.3'} : null}
    //       onClick={() => {
    //         if (!isLoading && imgMaxWidth > 150) {
    //           setImgMaxWidth(imgMaxWidth - 100);
    //         }
    //       }}
    //     >
    //       {marks.minusMark}
    //     </span>
    //     {/* <span>Image Size</span> */}
    //     <span className={classes.imgeSizeControlButton}
    //       style={imgMaxWidth > 400 ? {pointerEvents:"none", opacity: '0.3'} : null}
    //       onClick={() => {
    //         if (!isLoading && imgMaxWidth < 400) {
    //           setImgMaxWidth(imgMaxWidth + 100);
    //         }
    //       }}
    //     >
    //       {marks.plusMark}
    //     </span>
    //   </div>
    // );
  }


  return (
    <Fragment>
      <div>
        {imageSizeControlBody}
      </div>
    </Fragment>
  );
}

export default ImageSizeControl;
