import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from "react-image-gallery";
import "./image-gallery.css";

import { useStore } from '../../hook-store/store';
import { isTrashboxObject } from '../../utils/bucket/object-metadata-util';

// const images = [
//   {
//     original: "https://picsum.photos/id/1018/1000/600/",
//     thumbnail: "https://picsum.photos/id/1018/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1015/1000/600/",
//     thumbnail: "https://picsum.photos/id/1015/250/150/",
//   },
//   {
//     original: "https://picsum.photos/id/1019/1000/600/",
//     thumbnail: "https://picsum.photos/id/1019/250/150/",
//   },
// ];
// import classes from './Footer.module.css';


// import { Link } from 'react-router-dom';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

function ImageCarousel(props) {
  const { fileList } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const { 
    allBucketObjects,
    currentPrefix,
    imageUrlList,
  } = store.bucketObjectStore;
  // console.log('store- Layout.js', store);

  const folderObjects = allBucketObjects.filter(object => {
    if (!isTrashboxObject(object.Key, allBucketObjects)) {
      if (!currentPrefix) {
        if (object.Key.split('/').length === 1) {
          return object;
        }
        
      } else {
        if (object.Key.split('/').length === currentPrefix.split('/').length &&
          object.Key.startsWith(currentPrefix)
        ) {
          return object;
        }
      }
    }
  });

  // console.log(folderObjects);

  const imgList = [];

  if (folderObjects.length > 0) {

    for (const object of folderObjects) {
      if (object.mimeType?.split('/')[0] === 'image') {

        let imageUrl;

        const imageUrlObj = imageUrlList.find(element => {
          return element.key === object.Key;
        });
      
        if (imageUrlObj?.imageUrl) {
          imageUrl = imageUrlObj.imageUrl;
        }

        if (imageUrl) {
          imgList.push({
              original: imageUrl,
              thumbnail: "",
          });
        }

      }
    }
  }

  // console.log(imgList);

  

  return (
    <Fragment>
      <ImageGallery 
        items={imgList} 
      />
    </Fragment>
  );
}

export default ImageCarousel;
