import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import numeral from 'numeral';

import { useStore } from '../../../../hook-store/store';

import { marks } from '../../../../utils/marks';

import classes from './FileActions.module.css';


function FileAddTrashboxMeta(props) {

  const {
    object,
    setClickedActionInfoHandler,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // const { 
  //   bucketName, 
  //   currentPrefix,
  // } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;


  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);



  let fileAddTrashboxMetaBody;

  fileAddTrashboxMetaBody = (

    <button className={classes.objectActionListItem}
      disabled={isLoading}
      name="file-add-trashbox-meta"
      onClick={(event) => {if (!isLoading) {
        setClickedActionInfoHandler(event, object.Key);
        // setShowConfirmModal(true);
      } 
    }}
    >
      {marks.trashMark} {t('trash.13', 'move to trash box')}
    </button>
  )


  return (
    <Fragment>
      {fileAddTrashboxMetaBody}
    </Fragment>
  );
}

export default FileAddTrashboxMeta;
