import React, { Fragment, useEffect } from 'react';

// import openSocket from 'socket.io-client';
// import { io } from "socket.io-client";
import { io } from 'socket.io-client';

import { useStore } from '../../hook-store/store';
// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util'
// import { getPrefixFromKey } from '../../../utils/bucket/bucket-object-util';

import { FUSIONAPI_URL, BACKEND_URL } from '../../App';

// import classes from './PostSelect.module.css';


function ImageFusionSocket(props) {
  // const { } = props;

  const [store, dispatch] = useStore();
  // const { bucketName, currentPrefix } = store.bucketObjectStore;
  const { isAuth } = store.uiStore;

  useEffect(() => {

      // let SOCKET_ENDPOINT = FUSIONAPI_URL.replace('https://', '').replace('http://', '');

      // // console.log(SOCKET_ENDPOINT)

      // SOCKET_ENDPOINT = FUSIONAPI_URL.startsWith('https')
      //   ? `wss://${SOCKET_ENDPOINT}`
      //   : `ws://${SOCKET_ENDPOINT}`

      // // console.log(SOCKET_ENDPOINT)

      // const socket = io(`${SOCKET_ENDPOINT}`, {
      //   path: '/ws/socket.io/',
      //   transports: ['websocket', 'polling'],
      // })

      const socket = io.connect(BACKEND_URL, {
        // reconnection: true,
        extraHeaders: {
          // "my-custom-header": "1234",
          "header-client": 'image-fusion-frontend',
        }
      });

      // console.log('before socket.on connect')
      socket.on("connect", () => {
        console.log('connect socket.id', socket.id);
      });

      socket.on("progress", (data) => {
        console.log('progress data', data, Date.now());
        dispatch('SET_RUNCYCLE', data.iteration);
      });

      socket.on("processing", (data) => {
        console.log('processing data', data);
      });

      socket.on("processing_start", (data) => {
        console.log('processing_start data', data);
        dispatch('SET_ISFUSIONSERVERPROCESSING', true);
      });

      socket.on("processing_finish", (data) => {
        console.log('processing_finish data', data);
        dispatch('SET_RUNCYCLE', 0);
        dispatch('SET_ISFUSIONSERVERPROCESSING', false);
      });

      socket.on("processing_error", (data) => {
        console.log('processing_error data', data);
        dispatch('SET_RUNCYCLE', 0);
        dispatch('SET_ISFUSIONSERVERPROCESSING', false);
      });


      return () => {
        socket.off('connect')
        socket.off('disconnect')
        socket.off('progress')
        socket.off('finish')
  
        socket.off('processing')
        socket.off('processing_start')
        socket.off('processing_error')
        socket.off('processing_finish')
        socket.off('run_params')
      }
  },[]);


 



 

  return (
    <Fragment></Fragment>
  );
}

export default ImageFusionSocket;