import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_RESIZERFILES: (state, payload) => {
      return { 
        imageResizerStore : {
          ...state.imageResizerStore,
          resizerFiles: payload, 
        }
      };
    },
    SET_RESIZERSELECTED: (state, payload) => {
      return { 
        imageResizerStore : {
          ...state.imageResizerStore,
          resizerSelected: payload, 
        }
      };
    },
    SET_RESIZEDFILES: (state, payload) => {
      return { 
        imageResizerStore : {
          ...state.imageResizerStore,
          resizedFiles: payload, 
        }
      };
    },
  };

  initStore(actions, {
    imageResizerStore: {
      resizerFiles: [],
      resizerSelected: undefined,
      resizedFiles: [],
    }
  });
};

export default configureStore;
