import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Backdrop from '../../Backdrop/Backdrop';
import ModalConfirm from '../../Modal/ModalConfirm';
import { useStore } from '../../../hook-store/store';

import * as uploadUtils from '../../../utils/bucket/bucket-upload-util';
import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
// import * as fileMoveUtils from '../utils/file-move-util';

import { BACKEND_URL } from '../../../App';

import classes from './CreateFolder.module.css';

function CreateFolder(props) {

  const { setShowCreateFolder } = props;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store in FileUpload.js', store);
  const currentPrefix = store.bucketObjectStore.currentPrefix;
  const bucketName = store.bucketObjectStore.bucketName;
  const topLevels = store.bucketObjectStore.topLevels;
  const movePrefixes = store.bucketObjectStore.movePrefixes;

  const gLoading = store.uiStore.gLoading;

  const [folderNameInput, setFolderNameInput] = useState('');
  // const [showCreateFolder, setShowCreateFolder] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  const folderNameHandler = (event) => {
    setFolderNameInput(event.target.value);
  };

  const createFolderHandler = async (folderName, bucket) => {
    try {
      setIsLoading(true);
      dispatch('SET_GLOADING', true);


      let resultsForAction = {
        createFolder: null,
      };

      // console.log('topLevels', topLevels);
      const isFolderNameExist = topLevels.find((element) => {
        return element.Key === currentPrefix + folderName + '/';
      });
      // console.log('isFolderNameExist', isFolderNameExist);
      if (isFolderNameExist) {
        throw new Error('same folder name exist');
      }

      if(!folderName || folderName.includes('/')) {
        throw new Error('folder name is not provided or contains / ');
      }

      const result = await bucketObjectUtils.createFolder(
        BACKEND_URL,
        localStorage.getItem('token'),
        currentPrefix + folderName + '/',
        bucket,
      );

      console.log(result);

      resultsForAction.createFolder = result;

      if (!result) {
        throw new Error('');
      }

      const folderKey = currentPrefix + folderName + '/';
      const addedList = uploadUtils.createFolderAddedObjectList(folderKey, topLevels, currentPrefix);
      dispatch('SET_TOPLEVELS', addedList);
      
      setShowCreateFolder(false);


      dispatch('SET_OBJECTACTION', {
        actionType: 'create-folder',
        delete: false,
        add: true,
        result: resultsForAction,
      });

      dispatch('SET_DATAFORMETADATA', result.dataForMetadata);


      dispatch('SHOW_NOTIFICATION', {
        status: '',
        title: 'create folder success',
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 3);


      setIsLoading(false);
      dispatch('SET_GLOADING', false);

    } catch(err) {
      console.log(err);

      dispatch('SHOW_NOTIFICATION', {
        status: 'error',
        title: `create folder failed. ${err.message && err.message}`,
        message: '',
      });

      setTimeout(() => {
        dispatch('CLEAR_NOTIFICATION');
      },1000 * 10);

      setIsLoading(false);
      dispatch('SET_GLOADING', false);
    }
  };

  // const deleteFoldersHandler = async (bucket, prefixes) => {
  //   try {
  //     setIsLoading(true);

  //     const result = await bucketObjectUtils.deleteFolders(
  //       BACKEND_URL,
  //       localStorage.getItem('token'),
  //       bucket,
  //       prefixes,
  //     );

  //     console.log(result);

  //     dispatch('SET_MOVEPREFIX', '');
  //     dispatch('SET_SELECTED_PREFIX', '');

  //     dispatch('SET_MOVEPREFIXES', []);

  //     const deletedList = fileMoveUtils.createFoldersDeletedObjectList(prefixes, topLevels, currentPrefix);
  //     dispatch('SET_TOPLEVELS', deletedList);


  //     setIsLoading(false);
  //   } catch(err) {
  //     console.log(err);
  //     setIsLoading(false);
  //   }
  // };


  return (
    <Fragment>
      {/* <div
        onClick={() => {
          if (!isLoading) {
            setShowCreateFolder(!showCreateFolder);
          }
        }}
      >
        create-folder
      </div> */}
        <Backdrop 
          onCancel={() => { 
            if (!isLoading) {
              setShowCreateFolder(false); 
            }
          }}
          zIndex={'150'}
        />
        <ModalConfirm
          onClose={() => { 
            if (!isLoading) {
              setShowCreateFolder(false);
            }
           }}
          onAccept={() => { 
            if (!isLoading) {
              createFolderHandler(folderNameInput, bucketName); 
            }
          }}
          hideButtons={true}
          isLoading={isLoading}
        >
          <div>
            <div className={classes.folderNameLabel}>
              {t('objectControl.10', 'Enter Folder Name')}
            </div>
            <input className={`inputBase ${classes.folderNameInput}`}
              // className={classes.folderNameInput}
              type="text" name="createfolder" 
              onChange={folderNameHandler} 
              autoFocus
            />
            <div>
              <button className="btnBase"
                disabled={isLoading}
                onClick={() => {
                  if (!isLoading) {
                    createFolderHandler(folderNameInput, bucketName);
                  }
                }}
              >
                {t('objectControl.11', 'Create Folder')}
              </button> 
            </div>
          </div>
        </ModalConfirm>
     
    </Fragment>
  );
}

export default CreateFolder;
