import { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// import Loader from '../../Loader/Loader';
import FolderAndFileFileItem from './FolderAndFileFileItem';
import { useStore } from '../../../hook-store/store';

// import * as bucketObjectUtils from '../../../utils/bucket/bucket-object-util';
import { 
  getOriginalFileNameFromKey,
  getObjectSignedUrl,
} from '../../../utils/bucket/bucket-object-util';
import { 
  isTrashboxObject,
} from '../../../utils/bucket/object-metadata-util';

// import { BACKEND_URL } from '../../../App';
import { marks } from '../../../utils/marks';

import classes from './FolderAndFileList.module.css';
import { BACKEND_URL } from '../../../App';

function FolderAndFileList(props) {
  const { 
    getPrefixesHandler,
    selectedPrefixHandler,
    onFileClick,
    withImageLink,
    withCheckbox,
    getUploadSelectedHandler
    // isLoading,
  } = props;

  // console.log(marks);
  // const checkMark = marks.checkMark;
  // const noCheckMark = marks.noCheckMark;

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  // console.log('store-FolderList.js', store);
  const { 
    bucketName,
    prefixList,
    selectedPrefix,
    allBucketObjects,
  } = store.bucketObjectStore;
  const { gLoading } = store.uiStore;
  const { allObjectsMetaList } = store.objectMetadataStore;

  const [clickedPrefix, setClickedPrefix] = useState('');
  const [selectedObjects, setSelectedObjects] = useState([]);
  // const [noTrashObjects, setNoTrashObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  // console.log(selectedObjects)

  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  },[gLoading]);

  useEffect(() => {
    if (getUploadSelectedHandler) {
      getUploadSelectedHandler(selectedObjects);
    }
  },[selectedObjects]);


  
  const editSelectedObjectsHandler = (object, selectedObjects) => {
    
    const isSelected = selectedObjects.find(element => {
      return element.Key === object.Key;
    });
    // console.log(object, isSelected)

    //// folder select case
    if (object.Key.endsWith('/')) {

      const folderObjecs = allBucketObjects.filter(element => {
        return element.Key.startsWith(object.Key);
      });

      console.log(folderObjecs);

      if (isSelected) {
        const deletedList = _.differenceBy(selectedObjects, folderObjecs, 'Key');

        console.log(deletedList);

        setSelectedObjects(deletedList);

      } else {
        const addedList = selectedObjects.concat(folderObjecs);
        
        console.log('addedList', addedList);
        setSelectedObjects(_.uniqBy(addedList, 'Key'));
      }
    }

    //// file select case
    if (!object.Key.endsWith('/')) {
      if (isSelected) {
        const withoutList = selectedObjects.filter(element => {
          return element.Key !== object.Key;
        });
  
        setSelectedObjects(withoutList);
      } else {
        setSelectedObjects(selectedObjects.concat(object));
      }
    }
  }

  const setClickedPrefixHandler = (prefix) => {
    if (prefix === clickedPrefix) {
      setClickedPrefix('');
    } else {
      setClickedPrefix(prefix);
    }
  }

  const hideSelectPrefixHandler = (prefix) => {
    console.log(prefix);

    // const isTopLevel = prefix.split('/').length === 2;

    const prefixes2 = prefixList;

    for (const px of prefixes2) {
      // console.log(px.Prefix, px.Prefix.split('/'), prefix.split('/'));
      if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length === prefix.split('/').length + 1) {
        // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
        px.hide = !px.hide;
      }
      if (px.Prefix.startsWith(prefix) && px.Prefix.split('/').length > prefix.split('/').length + 1) {
        // console.log(px, px.Prefix.split('/'), prefix.split('/') + 1);
        px.hide = true;
      }
    }

    console.log(prefixes2);
    dispatch('SET_PREFIXLIST', prefixes2);

  };


  const topFiles = allBucketObjects.filter(object => {
    return ( 
      object.Key.split('/').length === 1 &&
      !object.Key.endsWith('/') &&
      !isTrashboxObject(object.Key, allObjectsMetaList)
    );
  });

  // console.log(topFiles);

  let foldersBody;

  if (prefixList.length >= 0) {
    foldersBody = (
      <tbody>
        <tr>
            {/* <span 
              onClick={() => {
                if (!isLoading) {
                    // getPrefixesHandler(bucketName, prefix.Prefix);
                    selectedPrefixHandler('');
                }
              }}
            >
              {!selectedPrefix && checkMark}
              {selectedPrefix && noCheckMark}
            </span>
            {' '}
            <span className={classes.folderTableFolderName}>
              top
            </span>   */}
            
              {/* <span>top</span> */}
              <ul>
                {topFiles.map(file => {
                  return (
                    <li 
                      key={file.Key}
                      className={classes.folderTableTopFileName}
                    >
                      <td>
                        <FolderAndFileFileItem 
                          onFileClick={onFileClick}
                          withImageLink={withImageLink}
                          withCheckbox={withCheckbox}
                          selectedObjects={selectedObjects}
                          editSelectedObjectsHandler={editSelectedObjectsHandler}
                          file={file}
                        />
                      </td>
                    </li>
                  );
                })}
              </ul>
        </tr>
        {prefixList.map((prefix, index) => {
          // console.log(prefix);
          const isTopLeve = prefix.Prefix.split('/').length === 2;
          const splitList = prefix.Prefix.split('/');
          
          const isInTrash = isTrashboxObject(prefix.Prefix, allObjectsMetaList);
          
          if (isInTrash) {
            return null;
          }

          const prefixObject = allBucketObjects.find(element => {
            return element.Key === prefix.Prefix;
          });

          const isSelectedObject = selectedObjects.find(element => {
            return element.Key === prefix.Prefix;
          });

          const underExist = prefixList.find(px => {
            return  px.Prefix.startsWith(prefix.Prefix) && px.Prefix.split('/').length > prefix.Prefix.split('/').length;
          });

          const underOpen = prefixList.find(px2 => {
            return  px2.Prefix.startsWith(prefix.Prefix) && 
              px2.Prefix.split('/').length === prefix.Prefix.split('/').length + 1 && 
              !px2.hide;
          });

          const folderFiles = allBucketObjects.filter(object => {
            return (object.Key.startsWith(prefix.Prefix) && 
              object.Key.split('/').length === prefix.Prefix.split('/').length &&
              !object.Key.endsWith('/') &&
              !object.Key.startsWith('trash')
            );
          });

          const folderFilesList = (
            <span>
              <ul>
                {folderFiles.map(file => {
                  return (
                    <li 
                      key={file.Key}
                      className={classes.folderTableTopFileName}
                    >
                      <td
                        style={{ paddingLeft: `${splitList.length*1 - 1}rem`, paddingRight: "0.5rem"}}
                      >
                        <FolderAndFileFileItem 
                          onFileClick={onFileClick}
                          withImageLink={withImageLink}
                          withCheckbox={withCheckbox}
                          selectedObjects={selectedObjects}
                          editSelectedObjectsHandler={editSelectedObjectsHandler}
                          file={file}
                        />
                      </td>
                    </li>
                  );
                })}
              </ul>
            </span>
          );

          // console.log(prefix.Prefix, underExist, underOpen, folderFiles);

          // console.log('underOpen', prefix, underOpen);

          if (isTopLeve || !prefix.hide) {
          // if (isTopLeve || (!prefix.hide && prefix.open)) {
            return (
              <tr key={prefix.Prefix}>
                <td>
                    {withCheckbox && (
                      <span 
                        style={{ paddingLeft: `${splitList.length*1 - 2}rem`, paddingRight: "0.5rem"}}
                        onClick={() => {
                          if (!isLoading) {
                            // getPrefixesHandler(bucketName, prefix.Prefix);
                            // selectedPrefixHandler(prefix.Prefix);
                            editSelectedObjectsHandler(prefixObject, selectedObjects);
                          }
                        }}
                      >
                          {isSelectedObject && marks.checkMark}
                          {!isSelectedObject && marks.noCheckMark}    
                      </span>
                    )}
                    <span className={classes.folderTableFolderName}
                      style={!withCheckbox ? { paddingLeft: `${splitList.length*1 - 2}rem`, paddingRight: "0.5rem" } : null}
                      onClick={() => {
                        if (!isLoading) {
                          // getPrefixesHandler(bucketName, prefix.Prefix);
                          // selectedPrefixHandler(prefix.Prefix);
                          hideSelectPrefixHandler(prefix.Prefix);
                          // setClickedPrefix(prefix.Prefix);
                          setClickedPrefixHandler(prefix.Prefix);
                        }
                      }}
                    >
                      {!underExist && (
                        <span>
                          {clickedPrefix === prefix.Prefix && (
                            <span>{marks.folderFillOpenMark}</span>
                          )}
                          {clickedPrefix !== prefix.Prefix && (
                            <span>{marks.folderFillMark}</span>
                          )}
                          {/* {marks.folderFillMark}  */}
                          {' '}
                          {splitList[splitList.length - 2]} 
                          
                          {/* {isLoading && clickedPrefix === prefix.Prefix && (
                            marks.spinner
                          )} */}
                        </span>
                      )}
                      {underExist && underOpen && (
                        <span>
                          {marks.folderFillOpenMark} {splitList[splitList.length - 2]}
                        </span>
                      )}
                      {underExist && !underOpen && (
                        <span>
                          {marks.folderFillMark} {splitList[splitList.length - 2]}
                        </span>
                      )}
                    </span>
                    
                    {underOpen && (
                      <span>{folderFilesList}</span>
                    )}

                    {clickedPrefix === prefix.Prefix && !underExist && (
                      <span>{folderFilesList}</span>
                    )}


                    
                  {/* </span> */}
                </td>
              </tr>
            );
          }
      })}
      </tbody>
    );
  }



  // const topLevelName = 'top';
  // const prefixArrayLabel = `${t('selectedObject.08', 'Destination Folder')}:`;
  
  // let prefixArray =  (
  //   <span
  //     onClick={() => { 
  //       if (!isLoading) {
  //         selectedPrefixHandler(''); 
  //       }
  //     }}
  //   >
  //     {/* top */}
  //     {prefixArrayLabel}
  //     {' '}
  //     <span style={{fontWeight:"bold"}}>
  //       {topLevelName}
  //     </span>
  //   </span>
  // );

  // if (clickedPrefix) {
  //   prefixArray = (
  //     <span>
  //       <span>{prefixArrayLabel}</span>
  //       {' '}
  //       <span>{topLevelName} /</span>
  //       {' '}
  //       {clickedPrefix.split('/').map((px, index) => {
  //         if (index <= clickedPrefix.split('/').length - 3) {
  //           return (
  //             <span key={px}>
  //               <span> {px} </span>
  //               <span>{'/'} </span>
  //             </span>
  //           );
  //         } else {
  //           return (<span style={{fontWeight:"bold"}}>{px}</span>);
  //         }
  //       })}
  //     </span>
  //   );
  // }


  return (
    <Fragment>
      <table className={classes.folderTable}>
        <thead>
          <tr>
            {/* <th>f-h0</th> */}
            <th></th>
            {/* <th></th> */}
          </tr>
        </thead>
        {foldersBody}
      </table>

      {/* <div>{prefixArray}</div> */}
    </Fragment>
  );
}

export default FolderAndFileList;
