import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useStore } from "../../hook-store/store";

// import Register from "../Subscription/Register";
import Prices from "../../components/subscription/prices/Prices";

import classes from "./SizeManagePage.module.css";

const SizeManagePage = (props) => {
  // const { } = props;
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();

  return (
    <Fragment>
      <div className="subscriptionContent">
        <div className={classes.sizeManage}>
          <section className={classes.sizeManageInfo}>
            <p>
              {t(
                "sizeContent.10",
                "There are several subscription plans to upgrade storage sizes."
              )}
            </p>
            <p>
              {t(
                "sizeContent.11",
                "The price of subscription is depend on the size of storage."
              )}
            </p>
            <p>
              {t(
                "sizeContent.12",
                "Credit card payment is used for monthly payment."
              )}
            </p>

            <div>
              <Link to="/subscription/account">
                <span className={classes.sizeManageLink}>
                  {t("sizeContent.13", "Go to subscription page")}
                </span>
              </Link>
            </div>
          </section>

          <section>
            <div className={classes.sizeManagePlanTitle}>
              {t("sizeContent.14", "Subscription plans for size upgrade")}
            </div>
            <Link to="/subscription/account">
              <Prices hidePriceSelect={true} />
            </Link>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default SizeManagePage;
