import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
// import download from 'downloadjs';

import Loader from '../Loader/Loader';
import { useStore } from '../../hook-store/store';
import { getOriginalFileNameFromKey, getObjectFile } from '../../utils/bucket/bucket-object-util';

import "./tui-image-editor.css";
import "./FileControl.css";

import { BACKEND_URL } from '../../App';

function FileUrlLoad(props) {
  const { 
    editorRef,
    // undoStackLength,
    // initialWidth,
  } = props;

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();


  const [store, dispatch] = useStore();
  const { initialWidth, undoStackLength } = store.imageEditorStore;
  const { bucketName } = store.bucketObjectStore;


  const [imageLoadNum, setImageLoadNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (editorRef && imageLoadNum <= 5) {

      const key = searchParams.get('key');
  
      if (key && editorRef.current && editorRef.current.getInstance()) {
        
        getObjectFileHandler(
          BACKEND_URL,
          localStorage.getItem('token'),
          bucketName,
          key,
          'image/png',
        );
      }
    }
  },[editorRef, imageLoadNum]);


 

  const handleLoadImageFromFile = (file, name) => {
    //// not work well load does not show up in history

    const imageEditor = editorRef.current.getInstance();
    // console.log(editorInstance);
    imageEditor.loadImageFromFile(file, name).then(result => {
      // console.log(result);
      // console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
      // console.log('new : ' + result.newWidth + ', ' + result.newHeight);
      
      // imageEditor.clearUndoStack();

      imageEditor.ui.activeMenuEvent();
    });
  };



  const getObjectFileHandler = async (url, token, bucket, key, mimeType) => {
    try {
      setIsLoading(true);

      const resData = await getObjectFile(
        url,
        token,
        bucket,
        key,
        mimeType,
      );

      if (resData) {
        console.log(resData);

        const key = searchParams.get('key');
        const originalName = getOriginalFileNameFromKey(key);

        const file = new File([resData], originalName);

        handleLoadImageFromFile(file, 'edit-' + originalName);

        navigate('/image-editor', { replace: true });
        // searchParams.delete('key');
        // setSearchParams(searchParams);

      }

      setImageLoadNum(imageLoadNum+1);
      setIsLoading(false);


    } catch(err) {
      console.log(err);
      setImageLoadNum(imageLoadNum+1);
      setIsLoading(false);
    }
  }



  // const createFileHandler = (fileName) => {
  //   const imageEditor = editorRef.current.getInstance();
  //   const data = imageEditor.toDataURL();

  //   const createdFile = dataURLtoFile(data, fileName);
  //   console.log(createdFile);
  // }

  // function dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  //       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  //   while(n--){
  //       u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, {type:mime});
  // }



  return (
    <Fragment>
      {isLoading && (
        <div className='loaderPositionCenter'>
          <Loader />
        </div>
      )}
    </Fragment>
  );
}

export default FileUrlLoad;
