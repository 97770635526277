import { initStore } from "./store";

const configureStore = () => {
  const actions = {
    SET_CONVERTFILES: (state, payload) => {
      return { 
        imageConvertStore : {
          ...state.imageConvertStore,
          convertFiles: payload, 
        }
      };
    },
    SET_CONVERTSELECTED: (state, payload) => {
      return { 
        imageConvertStore : {
          ...state.imageConvertStore,
          convertSelected: payload, 
        }
      };
    },
    SET_CONVERTEDFILES: (state, payload) => {
      return { 
        imageConvertStore : {
          ...state.imageConvertStore,
          convertedFiles: payload, 
        }
      };
    },
  };

  initStore(actions, {
    imageConvertStore: {
      convertFiles: [],
      convertSelected: undefined,
      convertedFiles: [],
    }
  });
};

export default configureStore;
