import { Fragment, useState, useEffect, useRef, } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Resizer from "react-image-file-resizer";

import { useStore } from '../../hook-store/store';

import './ResizerImages.css'
// import classes from './Footer.module.css';

// import { logoutHandler } from '../../utils/user';
// import { loginPageLink, signupPageLink } from '../../App';

export default function ResizerImages(props) {
  // console.log('Layout.js-props', props);

  const [t] = useTranslation('translation');

  // const hiddenFileInput = useRef(null);

  const [store, dispatch] = useStore();
  const { 
    resizerFiles,
    resizerSelected,
    resizedFiles,
  } = store.imageResizerStore;

  const cancelResizeHandler = (id) => {
    const deletedFiles = resizedFiles.filter(element => {
      return element.id !== id;
    });

    dispatch('SET_RESIZEDFILES', deletedFiles);

  };

  let imageResizerImagesBody;

  // console.log(resizerFiles, store.imageResizerStore)
  if (resizerFiles && resizerFiles.length > 0) {
    // console.log(resizerFiles)
    imageResizerImagesBody = (
      <div>
        {!resizerSelected && (
          <div>{t('resizer03', 'Select image to resize')}</div>
        )}
        {resizerSelected && (
          <div>{t('resizer04', 'Go below to resize image')}</div>
        )}
        <ul className='resizerImages'>
          {resizerFiles.map(item => {

            const resizedFile = resizedFiles.find(element => {
              return element.id === item.id;
            });

            let imageStyle = null;

            if (resizedFile) {
              imageStyle = {
                opacity: '0.5',
              }
            }

            if (resizerSelected && item.id === resizerSelected.id) {
              imageStyle = {
                ...imageStyle,
                border: "10px solid gray",
              }
            }
            return (
              <li className='resizerImageContainer'>
                <img className='resizerImage'
                  title={item.file.name}
                  // style={resizedFile ? {opacity: "0.5"} : null}
                  style={imageStyle}
                  src={URL.createObjectURL(item.file)}
                  alt="for resize"
                  onClick={() => { dispatch('SET_RESIZERSELECTED', item) }}

                />
                {/* <span>
                  {displayName}
                </span> */}
                {resizedFile && (
                  <span className='resizerImageResult'>
                    <span>{t('resizer05', 'Resized')}</span>
                    <span
                      onClick={() => { cancelResizeHandler(item.id) }}
                    > ({t('resizer06', 'Cancel')})
                    </span>
                  </span>
                )}
                {/* {resizedFile && resizedFile.targetDimension && (
                  <span className='resizerImageFinishMark'>
                    re-fl ({resizedFile.targetDimension.height}x{resizedFile.targetDimension.width})
                  </span>
                )} */}
              
          
              </li>
            );
          })}
        </ul>
      </div>
    );
  }




  return (
    <Fragment>
      <div>{imageResizerImagesBody}</div>
    </Fragment>
  );
}

