import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useStore } from "../../hook-store/store";

import { loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import classes from './AuthRequired.module.css';

function AuthRequired() {

  const [t] = useTranslation('translation');

  const [store, dispatch] = useStore();
  const isAuth = store.uiStore.isAuth;

  return (
    <Fragment>
      <div className={classes.authRequired}>
        <div>
          {t('nptPageFound.05', 'Login is required to access files, or create account')}
        </div>
        <div className={classes.authRequiredButtons}>
          <a href={loginPageLink}>
            <button className="btnBase">
              {marks.signInMrak} {t('nptPageFound.06', 'Login')}
            </button>
          </a>
          <span> {t('nptPageFound.07', 'or')} </span>
          <a href={signupPageLink}>
            <button className="btnBase">
              {marks.userPlusMrak} {t('nptPageFound.08', 'Signup')}
            </button>
          </a>
        </div>

        <div className={classes.authRequiredButtons}>
          <Link to="/image-editor">
            <button className="btnBase">
            {marks.paintBrushMark} {t('nptPageFound.03', 'Go to image editor page')}
            </button>
          </Link>
        </div>

        <div className={classes.authRequiredAboutLink}>
          <Link to="/about">
            About Kura Image Photo
          </Link>
        </div>

        {/* <div>
          some-other-content-not-page-found-page
        </div> */}
      </div>
    </Fragment>
  );
}

export default AuthRequired;
