// const React = window.React = require('react');
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Img from "react-cool-img";

import { useStore } from "../../hook-store/store";
// import { loginPageLink, signupPageLink } from "../../App";

import { marks } from "../../utils/marks";
import classes from "./AboutPage.module.css";

import FusionBaseImage from '../../images/imageFusion/lion.jpg';
import FusionStyleImage from '../../images/imageFusion/candy.jpg';
import FusionImage from '../../images/imageFusion/fusion-1705997507640.jpeg';

const AboutImageFusion = (props) => {
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { isAuth } = store.uiStore;

  let aboutImageFusionBody;

  aboutImageFusionBody = (
    <div>
      <div>
        <p>
          Image Fusion 
          {t('imageFusion17', 'is an AI-assisted image generation tool.')} 
          <br/>
          {t('imageFusion18', 'User can generate a fused image from two input images (base image and style image).')}
        </p>
        <br />

        <p>
          {t('imageFusion19', 'By transfering styles of style image to base image, style image-like base image is generated.')}
        </p>
        <br />
      </div>
      <br/>

      <div>
        <div>
            <strong>
            {t('imageFusion22', 'Examples of Image Fusion')}
            </strong>
        </div>
        <br/>

        <div className="combinerExampleImages">
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionBaseImage} />
            </div>
            <div>{t('imageFusion04', 'base image')}</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionStyleImage} />
            </div>
            <div>{t('imageFusion05', 'style image')}</div>
          </span>
          <span>
            <div>
              <img style={{ height: "225px" }} src={FusionImage} />
            </div>
            <div>{t('imageFusion09', 'fusion image')}</div>
          </span>
        </div>
      </div>
    </div>
 
  );

  return <Fragment>{aboutImageFusionBody}</Fragment>;
};
export default AboutImageFusion;
